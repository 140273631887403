.project-link{
  &__wrap{
    background-image: url('../../images/project-anim/bg_project_link.jpg');
    background-size: 100% 100%;
    padding:36px 40px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile{
      padding: 50px 25px;
      background-image: url('../../images/project-anim/bg_project_link_mobile.jpg');
    }

    @include mobile{
      flex-wrap: wrap;
    }
    &__left{
      color:white;
      @extend .heading-4;
      @include mobile{
        flex:0 0 100%;
        margin-bottom: 50px;
      }
    }

    &__right{
      @include mobile{
        flex:0 0 100%;
      }
      &__button{
        display: inline-flex;
        border-radius:26px;
        background-color: white;
        color:$turquoise;
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(20px);
        padding: 14px 30px;
        text-transform: uppercase;
        align-items: flex-start;
        &:after{
          content:"";
          width: 17px;
          height: 17px;
          background-image: url('../../images/icon_arrow_short_left.svg');
          display: block;
          margin-left: 5px;
          background-size: contain;
          background-repeat: no-repeat;
          transform: rotate(180deg);
        }
      }
    }

  }

}
.push-investir{
  padding-top: 207px;
  padding-bottom: 207px;
  max-width: 1520px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @include tablet{
    padding-top: 114px;
    padding-bottom: 114px;
  }
  &:before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.1;
  }
  &:after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
  }
  &__inner{
    max-width: 840px;
    margin: 0 auto;
    color: $white;
    text-align: center;
    position: relative;
    z-index: 2;
  }
  &__title{
    @extend .heading-1;
    margin-bottom: rem(10px);
  }
  &__text{
    @extend .heading-5;
    margin-bottom: rem(20px);
  }
}
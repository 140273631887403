.expertise{
  .section-title {
    margin: 0 0 24px;
    font-size: rem(40px);
    line-height: rem(42px);
    @extend %GothamBold;
    letter-spacing: -1.6px;
    text-transform: uppercase;
    @include tablet {
      font-size: rem(24px);
        line-height: rem(32px);
      letter-spacing: -0.96px;
      margin: 0 0 16px;
    }
  }
  .section-intro{
    max-width: 840px;
    font-size: rem(17px);
    line-height: rem(26px);
    @extend %GothamBook;
  }
  .expertise-list{
    border-bottom: 1px solid $border;
    margin: 40px 0 0;
    @include tablet {
      margin: 32px 0 0;
    }
    &>li{
      align-items: center;
      border-top: 1px solid $border;
      display: flex;
      padding: 24px 0;

      @include tablet {
        flex-direction: column;
        align-items: start;
      }
    }
    .expertise-list-icon{
      margin-right: 24px;
      img{
        display: block;
        max-height: 80px;
        max-width: 80px;
        @include tablet {
            max-height: 64px;
            max-width: 64px;
          margin-bottom: 8px;
        }
      }
    }
    .expertise-list-info{
      flex: 1 1;
      .expertise-list-title{
        @extend %GothamMedium;
        font-size: rem(21px);
        line-height: rem(28px);
        color: $turquoise-black-second;
        margin: 0 0 8px;

        @include tablet {
          font-size: rem(17px);
          line-height: rem(26px);
        }
      }
      .expertise-list-subtitle{
        @extend %GothamMedium;
        font-size: rem(17px);
        line-height: rem(26px);
        margin: 0;

      }
    }
    .expertise-list-action{
      margin-left: 40px;

      @include tablet {
        margin: 32px 0 0;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
}

.rich-content,
.section,
.video,
.image,
.key-numbers-slider,
.photo-gallery,
.twitter-slider
{
  margin-top: 70px;
  @include tablet {
    margin-top: 20px;
  }
  .section-spacer{
    @include tablet {
      margin-bottom: 70px;
    }
  }
  .rich-content-text{

    @include tablet {
      margin-top: 20px;
    }
    ul:not(.news-single-social), ol {
      list-style: disc;
      padding-left: 26px;
      li{
        list-style: disc;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    ul, ul li {
      list-style: none!important;
    }
    ul li {
      position: relative;
    }
    ul li::before {
      content: "";
      background-color: $turquoise-black-second;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: -1em;
      position: absolute;
      top: 9px;
      left: -8px;
    }
    ul, ol {
      font-size: 17px;
      line-height: 26px;
      font-weight: 400;
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 16px;
      li {
        ul, ol {
          margin-top: 4px;
          gap: 4px;
        }
        ul li::before {
          background-color: $icade-blue;
          left: -4px;
        }
      }
    }
    ol {
      counter-reset: item;
      list-style: none;
      li {
        list-style: none;
        &::before {
          display: inline-block;
          content: counter(item);
          counter-increment: item;
          font-size: 17px;
          width: 28px;
          margin-left: -24px;
          color: $turquoise-black-second;
          @extend %GothamBold;
        }
      }
      ol li::before {
        color: $icade-blue;
      }
    }
    &>ul, &>ol {
      margin-bottom: 40px;
    }
    table {
      width: 100%;
      margin-bottom: 40px;
      @include tablet {
        display: block;
        overflow: auto;
        white-space: nowrap;
        margin-left: -20px;
        margin-right: -20px;
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
      }
      th, td {
        padding: 16px 40px;
        text-align: left;
        font-size: 17px;
        line-height: 26px;
        max-width: 600px;
        text-wrap: auto;
        @include tablet {
          min-width: 350px;
        }
      }
      td {
        border: 1px solid $grey;
      }
      tr {
        th {
          text-align: center;
          background-color: $icade-blue;
          border-left: 1px solid white;
          @extend %GothamMedium;
        }
        & th:first-child {
          border-left: 1px solid $icade-blue;
        }
        & th:last-child {
          border-right: 1px solid $icade-blue;
        }
      }
      & tbody tr:first-child td {
        border-top: 1px solid $icade-blue;
      }
    }
    .indicators {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 40px;
      .indicators-item {
        border-top: 1px solid $grey;
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        @include tablet {
            flex-direction: column;
            gap: 16px;
        }
        h4 {
          min-width: 300px;
          font-weight: 400;
          color: $turquoise-black-second;
          margin-bottom: 0;
          @include tablet {
            min-width: unset;
          }
        }
        div {
          margin-top: 0;
          font-size: 17px;
          font-weight: 400;
          line-height: 26px;
        }
        & div:last-child {
          margin-bottom: 0;
        }
      }
        & .indicators-item:last-child {
            border-bottom: 1px solid $grey;
        }
    }
    .gallery {
      max-width: 100%;
      object-fit: cover;
      margin-bottom: 40px;
      border-radius: 16px;
      overflow: hidden;
      &.vertical {
        aspect-ratio: 620/827;
        width: 620px;
      }
      &.horizontal {
        aspect-ratio: 4/3;
        width: 100%;
        &.small {
          width: 620px;
        }
      }
    }
    .chapo{
      margin: 20px 0;
    }

    .indicators {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 40px;
          .indicators-item {
              border-top: 1px solid $grey;
              padding: 24px 0;
              display: flex;
              justify-content: space-between;
              gap: 24px;
              @include tablet {
                  flex-direction: column;
                  gap: 16px;
              }
              h4 {
                  min-width: 300px;
                  font-weight: 400;
                  color: $turquoise-black-second;
                  margin-bottom: 0;
                  @include tablet {
                      min-width: unset;
                  }
              }
              div {
                  margin-top: 0;
                  font-size: 17px;
                  font-weight: 400;
                  line-height: 26px;
              }
              & div:last-child {
                  margin-bottom: 0;
              }
          }
          & .indicators-item:last-child {
              border-bottom: 1px solid $grey;
          }
      }
  }

  //.image{
  //  img{
  //    max-width: 85%;
  //  }
  //}
}


.ezrichtext-field{


  strong, b{
    font-weight: bold;
  }
}

.reading-time {
  color: rgba(4, 20, 45, 0.5);
  font-size: 1.16667rem;
  margin: 30px auto;
  font-family: "Gotham Medium", sans-serif;
  font-weight: 500;
}

// Leaders and trailers.

.leader-25 { margin-top: rem(25px); }
.leader-40 { margin-top: rem(30px); }
.leader-50 { margin-top: rem(50px); }
.leader-100 { margin-top: rem(100px); }

.trailer-15 { margin-bottom: rem(15px); }
.trailer-40 { margin-bottom: rem(40px); }
.trailer-60 { margin-bottom: rem(50px); }
.trailer-80 { margin-bottom: rem(80px); }
.trailer-90 { margin-bottom: rem(90px); }

html,
body {
    //overflow-x : hidden;
    background-color: $white;
}

.is-hidden-desktop {
  display: none;

  @include tablet {
    display: block;
  }
}
.is-hidden-tablet {
    @include tablet {
        display: none;
    }
}
.is-hidden-mobile {
  @include mobile {
    display: none;
  }
}

.is-hidden {
    display: none;
    visibility: hidden;
}
@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

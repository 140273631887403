.jobboard-fixed{
  position: fixed;
  top:0;
  left:0;
  right: 0;
  z-index: 11;
  background-color: $white;
  transform: translateY(-100%);
  transition: all 250ms;
  @include tablet{
    display: none;
  }
  &.is-shown{
    transform: translateY(0%);
  }
  &__progress{
    background-color: rgba($turquoise, 0.5);
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    height: 2px;
    &__bar{
      background-color: $turquoise;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      transform-origin: left;
      transform: scaleX(0);
    }
  }

  &__wrap{
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 12px;
    justify-content: space-between;
  }

  &__left{
    display: flex;
    align-items: center;
  }
  &__right{
    .button{
      margin-right: rem(15px);
      :last-child{
        margin-right: 0;
      }
    }
  }

  &__text{
    &__top{
      text-transform: uppercase;
      color: $turquoise;
      font-size: rem(12px);
      @extend %GothamMedium;
      margin-bottom: rem(7px);
    }
    &__title{
      @extend .heading-4;
      font-size: rem(16px);
      line-height: rem(16px);
    }
  }

  &__logo{
    max-width: 75px;
    margin-right: 38px;
    img{
      width: 100%;
      height: auto;
    }
  }
}
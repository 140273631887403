.innovation{
  .innovation-container{
    border-bottom: 1px solid $border;
    border-top: 1px solid $border;
    padding: 100px 0;
    text-align: center;

    @include tablet {
      padding: 30px 0;
    }

    .section-title{
      margin: 0 auto 20px;
      max-width: 850px;
    }
    .section-intro{
      margin: 0 auto;
      max-width: 850px;
    }
    .button{
      margin: 20px 0 0;
    }
  }
}

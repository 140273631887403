.table{
  @extend %GothamMedium;
  font-size: rem(14px);
  line-height: rem(17px);
  margin: rem(20px) 0;
  &--maxw{
    max-width: 950px;
  }
  &__row{
    display: flex;

    &--top{
      color:$white;
      background-color: $black;
    }

    &__col{
      flex: 1 1 0px;
      text-align:center;
      padding: 22px 10px;
      border-bottom: 1px solid #BBBBCC;
      border-left: 1px solid #BBBBCC;
      &:last-child{
        border-right: 1px solid #BBBBCC;;
      }
    }

    &--top &{
      &__col{
        text-transform: uppercase;
        border-right: 1px solid rgba(204, 205, 218, 0.2);
        border-left: 0;
        border-bottom: 0;
        &:last-child{
          border-right: 0;
        }
      }
    }
  }
}
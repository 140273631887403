.hisory{
  overflow: hidden;
}
.history-list-wrapper{
  position: relative;

  .progress{
    background-color: $black;
    display: block;
    max-height: 100%;
    min-height: 0;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    @include size(1px, 100px);
  }
  &:after{
    background-image: linear-gradient(to bottom, rgba($white, .01), $white);
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: 10px;
    z-index: 2;
    @include size(1px, 100px);
  }
}
.history-list{
  &>li{
    border-right: 1px solid $border;
    border-top: 1px solid $border;
    margin-right: 10px;
    padding: 30px 0 80px;
    padding-right: 320px;
    position: relative;

    @include tablet {
      padding: 20px 0 40px;
      padding-right: 15px;
    }
    &:before{
      background-color: $white;
      content: "";
      height: 1px;
      position: absolute;
      right: 0;
      top: -1px;
      width: 100px;

      @include tablet {
        width: 18px;
      }
    }
    &:after{
      background-color: $turquoise;
      content: "";
      position: absolute;
      right: -6px;
      top: 45px;
      transition: background-color .3s ease;
      @include circle(11px);

      @include tablet {
        top: 25px;
      }
    }
    &.history-list-intro{
      border-top: none;
      padding-top: 0;

      &:after{
        background-color: $black;
        top: 0;
      }
      p{
        @extend %Gotham;
        font-size: rem(28px);
        line-height: rem(38px);

        @include tablet {
          font-size: rem(18px);
          line-height: rem(26px);
        }
      }
    }
    &.active{
      &:after{
        background-color: $black;
      }
    }
  }
  .history-list-intro-visual{
    border-radius: 0 rem(4px) rem(4px) 0;
    margin-left: -($outter * 2);
    margin-right: -220px;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;

    @include tablet {
      width: calc(100% + 80px);
    }

    .history-list-intro-visual-image{
      background: transparent none 50% 50%/cover no-repeat;
      border-radius: rem(4px);
      height: 720px;
      overflow: hidden;
      width: calc(100% + 300px);

      @include tablet {
        height: 360px;
        width: calc(100% + 260px);
      }
      @include mobile {
        height: 160px;
        width: calc(100% + 260px);
      }
    }
  }
  .history-list-date{
    color: $turquoise;
    @extend %GothamMedium;
    font-size: rem(32px);
    line-height: rem(40px);
    position: absolute;
    right: 100px;
    top: 35px;

    @include tablet {
      font-size: rem(20px);
      line-height: rem(26px);
      position: static;
      right: auto;
      top: auto;
    }
  }
  .history-list-title{
    @extend %GothamMedium;
    font-size: rem(32px);
    line-height: rem(40px);
    margin: 0 0 10px;

    @include tablet {
      font-size: rem(20px);
      line-height: rem(26px);
    }
  }
  p{
    @extend %Gotham;
    font-size: rem(21px);
    line-height: rem(32px);

    @include tablet {
      font-size: rem(16px);
      line-height: rem(22px);
    }
  }
  img{
    border-radius: rem(4px);
    display: block;
    margin: 50px 0 0;
    max-width: 100%;
  }
  .history-list-caption{
    color: rgba($black, .45);
    @extend %Gotham;
    font-size: rem(17px);
    line-height: rem(26px);
    margin: 15px 0 0;
  }
  .key-numbers-slider,
  .video-wrapper{
    margin: 50px 0 0;
  }
  .video-wrapper{
    border-radius: rem(4px);
  }
}

.graph{
  &-row {
    align-items: stretch;
    margin-top: 40px;
    @include tablet {
      margin-top: 32px;
    }
  }

  &__wrap{
    display: flex;
    justify-content: space-between;
    .graph-title {
      font-size: rem(21px);
      line-height: rem(28px);
      @extend %GothamMedium;
      margin-bottom: 8px;
      @include tablet {
        margin-top: 16px;
      }
    }
    .graph-name{
      color: rgba(0, 0, 0, 0.4);
      @extend %GothamBook;
      font-size: rem(17px);
      line-height: rem(26px);

      @include tablet {
        font-size: rem(18px);
        line-height: rem(26px);
        margin: 0 0 10px;
      }
    }
  }
  &__tabs{
    display: flex;
    border-top: 1px solid #bbbbcc;
    border-bottom: 1px solid #bbbbcc;
    &__item{
      padding: 30px 0;
      margin-right: 30px;
      @extend %GothamMedium;
      font-size: rem(14px);
      letter-spacing: 1px;
      line-height: rem(17px);
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      transition: all 250ms;
      &:hover{
        color:$turquoise;
      }
      &.is-active{
        color:$turquoise;
        &:after{
          content:"";
          width: 100%;
          bottom:-1px;
          height: 2px;
          background-color: $turquoise;
          position: absolute;
          left:0;
        }
      }
      &:last-child{
        margin-right: 0;
      }
      .text-mobile{
        display: none;
        @include tablet{
          display: block;
        }
      }
      .text-desktop{
        @include tablet{
          display: none;
        }
      }
    }

  }
  &__toggles{
    &__item{
      display: none;
      &.is-active{
        display: block;
      }
      &__title{
        @extend %GothamMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        letter-spacing: rem(-0.8px);
        @include tablet{
          font-size: rem(20px);
          line-height: rem(26px);
          letter-spacing: rem(-0.2px);
        }
      }
      &__btns{
        margin-top: rem(24px);
        margin-bottom: rem(16px);
        @include tablet {
          margin-top: rem(6px);
        }
        .select{
          width: 100%;
          max-width: 290px;
          display: none;
          @include tablet{
            display: block;
          }
          @include mobile{
            max-width: 100%;
          }
        }
        .button {
          justify-content: center;
          &:last-child{
            margin-right: 0;
          }
          @include tablet{
            display: none;
          }
        }
      }
    }
  }
  .graph-progress {
    background-color: $white;
    box-shadow: 0px 0px 32px 0px rgba(41, 34, 101, 0.08);
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 240px;
      height: 240px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../images/blue-quart-circle.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      rotate: 90deg;
      z-index: 0;
      @include tablet-wide {
        width: 155px;
        height: 155px;
      }
      @include tablet {
        width: 115px;
        height: 115px;
      }
    }
    @include mobile {
      padding: 48px 20px;
    }
    .graph-value, .graph-date, .graph-variation {
      z-index: 1;
    }
    .graph-value{
      @extend %GothamBold;
      font-size: rem(40px);
      line-height: rem(42px);
      letter-spacing: -1.6px;
      b {
        font-size: rem(100px);
        line-height: rem(115px);
        letter-spacing: -4px;
        @include tablet-wide {
          font-size: rem(60px);
          line-height: normal;
          letter-spacing: -3.2px;
        }
        @include tablet {
          font-size: rem(80px);
        }
      }
      @include tablet-wide {
        font-size: rem(32px);
        line-height: rem(36px);
        letter-spacing: -1.28px;
      }
      @include tablet {
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -0.96px;
      }
    }
    .graph-date{
      font-size: rem(14px);
      @extend %GothamBold;
      line-height: rem(14px);
      letter-spacing: -0.28px;
      text-transform: uppercase;
      padding-bottom: 4px;
      margin: 0;
    }
    .graph-variation {
      color: rgba(0, 0, 0, 0.6);
      @extend %GothamBook;
      font-size: rem(21px);
      line-height: rem(28px);
      @include tablet {
        font-size: rem(18px);
        line-height: rem(26px);
      }
    }
  }
  .graph-sr-only{
    ul{
      padding-left: 1rem;
      margin: 25px 0;
      li{
        margin-bottom: 15px;
        list-style-type: disc;
        &:last-child{
          margin-top: 0;
        }
      }
    }
  }
  .graph-canvas {
    overflow-x: auto;
  }
}

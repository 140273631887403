.p-0 {
  padding: 0;
}
.p-0-top {
  padding-top: 0;
}
.p-0-right {
  padding-right: 0;
}
.p-0-bottom {
  padding-bottom: 0;
}
.p-0-left {
  padding-left: 0;
}

.p-1 {
  padding: 8px;
}
.p-1-top {
  padding-top: 8px;
}
.p-1-right {
  padding-right: 8px;
}
.p-1-bottom {
  padding-bottom: 8px;
}
.p-1-left {
  padding-left: 8px;
}

.p-2 {
  padding: 16px;
}
.p-2-top {
  padding-top: 16px;
}
.p-2-right {
  padding-right: 16px;
}
.p-2-bottom {
  padding-bottom: 16px;
}
.p-2-left {
  padding-left: 16px;
}

.p-3 {
  padding: 24px;
}
.p-3-top {
  padding-top: 24px;
}
.p-3-right {
  padding-right: 24px;
}
.p-3-bottom {
  padding-bottom: 24px;
}
.p-3-left {
  padding-left: 24px;
}
.projects{
  margin-top: 0;
  min-height: 100vh;
//  overflow: hidden;
  padding-top: 190px;

  &.no-padding{
    padding-top: 0px;
    @include tablet{
      padding-top: 0px;
    }
  }

  @include tablet{
    padding-top: 60px;
  }
  .projects-title{
    margin-top: 60px;
    @extend .heading-1;
    @extend %GothamBold;

    @include tablet{
      margin-top: 30px;
    }
  }
}
.project-filters-container{
  border-bottom: 1px solid $border;
  border-top: 1px solid $border;
  position: relative;
  z-index: 3;

  &.pad-y{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.no-border-top{
    border-top: 0;
  }

  &.noborder{
    border-bottom: none;
  }

  @include tablet{
    border-top: none;
  }

  .project-filters-wrapper{
    display: flex;
    justify-content: space-between;

    &.justify-start{
      justify-content: flex-start;
    }
    &.align-center{
      align-items: center;
    }

    @include tablet-wide{
      justify-content: flex-end;
    }
    @include tablet{
      justify-content: center;
    }
  }
}
.projet-filters-search{
  margin-right: rem(70px);
  min-width: 400px;
  position: relative;
  @include tablet{
    min-width: 100%;
  }
  button{
    position: absolute;
    top:50%;
    right:15px;
    transform: translateY(-50%);
    cursor: pointer;
    color:$black;
    appearance: none;
    border:0;
    background: transparent;
  }
  input{
    appearance: none;
    background-color: #EAF9FA;
    border: 0;
    padding: 20px 15px;
    font-size: rem(12px);
    width: 100%;
    padding-right: 45px;
    @extend %GothamBold;
    text-transform: uppercase;
    border-radius: 4px;
    &:focus{
      outline: 0;
    }
  }
}
.project-nav{
  display: flex;
  @extend %GothamBold;
  font-size: rem(14px);
  letter-spacing: 1px;
  line-height: rem(17px);
  margin: 0 -10px;
  position: relative;
  text-transform: uppercase;
  justify-content: flex-start;
  padding: 20px 0;
  min-height: unset ;

  @include tablet{
    font-size: rem(12px);
    letter-spacing: 1px;
    line-height: rem(14px);
  }
  @include tablet{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &.projects{
    a:nth-child(1){
      color: $turquoise-black-second;
      &:after{
        height: 2px;
      }
    }
  }
  &.references{
    a:nth-child(2){
      color: $turquoise-black-second;
      &:after{
        height: 2px;
      }
    }
  }
  &.map{
    a:nth-child(3){
      color: $turquoise-black-second;
      &:after{
        height: 2px;
      }
    }
  }

  a{
    margin: 0 10px;
    position: relative;
    transition: color .3s ease;

    &:after{
      background-color: $turquoise-black-second;
      bottom: -21px;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      transition: height .2s ease;
      @include size(auto, 0px);
    }
    &:hover{
      color: $turquoise-black-second;
    }
  }
}
.projects-list{
  margin-top: 60px;

  @include tablet {
    margin-top: 30px;
  }

  .projects-list-item{
    margin: 0 0 100px;
    position: relative;
    z-index: 1;

    @include tablet {
      margin: 0 0 64px;
    }
    >a {
      overflow: hidden;
      height: fit-content;
      width: 100%;
      border-radius: 1rem;
      display: block;
      img{
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 75vh;
        transition: transform .3s ease;
      }
      &:hover{
        img{
          transform: scale(1.02);
        }
      }
    }
  }
  .projects-list-item-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 24px -5px 0;
    position:relative;

    @include tablet {
      margin: 16px -5px 0;
    }

    &>li{
      padding: 5px;
    }
    a{
      border: 1px solid $black;
      border-radius: rem(4px);
      color: $black;
      display: block;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(16px);
      padding: 6px 8px;
      transition: all .3s ease;

      &:hover{
        background-color: $black;
        color: $white;
      }
    }
  }
  .projects-list-item-name{
    @extend %GothamBold;
    margin: 24px 0 0;
    font-size: rem(32px);
    line-height: rem(32px);
    letter-spacing: -1.28px;
    text-transform: uppercase;
    position:relative;
    @include tablet {
      font-size: rem(24px);
      letter-spacing: -0.96px;
      margin: 16px 0 0;
    }
  }

  .row-single{
    position: relative;

    @include tablet {
      margin: 0;
    }
    .projects-list-item{
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -40px;
        right: -40px;
        width: 162px;
        height: 162px;
        background-image: url('../../images/yellow-quart-circle.svg');
        background-size: cover;
        rotate: 180deg;
        @include tablet {
          top: -10px;
          right: -10px;
          width: 88px;
          height: 88px;
        }
      }

      a{
        display: block;
        position: relative;
        img {
        }
      }
    }
  }
}
.projects-list-moar{
  text-align: center;
  .button {
    padding: 12px 16px 8px 16px;
    font-size: 0.77778rem;
    line-height: 0.77778rem;
    margin: 0;
  }
}

.projects-references-list{
  margin: 60px 0;

  @include tablet {
    margin: 30px 0;
  }
  table{
    width: 100%;

    @include tablet {
      display: block;
    }

    thead{
      @include tablet {
        display: none;
      }
      th{
        text-align: left;
        text-transform: uppercase;
        @extend .small-link;

        &:first-child{
          width: 40%;
        }
        &:last-child{
          text-align: right;

          a{
            padding-left: 15px;
            padding-right: 0;

            &:before{
              left: 0;
              right: auto;
            }
          }
        }
        a{
          padding-right: 15px;
          position: relative;

          &:before{
            border: 5px solid transparent;
            border-top-color: #8E96A1;
            content: "";
            margin-top: 3px;
            right: 0;
            @include absolute-center('y');
            @include size(0, 0);
          }
        }
      }
    }
    tbody{
      @include tablet {
        display: block;
      }
      tr{
        &:hover{
          cursor: pointer;
          td{
            &:first-child{
              a,span{
                transform: translateX(10px);

                @include tablet {
                  transform: none;
                }

                &:before{
                  opacity: 1;

                  @include tablet {
                    opacity: 0;
                  }
                }
              }
            }
            a,span{
              transform: translateX(-10px);

              @include tablet {
                transform: none;
              }
            }
          }
        }
        @include tablet {
          border-bottom: 1px solid $border;
          display: block;
          padding: 10px 0;
        }
        &.disabled{
          td{
            opacity: .2;
          }
          &:hover{
            cursor: default;
            td{
              a,span{
                cursor: default;
                transform: none;

                &:before{
                  opacity: 0;
                }
              }
            }
          }
        }
        &:last-child{
          @include tablet {
            border-bottom: none;
          }
          td{
            border-bottom: none;
          }
        }
      }
      td{
        border-bottom: 1px solid $border;
        text-align: left;
        padding: 20px 15px 20px 0;
        @extend .body;

        @include tablet {
          border-bottom: none;
          display: flex;
          margin: 10px 0;
          padding: 0;
        }

        &:last-child{
          padding-right: 0;
          text-align: right;

          @include tablet {
            text-align: left;
          }
        }
        a{
          display: block;
          position: relative;
          transition: transform .3s ease;
          @extend .heading-5;

          &:before{
            content: "";
            opacity: 0;
            right: 100%;
            transition: opacity .3s ease;
            @include absolute-center('y');
            @include svgicon('../../images/small_arrow.svg', 16px, 12px);
          }
        }
        span{
          display: block;
          flex: 0 0 50%;
          position: relative;
          transition: transform .3s ease;
        }
        .label{
          color: rgba($black, .5);
          display: none;
          text-transform: uppercase;
          @extend .normal-link;
          line-height: rem(22px);

          @include tablet {
            display: block;
          }
        }
      }
    }
  }
}
.project-details-infos{
  margin-bottom: 20px;
  @include tablet {
    margin-top: 30px;
  }
  &>li{
    margin: 0 0 15px;
    padding-left: 30px;
    position: relative;
    text-transform: uppercase;
    @extend .normal-link;

    &:before{
      background-color: $black;
      content: "";
      left: 0;
      position: absolute;
      top: 2px;
      @include circle(12px);
    }

    em{
      color: $turquoise-black-second;
      display: block;
      font-style: normal;
      margin-top: 5px;
      text-transform: none;
      @extend .body-small;
    }
  }
}
.project-details-content{
  p{
    margin: 20px 0 20px;
    max-width: 840px;
    @extend .body;

    @include tablet {
      margin: 10px 0 22px;
    }
  }
  ul, ol{
    margin: 10px 0 10px;
    max-width: 840px;
    @extend .body;
  }
  .project-details-content-subtitle{
    @extend .heading-5;
  }
}
.projects-maps{
  background-color: #eee;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 248px;
  z-index: 1;

  @include tablet {
    top: 115px;
  }
  .projects-map-element{
    left: 0;
    position: absolute;
    top: 0;
    @include size(100%, 100%);
  }
  .projects-maps-zoom{
    bottom: 10px;
    position: absolute;
    right: 10px;
    z-index: 1;

    @include tablet {
      bottom: 60px;
    }

    a{
      background-color: $black;
      color: #fff;
      display: block;
      line-height: 24px;
      margin: 5px 0;
      text-align: center;
      @include circle(24px);
    }
  }
  .gm-style-iw{
    border-radius: rem(4px);
    box-shadow: 0 0 5px 0 rgba(#000, .15);
    padding: 0;
  }
  .gm-style-iw-d{
    overflow: hidden !important;
  }
  .gm-style-iw-c,
  .gm-style-iw-d,
  .gm-style-iw-d > div{
    width: 660px;

    @include tablet {
      width: auto;
      max-height: none !important;
    }
  }
  .gm-style-iw-t:before{
    display: none;
  }
  .gm-ui-hover-effect{
    right: 0 !important;
    top: 0 !important;
  }
  .infowindow{
    display: flex;
    width: 660px;

    @include tablet {
      display: block;
      width: auto;
    }
    .infowindow-visual{
      display: block;
      flex: 0 0 200px;
      overflow: hidden;
      position: relative;
      @include size(200px, 200px);

      @include tablet {
        @include size(100%, 200px);
      }
      img{
        display: block;
        @include absolute-center;
      }
    }
    .infowindow-info{
      padding: 30px;

      @include tablet {
        padding: 15px;
      }
    }
    .infowindow-title{
      @extend .heading-4;
    }
    .infowindow-tags{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px 10px;

      @include tablet {
        margin: 0px -5px 5px;
      }

      &>li{
        padding: 5px;
      }
      a, span{
        border: 1px solid $black;
        border-radius: rem(2px);
        color: $black;
        display: block;
        @extend %GothamBold;
        font-size: rem(12px);
        line-height: rem(24px);
        padding: 0 10px;
        transition: all .3s ease;

        &:hover{
          background-color: $black;
          color: $white;
        }
      }
    }
    .infowindow-link{
      color: $turquoise-black-second;
      display: inline-block;
      margin: 30px 0 0;
      padding-left: 25px;
      position: relative;
      text-transform: uppercase;
      @extend .normal-link;

      &:before{
        content: "";
        left: 0;
        @include absolute-center('y');
        @include svgicon("../../images/icon_arrow_short_right.svg", 16px, 16px);
      }
    }
  }
}

.turquoise {
  padding: 80px 0;
  background-color: rgba(117, 194, 189, 0.1);
}
.financial-actus .title-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  @include tablet {
    display: block;
  }
}
.financial-actus .button {
  padding: 12px 24px;
}
.financial-actus .cells-container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.financial-actus .actu-cell {
  padding-right: 20px;
  padding-left: 0;
  margin-bottom: 40px;
  @include tablet {
    margin-bottom: 10px;
  }
}
.financial-actus .actu {
  border-top: 1px solid $border;
  padding-top: 24px;
  @include tablet {
    padding-top: 16px;
  }
}
.financial-actus .actu .title {
  @extend %GothamMedium;
  font-size: rem(21px);
  line-height: rem(26px);
  margin-bottom: 5px;
  min-height: 52px;
  @include tablet {
    font-size: 18px;
    line-height: 22px;
    min-height: 44px;
  }
}
.financial-actus .actu .informations {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  margin-bottom: 30px;
}
.financial-actus .actu .informations .date {
  @extend %GothamMedium;
  font-size: rem(12px);
  line-height: rem(14px);
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.financial-actus .informations {
  @include tablet {
    margin-bottom: 16px!important;
  }
}
.dividends{
  .dividends-chart {
    overflow-x: auto;
    & > svg {
      min-height: 266px;
      fill: $turquoise-black-second;
    }
  }
  .dividends-calendar-desktop{
    @include tablet{
      display: none;
    }
  }
  .dividends-calendar-mobile{
    display: none;

    @include tablet{
      display: block;
    }
  }
  .dividends-list{
    border-bottom: 1px solid #BBBBCC;
    margin: 30px 0 0;
    .date-cell {
      box-shadow: 0px 0px 32px 0px rgba(41, 34, 101, 0.08);
      border-radius: 16px;
    }
    @include tablet {
      margin: 15px 0 20px;
    }

    &>li{
      border-top: 1px solid #BBBBCC;
      display: flex;
      padding: 35px 0;

      @include tablet {
        flex-direction: column;
        padding: 15px 0 20px;
      }
    }
  }
  .dividends-list-date{
    color: rgba($black, .5);
    @extend %GothamMedium;
    flex: 0 0 210px;
    font-size: rem(12px);
    line-height: rem(14px);
    letter-spacing: 1px;
    padding-top: 10px;
    text-transform: uppercase;

    @include tablet {
      flex: 0 0 120px;
    }
    @include tablet {
      flex: 0;
    }
  }
  .dividends-list-infos{
    flex: 1;
    flex-basis: auto;
  }
  .dividends-list-title{
    @extend %GothamMedium;
    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -.8px;

    @include tablet {
      font-size: rem(20px);
      line-height: rem(26px);
      letter-spacing: -.2px;
      margin-top: 10px;
    }
  }
  .dividends-list-files{

    &>li{
      a{
        color: $turquoise-black;
        display: inline-block;
        @extend %GothamMedium;
        flex: 0 0 210px;
        font-size: rem(14px);
        line-height: rem(20px);
        letter-spacing: 1px;
        padding-left: 30px;
        position: relative;
        text-transform: uppercase;
        transition: all .2s ease;

        &:hover{
          color: $black;

          .dividends-list-icon{
            svg *[stroke]{
              stroke: $black;
            }
          }
        }
        .dividends-list-icon{
          align-items: center;
          display: flex;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0px;
          @include size(20px, 20px);

          svg{
            max-height: 100%;
            max-width: 100%;
            stroke: $turquoise-black;

            path {
              stroke: $turquoise-black;
            }
          }
          &:hover {
            svg {
              path {
                stroke: $black;
              }
            }
          }
        }
      }
    }
  }
  .dividends-list-tools{
    flex: 0;
    flex-basis: auto;
    margin-left: 20px;

    @include tablet {
      margin-top: 10px;
    }

    .button{
      color: $turquoise-black;
      position: relative;
      white-space: nowrap;

      &:hover{
        border-color: $turquoise-black-second;
      }
    }
  }
}

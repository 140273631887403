.jobcard{
  padding-top: rem(50px);
  padding-bottom: rem(30px);
  border-bottom: 1px solid $border;
  @include tablet{
    padding-top: rem(20px);
    padding-bottom: rem(28px);
  }
  &--alt{
    border-bottom: 0;
    padding-top: rem(25px);
  }
  &--alt &{
    &__top{
      margin-bottom: rem(10px);
    }
    &__bottom{
      &__left{
        &__item{
          margin-right: 50px;
        }
      }
    }
  }
  &__top{
    @extend .heading-4;
    margin-bottom: rem(26px);
    p{
      margin-bottom: 0;
    }
    @include tablet{
      margin-bottom: rem(16px);
    }
  }
  &__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.align-end{
      align-items: flex-end;
      @include tablet{
        align-items: flex-start;
      }
    }
    @include tablet{
      flex-flow: column;
      align-items: flex-start;
    }
    &__left{
      display: flex;
      @include tablet{
        flex-flow: column;
        margin-bottom: rem(30px);
      }
      &__item{
        color: $turquoise;
        display: flex;
        align-items: center;
        font-size: rem(12px);
        @extend %GothamMedium;
        text-transform: uppercase;
        margin-right: 80px;
        &:last-child{
          margin-right: 0;
        }
        @include tablet{
          margin-right: 0;
          margin-bottom: rem(10px);
          &:last-child{
            margin-bottom: 0;
          }
        }
        svg{
          width: 21px;
          margin-right: rem(10px);
        }
      }
    }
    &__right{
      display: flex;
      @include tablet{
        flex-wrap: wrap;
      }
      &__info{
        margin-right: 80px;
        &:last-child{
          margin-right: 50px;
        }
        &__title{
          font-size: rem(12px);
          @extend %GothamMedium;
          text-transform: uppercase;
          margin-bottom: rem(18px);
        }
        &__text{
          color: $turquoise;
          font-size: rem(12px);
          @extend %GothamMedium;
          text-transform: uppercase;
        }
      }
      @include tablet{
        width: 100%;
      }
      .button{
        @include tablet{
          width: 100%;
          &:first-child{
            margin-bottom: 10px;
          }
          &:last-child{
            margin-left: 0;
          }
        }
      }
    }
  }

  .button-apply-job{
    margin-left: 10px;
    @include tablet-wide{
      //margin-top: rem(10px);
      //margin-left: 0;
    }
  }
}
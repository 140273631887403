.homepage-news-intro .show-mobile {
  margin-bottom: 40px;
  display: none;
  @include mobile {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.news{
  .section-title-wrapper .button {
    @include mobile {
      display: none;
    }
  }
  .news-list{
    display: flex;
    margin-left: -$gutter;
    margin-right: -$gutter;

    &:after{
      display: block;
      content: "";
      clear: both;
    }

    &.news-small{
      @include tablet {
        margin-right: -5px;
        margin-bottom: 30px;
      }
      @include mobile {
        margin-right: 25px;
      }
      .news-list-item{
        flex: 0 1 50%;
        padding: 0 $gutter;

        @include tablet {
          padding: 0 5px;
        }
      }
    }

    @include tablet {
      display: block;
      margin-left: -5px;
      //margin-right: -5px;
      margin-right: 120px;
      margin-top: 10px;
      overflow: visible !important;
    }
    @include mobile {
      margin-right: 25px;
    }

    .news-list-item{
      flex: 1 1 33.33%;
      padding: 0 $gutter;

      @include tablet {
        padding: 0 5px;
      }
    }
  }
  .news-item{
    display: block;
    margin-top: 30px;
    position: relative;

    @include tablet {
      margin-top: 10px;
    }
    &:hover .news-item-visual:before{
      transform: scale(1.3);
    }
  }
  .news-item-visual{
    border-radius: rem(16px);
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    img{
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &:before {
      content: '';
      display: block;
      width: 160px;
      height: 160px;
      background-color: red;
      position: absolute;
      bottom: -80px;
      left: -80px;
      z-index: 10;
      border-radius: 50%;
      transition: all .3s ease;
    }
    &.blue:before {
      background-color: #75C2BD;
    }
    &.yellow:before {
      background-color: #FFC600;
    }
    &.green:before {
      background-color: #9DBC00;
    }
  }
  .news-item-event{
    background: $violet url(../../images/event_bg@2x.jpg) 50% 50%/cover no-repeat;
    border-radius: rem(4px);
    display: flex;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    text-align: center;

    @include tablet{
      margin: 0 0 15px;
      width: auto;
    }
    .news-item-event-content{
      color: $white;
      @include absolute-center;
      @include size(90%, auto);
    }
    .date{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(27px);
      letter-spacing: -.6px;

      @include tablet {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -.2px;
      }

      strong{
        display: block;
        @extend %GothamNarrow;
        font-size: rem(115px);
        line-height: rem(85px);
        letter-spacing: -2px;

        @media (max-width: 1024px){
          font-size: rem(95px);
          line-height: rem(65px);
          letter-spacing: -1.6px;
        }
        @include tablet {
          font-size: rem(95px);
          line-height: rem(65px);
          letter-spacing: -1.6px;
        }
      }
    }
    .place{
      margin: 15px 0 0;
      text-transform: uppercase;
      @extend .small-link;

      @include tablet {
          margin: 10px 0 0;
      }
    }
  }
  .news-item-date{
    color: $turquoise-black-second;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(16px);
    letter-spacing: -0.28px;
    margin: 24px 0 0;
    text-transform: uppercase;
  }
  .news-item-title{
    @extend %GothamBook;
    font-size: rem(21px);
    line-height: rem(28px);
    margin: 8px 0 0;

    @include tablet {
      font-size: rem(18px);
      line-height: rem(22px);
    }
  }
  .news-item-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 16px -5px 0;

    @include tablet {
      margin: 10px -5px 0;
    }

    &>li{
      padding: 0 5px;
    }
    a{
      border: 1px solid $black;
      border-radius: rem(4px);
      color: $black;
      display: block;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(24px);
      padding: 0 10px;
      transition: all .3s ease;

      &:hover{
        background-color: $black;
        color: $white;
      }
    }
  }
}

.section {
  margin-top: 80px;

  @include tablet {
    margin-top: 40px;
  }
  &.no-space{
    margin-top: 0px;

    @include tablet {
      margin-top: 0px;
    }
    .publications-filter{
      border-top: none;
    }
  }

  hr{
    border: none;
    border-top: 1px solid $border;
    margin: 40px 0;
    @include size(auto, 0px);

    @include tablet {
      margin: 30px 0;
    }
  }
}
.section-title-wrapper{
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
  }
  .button{
    @include mobile {
      margin-top: 10px;
    }
  }
}
.section-title{
  @extend %GothamBold;
  text-transform: uppercase;
  font-size: rem(40px);
  line-height: rem(42px);
  letter-spacing: -1.6px;
  position: relative;

  @include tablet {
    font-size: rem(24px);
    line-height: rem(28px);
    letter-spacing: -.5px;
  }
  em{
    color: $turquoise;
    font-style: normal;
  }
  .section-filter{
    display: inline-flex;
    padding: 20px 0;
    @extend %GothamMedium;
    font-size: rem(14px);
    letter-spacing: 1px;
    line-height: rem(17px);
    margin-left: 15px;
    text-transform: uppercase;
    &.no-uppercase{
      text-transform: none;
    }

    .section-filter-toggle{
      color: $turquoise;
      padding-right: 20px;
      position: relative;
      font-size: rem(44px);
      letter-spacing: -1px;
      line-height: rem(54px);
      cursor: pointer;

      @include tablet {
        font-size: rem(24px);
        line-height: rem(28px);
        letter-spacing: -.5px;
      }

      &:before{
        background-color: $turquoise;
        bottom: 5px;
        content: "";
        left: 0;
        position: absolute;
        right: 20px;
        @include size(auto, 1px);
      }
      &:after{
        content: "";
        opacity: .45;
        position: absolute;
        right: 0;
        top: 50%;
        transition: transform .3s ease;
        transform: translateY(-50%) rotate(90deg);
        @include svgicon('../../images/dropdown_arrow.svg', 12px, 18px);
      }
    }
    .section-filter-item{
      min-width: 100px;
      position: relative;
      @include tablet {
        position: static;
      }

      &.open{
        .section-filter-toggle{
          color: $turquoise;

          &:after{
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .section-filter-dropdown{
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .section-filter-dropdown{
      background-color: $white;
      border: 1px solid $border;
      left: 0;
      margin-top: 15px;
      opacity: 0;
      padding: 30px;
      position: absolute;
      top: 100%;
      transition: all .3s ease;
      visibility: hidden;
      width: 260px;
      z-index: 1;

      @include tablet {
        margin-top: 0px;
      }
      .button{
        margin-top: 15px;
      }
    }
  }
}
.section-subtitle{
  color: rgba($black, .5);
  @extend %Gotham;
  font-size: rem(28px);
  line-height: rem(38px);
  //margin: 0 0 10px;

  @include tablet {
    font-size: rem(18px);
    line-height: rem(26px);
  }
}
.section-intro{
  @extend %Gotham;
  font-size: rem(21px);
  line-height: rem(32px);
  margin: 0;

  @include tablet {
    font-size: rem(16px);
    line-height: rem(22px);
  }
  strong{
    @extend %GothamMedium;
  }
}
.padding-40.padding-40{
  padding-top: 40px;
}

.multiple-content-wrapper{
  position: relative;
  .multiple-content-item{
    position: absolute;
    top:0;
    left:0;
    opacity: 0;
    &:first-child{
      opacity: 1;
    }
  }
}

.mb-100{
  margin-bottom: 110px;
  @include tablet{
    margin-bottom: 60px;
  }
}
.mb-60{
  margin-bottom: 60px;
  @include tablet{
    margin-bottom: 40px;
  }
}
.mt-60{
  margin-top: 60px;
  @include tablet{
    margin-top: 40px;
  }
}

.image{
  img{
    border-radius: rem(4px);
    display: block;
    max-width: 100%;
  }
}
.image-wrapper{

}
.image-caption{
  color: rgba($black, .45);
  @extend %Gotham;
  font-size: rem(17px);
  line-height: rem(26px);
  margin: 15px 0 0;

  @include tablet {
    font-size: rem(14px);
    line-height: rem(18px);
  }
}

.video-wrapper .video-trigger:after {
  z-index: 2;
  border-radius: 100px;
  background-color: #FFF;
  background-position: 54% 50%;
  background-size: 32px;
  height: 80px;
  width: 80px;
}
.video-infos-container {
  display: none;
}
.video-wrapper .video-overlay {
  position: absolute;
  z-index: 1;
  padding: 40px;
  background: $black;
  color: white;
  width: 100%;
  height: 100%;
}
.video-wrapper .video-overlay .video-overlay-title, .video-infos-container .video-title {
  font-size: rem(28px);
  @extend %GothamMedium;
  line-height: rem(32px);
}
.video-wrapper .video-overlay .video-overlay-infos, .video-infos-container .video-infos {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.video-wrapper .video-overlay .video-overlay-infos img, .video-infos-container .video-infos img {
  margin-right: 8px;
}
.video-wrapper .video-overlay .video-overlay-infos span, .video-infos-container .video-infos span {
  margin-right: 16px;
  font-size: rem(17px);
  font-weight: 400;
  line-height: 26px;
}
@media screen and (max-width: 420px) {
  .video-wrapper .video-overlay .video-overlay-title, .video-wrapper .video-overlay .video-overlay-infos {
    display: none;
  }
  .video-wrapper .video-trigger:after {
    border-radius: 100px;
    background-color: #FFF;
    background-position: 54% 50%;
    background-size: 22px;
    height: 56px;
    width: 56px;
  }
  .video-infos-container {
    display: block;
  }
  .video-infos-container .video-title {
    padding: 16px 0;
  }
  .video-infos-container .video-infos {
    margin-top: 0;
  }
  .video-infos-container .video-infos img {
    filter: invert(1);
  }
  .video-infos-container .video-infos span {
    font-size: 14px;
    margin-right: 8px;
  }
}
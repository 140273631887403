.more{
  .section-title {
        margin-bottom: 32px;
        @include tablet {
          margin-bottom: 24px;
        }
    }
  .cell-m-2 {
    @include tablet {
      padding: 0;
    }
  }
  .more-item{
    border-radius: rem(4px);
    color: $white;
    display: block;
    overflow: hidden;
    height: 0;
    padding-bottom: 60%;
    position: relative;
    margin-bottom: 10px;
    &.small{
      padding-bottom: 93%;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }

    img{
      display: block;
      left: 0;
      width: 100%;
      opacity: .8;
      position: absolute;
      top: 0;
      transition: opacity .3s ease, transform 3s ease-out;
      width: 100%;
    }
    &:hover{
      img{
        opacity: 1;
      }
      .more-item-title{
        margin-left: 40px;

        @include tablet {
          margin-left: 30px;
        }

        &:before{
          opacity: 1;
        }
      }
    }
  }
  .more-item-title{
    @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(32px);
    left: 32px;
    bottom: 32px;
    position: absolute;
    transition: all .3s ease;

    @include tablet {
      font-size: rem(16px);
      line-height: rem(22px);
      left: 20px;
      bottom: 20px;
    }

    &:before{
      content: "";
      display: block;
      left: -40px;
      opacity: 0;
      transition: opacity .5s ease;
      @include absolute-center('y');
      @include svgicon('../../images/icon-arrow.svg', 32px, 32px);

      @include tablet {
        left: -30px;
        @include svgicon('../../images/icon-arrow.svg', 22px, 22px);
      }
    }
  }
}

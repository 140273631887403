$mq-mobile : 420px !default;
$mq-tablet : 768px !default;
$mq-tablet-wide: 1024px !default;
$mq-desktop : 1400px !default;

@mixin desktop {
    @media (min-width : $mq-desktop) {
        @content;
    }
}

@mixin tablet {
    @media (max-width : $mq-tablet) {
        @content;
    }
}

@mixin tablet-wide {
    @media (max-width : $mq-tablet-wide) {
        @content;
    }
}



@mixin mobile {
    @media (max-width : $mq-mobile) {
        @content;
    }
}

@mixin target-metro {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
.list-participants{
  @include tablet{
    margin: rem(50px) 0;
  }
  &__title{
    @extend .heading-3;
    text-transform: uppercase;
  }
  &__wrap{
    margin-top: 32px;
    @include tablet {
      margin-top: 24px;
    }
    .row{
      margin: 0;
      align-items: center;
    }
  }
  .col-item{
    padding: 0;

    &:nth-child(3n){
      .list-participants__item{
        border-right: none;
      }
    }
    &:nth-last-child(-n+3){
      .list-participants__item{
        border-bottom: 1px solid $border;
      }
    }
  }
  &__item{
    max-height: 130px;
    padding: 20px 40px;
    border-top: 1px solid $border;
    border-right: 1px solid $border;
    display: flex;
    align-items: center;
    min-height: 122px;
    @include tablet{
      border-right: 0;
      padding: 20px 0;
      border-top: 1px solid $border;
    }
    &__img{
      width: 80px;
      height: 80px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      margin-right: 20px;
      flex-shrink: 0;
    }
    &__right{

      &__name{
        @extend .heading-5;
        margin-bottom: rem(5px);
      }
      &__status{
        @extend .small-link;
        color: $turquoise-black-second;
        text-transform: uppercase;
      }
    }
  }
}
.list-participants-thirdSemester {
  .list-participants__wrap {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    border-top:  1px solid $border;
    border-bottom: 1px solid $border;
    gap: 0 20px;
    @include tablet{
      grid-template-columns: 1fr;
    }
    .list-participants__item {
      padding: 25px 0;
      border:none;
      border-right: 1px solid $border;
      &:nth-child(3n){
        border-right: none;
      }
      &__img {
        margin-right: 32px;
        @include tablet {
            margin-right: 10px;
        }
      }
      @include tablet{
        border-right: none;
        border-bottom: 1px solid $border;
        padding: 16px 0;
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
}
.grid-overflow-hidden {
    overflow: hidden;
}
.key-numbers {
    .subtitle {
        padding-top: 24px;
        @include tablet {
            padding-top: 16px;
        }
    }
}
.key-numbers-slider{
    .slick-list {
        overflow: visible;
        .slick-track {
            padding: 40px 0;
            display: grid;
        }
    }
    .key-numbers-container{
        cursor: grab;
        .key-numbers-item{
            box-shadow: 0px 0px 32px 0px rgba(41, 34, 101, 0.08);
            border-radius: 16px;
            background-color: $white;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: start;
            padding: 42px 64px;
            text-align: center;
            height: 100%;
            margin-right: 40px;
            grid-row: 1;
            position: relative;
            overflow: hidden;
            &:before {
                content: '';
                display: block;
                height: 190px;
                width: 190px;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url('../../images/blue-quart-circle.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                rotate: 90deg;
                z-index: 0;
                @include tablet {
                    height: 123px;
                    width: 123px;
                }
            }
            @include tablet {
                margin-right: 16px;
                padding: 24px 16px;
            }
        }

        .key-numbers-item:nth-child(4n-2):before {
            background-image: url('../../images/green-quart-circle-alt.svg');
            top: unset;
            bottom: 0;
            right: 0;
            left: unset;
            rotate: 0deg;
        }
        .key-numbers-item:nth-child(4n-1):before {
            background-image: url('../../images/yellow-quart-circle-alt.svg');
            top: unset;
            bottom: 0;
            rotate: -90deg;
        }
        .key-numbers-item:nth-child(4n):before {
            background-image: url('../../images/light-blue-quart-circle.svg');
            left: unset;
            right: 0;
            rotate: 180deg;
        }
        .key-numbers-value, .key-numbers-title, .key-numbers-info {
            max-width: 312px;
            position: relative;
            z-index: 1;
        }
        .key-numbers-value{
            @extend %GothamBold;
            font-size: rem(40px);
            line-height: rem(42px);
            letter-spacing: -1.6px;
            max-width: 392px;
            b {
                font-size: rem(100px);
                line-height: rem(115px);
                letter-spacing: -4px;
                @include tablet {
                    font-size: rem(80px);
                    line-height: normal;
                    letter-spacing: -3.2px;
                }
            }
            @include tablet {
                font-size: rem(24px);
                line-height: rem(32px);
                letter-spacing: -0.96px;
            }
        }
        .key-numbers-title {
            @extend %GothamBold;
            font-size: rem(14px);
            line-height: rem(22px);
            letter-spacing: -0.28px;
            text-transform: uppercase;
            @include tablet {
                font-size: rem(14px);
                line-height: rem(22px);
            }
        }
        .key-numbers-info{
            color: rgba(0, 0, 0, 0.60);
            @extend %GothamBook;
            font-size: 14px;
            line-height: rem(20px);
            @include tablet {
                font-size: 14px;
                line-height: rem(20px);
            }

            p {
                font-size: 14px;
            }
        }
    }
}
.slide-controller {
    display: flex;
    justify-content: end;
    gap: 0 16px;
    .slick-arrow {
        width: 48px;
        height: 48px;
        position: unset;
        transform: unset;
        border-radius: 60px;
        border: 1px solid var(--Noir, #000);
        &:before {
            content: '';
        }
        &, &:hover, &:active, &:focus {
            background-image: url('../../images/icon-arrow-black.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px;
        }
        &.slick-prev {
            transform: rotate(180deg);
        }
        &.slick-disabled {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

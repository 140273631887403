.rte{
    b, strong {
        font-weight: bold;
    }

    i,
    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }
    ul{
        padding-left:rem(40px);
        li{
            list-style-type: disc;
            margin-bottom: rem(15px);
        }
    }
    h1{
        @extend .heading-1;
    }
    h2{
        @extend .heading-2;
    }
    h3{
        @extend .heading-3;
    }
    h4{
        @extend .heading-4;
    }
    h5{
        @extend .heading-5;
    }
    a{
        color:#32BBC5;
        &:hover{
            text-decoration: underline;
        }
    }
}
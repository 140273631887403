.push-block {
  .push-block-row {
    align-items: stretch;
    & > div:first-child {
      @include tablet {
        margin-bottom: 24px;
      }
    }
    .push-block-container {
      border-radius: 16px;
      padding: 32px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include tablet {
        padding: 24px;
      }
      .push-block-title {
        font-size: rem(21px);
        @extend %GothamMedium;
        line-height: 28px; /* 135.714% */
        @include tablet {
          font-size: rem(17px);
          line-height: rem(26px);
        }
      }
      .push-block-description {
        font-size: rem(17px);
        line-height: rem(26px);
        margin: 16px 0 40px 0;
        @extend %GothamBook;
        @include tablet {
          margin-top: 8px;
        }
      }
      .button {
        width: fit-content;
        @include mobile {
          width: 100%;
        }
      }
    }
    .push-block-bg-white {
        background-color: $white;
      box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    }
    .push-block-bg-blue {
      background-color: rgba(117, 194, 189, 0.1);
    }
  }
}

.push-modal-section {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  .push-modal-background {
    background-color: #00000040;
    padding: 46px 80px;
    width: 100%;
    @include tablet-wide {
    padding: 0;
  }
    .push-modal-grid {
      background-color: #FFF;
      border-radius: 4px;
      padding: 80px;
      height: 100%;
      position: relative;
      overflow: hidden;
      .push-modal-btn {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid black;
        width: 32px;
        padding: 7px;
        height: 32px;
        background-color: inherit;
        svg {
          width: 16px;
          height: 16px;
        }
        &:hover {
          svg {
            opacity: 0.5;
          }
        }
      }
      @include tablet {
        height: auto;
        padding: 80px 30px 30px 30px;
        margin: 50px 0;
      }
      .push-modal-row {
        @include tablet {
          width: 100%;
        }
      }
      .push-modal-container {
        overflow-y: auto;
        height: 70vh;
        h3 {
          font-size: rem(44px);
          @extend %GothamMedium;
          text-transform: uppercase;
        }
        p {
          font-size: rem(21px);
          line-height: rem(32px);
          margin-bottom: 16px;
        }
        small {
          font-size: rem(14px);
          line-height: rem(16px);
          color: $border;
        }
        @include mobile {
          font-size: rem(16px);
          line-height: rem(22px);
          h3 {
            font-size: rem(24px);
            line-height: rem(28px);
          }
        }
      }
    }
  }
}

// Grid for the project.

$width: 1440px;

// Desktop
$cols: 12;
$gutter: 20px;
$outter: 40px;

// Tablet
$colsTablet: 6;
$gutterTablet: 15px;
$outterTablet: 30px;

// Mobile
$colsMobile: 2;
$gutterMobile: 5px;
$outterMobile: 20px;


.grid {
    width: $width;
    padding: 0 $outter;
    max-width: 100%;
    margin: 0 auto;

    @include tablet {
        padding: 0 $outterTablet;
    }

    @include mobile {
        padding: 0 $outterMobile;
    }

}

.row {
    margin-left: -$gutter;
    margin-right: -$gutter;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    &--full-h {
        align-items: stretch;
    }

    @include tablet {
        margin-left: -$gutterTablet;
        margin-right: -$gutterTablet;
    }

    @include mobile {
        margin-left: -$gutterMobile;
        margin-right: -$gutterMobile;
    }
}

.g-row {
    margin-left: -$gutter;
    margin-right: -$gutter;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $gutter;

    @include tablet {
        margin-left: -$gutterTablet;
        margin-right: -$gutterTablet;
        grid-gap: $gutterTablet;
    }

    @include mobile {
        margin-left: -$gutterMobile;
        margin-right: -$gutterMobile;
        grid-gap: $gutterMobile;
    }
}

[class*="g-cell-"] {
    padding-left: $gutter;
    padding-right: $gutter;

    @include tablet {
        padding-left: $gutterTablet;
        padding-right: $gutterTablet;
    }
    @include mobile {
        padding-left: $gutterMobile;
        padding-right: $gutterMobile;
    }
}

[class*="cell-"] {
    padding-left: $gutter;
    padding-right: $gutter;

    @include tablet {
        padding-left: $gutterTablet;
        padding-right: $gutterTablet;
    }
    @include mobile {
        padding-left: $gutterMobile;
        padding-right: $gutterMobile;
    }
}

@for $i from 1 through $cols {
    .cell-#{$i} {
        width: (100% / $cols) * $i;
    }
    .g-cell-#{$i} {
        grid-column: span $i;
    }
}

@for $i from 1 through $cols {
    .prepend-#{$i} {
        margin-left: (100% / $cols) * $i;
    }
}

@for $i from 1 through $cols {
    .append-#{$i} {
        margin-right: (100% / $cols) * $i;
    }
}

@include tablet {

    [class*="cell-"] {
        width: 100%;
    }
    [class*="g-cell-"] {
        grid-column: span 12;
    }

    [class*="prepend-"] {
        margin-left: 0;
    }

    [class*="append-"] {
        margin-right: 0;
    }

    @for $i from 1 through $colsTablet {
        .cell-t-#{$i} {
            width: (100% / $colsTablet) * $i;
        }
    }

    @for $i from 1 through $colsTablet {
        .prepend-t-#{$i} {
            margin-left: (100% / $colsTablet) * $i;
        }
    }

    @for $i from 1 through $colsTablet {
        .append-t-#{$i} {
            margin-right: (100% / $colsTablet) * $i;
        }
    }

}

@include mobile {
    [class*="cell-t-"] {
        width: 100%;
    }

    [class*="g-cell-t-"] {
        grid-column: span 12;
    }
    [class*="prepend-t-"] {
        margin-left: 0;
    }

    [class*="append-t-"] {
        margin-right: 0;
    }

    @for $i from 1 through $colsMobile {
        .cell-m-#{$i} {
            width: (100% / $colsMobile) * $i;
        }
    }

    @for $i from 1 through $colsMobile {
        .prepend-m-#{$i} {
            margin-left: (100% / $colsMobile) * $i;
        }
    }

    @for $i from 1 through $colsMobile {
        .append-m-#{$i} {
            margin-right: (100% / $colsMobile) * $i;
        }
    }
}

// Grid dev

.dev-grid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    .grid {
        height: 100%;
    }

    .row {
        height: 100%;
    }

    [class*="cell-"] {
        position: relative;
        height: 100%;

        @include tablet {
            display: none;
        }

        &:before {
            content: "";
            width: 100%;
            height: 100vh;
            display: block;
            background-color: RGBA(219, 71, 69, 0.30);
        }
    }

    [class*="cell-t-"] {
        display: block;

        @include mobile {
            display: none;
        }
    }

    [class*="cell-m-"] {
        @include mobile {
            display: block;
        }
    }

    &__btn {
        @extend %Gotham;
        font-size: rem(14px);
        line-height: rem(24px);
        position: fixed;
        z-index: 100;
        bottom: 25px;
        left: 25px;
        display: block;
        padding: 10px 15px;
        color: $white;
        text-transform: uppercase;
        background-color: $brand;
        border: none;
        cursor: pointer;
        border-radius: 3px;
    }

    &.is-hidden {
        display: none;
    }
}

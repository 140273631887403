.streaming-live{
  margin-top: rem(100px);
  @include tablet{
    margin-top: rem(50px);
  }
  &__title{
    @extend .heading-3;
    margin-bottom: rem(30px);
    span{
      color: $turquoise;
    }
  }
  &__text{
    @extend .body;
    margin-bottom: rem(30px);
  }
  &__cover{
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    line-height: 0;
    margin-bottom: rem(10px);
    img{
      width: 100%;
      height: auto;
    }
    &__button{
      position: absolute;
      left:30px;
      bottom: 30px;
      display: flex;
      align-items: center;
      height: 48px;
      border-radius: 24px;
      padding-left: 58px;
      padding-right: 18px;
      background-color: $turquoise;
      color: $white;
      @extend .body-small;
      span{
        font-weight: bold;
      }
      @include mobile{
        bottom: 15px;
        left:15px;
        right: 15px;
        height: 38px;
        padding-left: 48px;
      }
      &:before{
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        @include svgicon('../../images/icon_play.svg', 48px, 48px);
        top:0;
        left:0;
        @include mobile{
          width: 38px;
          height: 38px;
          @include svgicon('../../images/icon_play.svg', 38px, 38px);
        }
      }
    }
  }
  &__figcaption{
    @extend .body-small;
    opacity: 0.45;
  }
}
.keyfigures-mosaic{
  .row-title{
    margin-bottom: rem(40px);
  }
  .col-left{
    @include tablet-wide{
      width: 50%;
    }
    @include tablet{
      width: 100%;
    }
  }
  .col-right{
    @include tablet-wide{
      width: 50%;
    }
    @include tablet{
      display: none;
    }
  }
  &__title{
    margin-bottom: 0;
    @extend .heading-3;
  }
  &__list-wrap{
    overflow: hidden;
    position: relative;
  }
  &__bullets{
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:25px;
    display: none;
    @include tablet{
      display: flex;
    }
    li{
      margin: 0 5px;
      width: 10px;
      height: 10px;
      background-color: $black;
      opacity: 0.15;
      transition: all 250ms;
      border-radius: 50%;
      &.is-current{
        opacity: 1;
      }
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 -5px;
    @include tablet{
      flex-wrap: nowrap;
      touch-action: pan-y;
      margin: 0;
    }
    &__item{
      cursor: pointer;
      appearance: none;
      border: 0;
      padding: 34px 15px 40px 15px;
      background-color: $turquoise-third;
      color: $turquoise;
      width: calc(50% - 10px);
      flex-shrink: 0;
      flex-grow: 0;
      margin: 0px 5px;
      margin-bottom: 10px;
      min-height: 180px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      transition: all 500ms;
      position: relative;
      &.is-active, &:hover{
        color: $white;
        background-color: $turquoise;
        @include tablet{
          background-color: $turquoise-third;
          color: $turquoise;
        }
      }
      @include tablet{
        margin: 0;
        width:100%;
        min-height: 279px;
      }
      &__number{
        @extend %GothamNarrow;
        font-size: rem(70px);
        line-height: rem(80px);
        letter-spacing: rem(-2px);
      }
      &__text{
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(14px);
        text-transform: uppercase;
      }
      &__btn{
        display: none;
        width: 32px;
        height: 32px;
        background-image: url("../../images/round-plus.svg");
        background-size: contain;
        background-position: center;
        position: absolute;
        bottom:15px;
        right: 15px;
        appearance: none;
        border: 0;
        background-color: transparent;
        border-radius: 50%;
        @include tablet{
          display: block;
        }
      }
    }
  }
  &__content{
    &__item{
      display: none;
      &.is-active{
        display: block;
      }
      img{
        width: 100%;
        height: auto;
        margin-bottom: rem(20px);
      }
      &__title{
        @extend .heading-5;
        margin-bottom: rem(15px);
      }
      &__content{
        line-height: rem(22px);
      }
    }
  }
  &__panel{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 101;
    display: none;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all 250ms;
    @include tablet{
      display: block;
    }
    &.is-active{
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
    &.is-active &{
      &__content{
        transform: translateX(0%);
      }
    }
    &__overlay{
      background-color: black;
      opacity: 0.6;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
    &__content{
      position: absolute;
      top:0;
      height:100vh;
      right: 0;
      background-color: $white;
      width: 400px;
      padding-top: 65px;
      overflow-y: auto;
      transform: translateX(50px);
      transition: all 250ms;
      @include mobile{
        width: 265px;
      }
      &__close{
        width: 25px;
        height: 25px;
        background-image: url("../../images/icon_close_turquoise.svg");
        background-size: cover;
        background-position: center;
        background-color: transparent;
        border: 0;
        position: absolute;
        top:23px;
        right:15px;
      }
      img{
        width: 100%;
        height: auto;
        margin-bottom: rem(20px);
      }
      &__wrapper{
        &__inner{
          padding: 0 25px;
          padding-bottom: 50px;
          &__title{
            @extend .heading-5;
            margin-bottom: rem(20px);
          }
          &__text{
            line-height: rem(22px);
          }
        }
      }
    }
  }
}
.hero-banner {
    margin-top: -16px;
}
.hero-banner-bg-filter {
  backdrop-filter: brightness(50%);
  height: 100%;
  color: #FFF;
  & li > a {
    color: #FFF;
    &:after {
      background-image: url(../../images/icon_arrow_small_left_white.svg);
    }
  }
}

.hero-banner-grid {
  @include tablet {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 42px;
  }
}

.hero-banner-bg-default {
  height: 500px;
  @include mobile {
    height: 450px;
  }
}

.hero-banner-bg-big {
  height: 650px;
  @include mobile {
    height: 500px;
  }
}

.hero-banner-bg {
  background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-banner-breadcrumb {
  padding-top: 16px;
  @include mobile {
    padding-top: 50px;
  }
  & > li:last-child {
    @extend %GothamBold;
  }
}


.hero-banner-page-title {
  padding-top: 68px;

  .button-solid {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-align: left;
    @include tablet {
      font-weight: 600;
      letter-spacing: 0.48px;
    }
  }
  @include tablet {
    padding-top: 20px;
    margin: auto 0 0 0;
  }
  @include mobile {
    font-size: rem(35px);
    line-height: rem(38px);
  }
}
.simpleText {
  @include tablet {
    padding-bottom: 32px;
  }

  .hero-banner-page-title {
    padding-top: 88px;
    @include tablet {
      margin-top: 16px;
      padding: 0;
    }
  }

  .hero-banner-grid {
    @include tablet {
      padding-bottom: 0 !important;
    }
  }
}
  .hero-banner-page-sub-title {
    padding: 16px 0;
    font-size: 1.3125em;
    line-height: 1.35em;
    @include mobile {
      font-size: rem(16px);
      line-height: rem(22px);
    }
  }

  .hero-banner-button {
    margin: 0;
    transition: all .3s ease;
    &:hover {
      background-color: $grey-third;
    }
  }

  .hero-banner-date {
    font-size: rem(14px);
    text-transform: uppercase;
    @extend %GothamMedium;
  }

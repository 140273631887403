.team{
  .team-list{
    margin-top: 40px;
    @include tablet {
      margin-top: 32px;
      gap: 24px 0;
    }

    &>li{
      a{
        display: block;

        &:hover{
          img{
            transform:scale(1.1) ;
          }
        }
      }

    }
    .team-list-visual{
      border-radius: 16px;
      overflow: hidden;
      line-height: 0;
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @include mobile{
        margin: 0 auto;
      }

      img{
        display: block;
        margin: 0;
        position: relative;
        transition: transform 3s ease-out;
        object-fit: cover;
        @include size(100%, 384px);

        @include mobile {
          @include size(170px, 211px);
          border-radius: 16px;
        }
      }
    }
    .team-list-name{
      @extend %GothamMedium;
      color: $turquoise-black-second;
      font-size: rem(21px);
      line-height: rem(28px);
      margin: 20px 0 0;
      @include tablet {
        font-size: rem(17px);
        line-height: rem(26px);
        margin: 15px 0 0;
      }
    }
    .team-list-position{
      @extend %GothamBook;
      font-size: rem(14px);
      line-height: rem(20px);
      margin: 8px 0 0;
    }
  }
}
body.show-team-modal{
  overflow: hidden;

  &:after{
    opacity: 1;
    visibility: visible;
  }

  .team-modal{
    display: flex;
    opacity: 1;
    visibility: visible;

    @include mobile{
      display: block;
    }
  }
}
.member-card {
  padding-bottom: 30px;
  @include mobile{
    width: 170px;
  }
}
.team-modal{
  background-color: $white;
  bottom: 0;
  display: flex;
  left: auto;
  max-width: 1440px;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .4s ease, visibility .4s ease;
  visibility: hidden;
  width: 100%;
  z-index: 100;

  @include mobile{
    display: block;
    overflow-y: scroll;
  }

  .team-modal-close{
    background-color: $white;
    border: 1px solid rgba($black, .15);
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 1;
    @include circle(48px);
    cursor: pointer;

    @include mobile{
      position: fixed;
      right: 15px;
      top: 15px;
    }

    &:before{
      content: "";
      @include absolute-center;
      @include svgicon('../../images/icon_close.svg', 16px, 16px);
    }
  }
  .team-modal-portrait{
    overflow: hidden;
    position: relative;
    flex: 0 0 520px;

    @media (max-width: 1200px){
      flex: 0 0 320px;
    }
    @include mobile{
      height: 510px;
      max-height: calc(100vh - 120px);
    }

    img{
      position: absolute;
      left: -9999px;
      right: -9999px;
      top: -9999px;
      bottom: -9999px;
      margin: auto;
      min-width: 100%;
      min-height: 100%;

      @media (max-width: 1200px){
        bottom: auto;
        right: auto;
        min-width: 0%;
        min-height: 0%;
        top: 0;
        width: 100%;
        @include absolute-center('x');
      }
      @include mobile{
        left: -9999px;
        right: -9999px;
        top: -9999px;
        bottom: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        transform: none;
        width: auto;
      }
    }
  }
  .team-modal-content{
    flex: 1;
    overflow: hidden;
    overflow-y: scroll;
    padding: 100px;

    @media (max-width: 1200px){
      padding: 100px 40px;
    }
    @include tablet{
      padding: 100px 40px;
    }
    @include mobile{
      padding: 30px 15px;
      overflow: hidden;
    }

  }
  .team-modal-name{
    @extend %GothamMedium;
    font-size: rem(60px);
    line-height: rem(74px);
    margin: 0;

    @media (max-width: 1200px){
      font-size: rem(35px);
      line-height: rem(38px);
    }
  }
  .team-modal-position{
    @extend %Gotham;
    font-size: rem(28px);
    line-height: rem(38px);
    margin: 15px 0 0;

    @media (max-width: 1200px){
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }
  hr{
    border: none;
    border-top: 1px solid $black;
    margin: 40px 0;
    @include size(100%, 0px);

    @media (max-width: 1200px){
      margin: 20px 0;
    }
  }
  .team-modal-line{
    display: flex;
    @extend %Gotham;
    font-size: rem(21px);
    line-height: rem(32px);

    @media (max-width: 1200px){
      flex-direction: column;
      font-size: rem(16px);
      line-height: rem(22px);
    }
  }
  .team-modal-title{
    flex: 0 0 190px;

    @media (max-width: 1200px){
      flex: 0 0 auto;
      margin: 0 0 15px;
    }
  }
  .team-modal-infos{
    flex: 1;

    p{
      margin: 0 0 32px;
    }
    .team-modal-distinctions{
      &>li{
      }
      p{
        display: flex;
        margin: 0;

        .year{
          display: block;
          flex: 0 0 90px;
        }
        span{
          display: block;
        }
      }
    }
  }
}

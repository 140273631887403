.jobboard-top{
  padding-bottom: rem(74px);
  border-bottom: 1px solid $border;
  @include tablet{
    padding-bottom: rem(30px);
  }
  &__list{
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    li{
      padding-bottom: rem(20px);
      @media screen and (max-width: 1200px){
        margin-right: 50px;
      }
      @include tablet{
        margin-right: 25px;
      }
      a{
        @extend .heading-3;
        transition: all 250ms;
        display: inline-block;
        white-space: nowrap;
        &:hover{
          color:$turquoise;
        }
        &.is-current{
          color:$turquoise;
          position: relative;
          &:after{
            content:"";
            position: absolute;
            top:calc(100% + 5px);
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $turquoise;
          }
        }
      }
    }
  }
}
.photo-gallery{
  overflow: hidden;

  .photo-gallery-col{
    position: relative;
  }
  .photo-gallery-slider{
    overflow: visible !important;
    &:after{
      content: "";
      display: table;
      clear: both;
    }
    .show{
      .photo-gallery-item{
        .photo-gallery-caption{
          opacity: 1;
        }
      }
    }
  }
  .photo-gallery-item{
    margin-right: 40px;

    @include mobile{
      margin-right: 10px;
    }

    img{
      display: block;
      width: 100%;
    }
    .photo-gallery-caption{
      color: rgba($black, .5);
      @extend %Gotham;
      font-size: rem(17px);
      line-height: rem(26px);
      margin: 20px 0 0;
      min-height: 50px;
      opacity: 0;
      padding-right: 120px;
      transition: opacity .3s ease;

      @include mobile{
        font-size: rem(14px);
        line-height: rem(18px);
        margin: 10px 0 0;
        padding: 0;
      }
    }
  }
  .photo-gallery-prev,
  .photo-gallery-next{
    background-color: $white;
    border: 1px solid rgba($black, .15);
    cursor: pointer;
    display: block;
    margin-top: -48px;
    outline: none;
    position: absolute;
    right: 60px;
    top: 100%;
    transition: background-color .3s ease, border-color .3s ease;
    z-index: 1;
    @include circle(48px);

    @include mobile{
      display: none;
    }

    &:hover{
      background-color: $black;
      border-color: $black;

      svg{
        &>g{
          fill: none;
          *[fill]{
            fill: $white;
          }
          *[stroke]{
            stroke: $white;
          }
        }
      }
    }
    svg{
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .photo-gallery-prev{
    right: 120px;

    svg{
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

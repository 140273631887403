.financing-program {
  &.turquoise {
    background-color: rgba(117, 194, 189, 0.1);
    padding-bottom: 80px;
    padding-top: 40px;
  }
  @include tablet {
    padding-top: 32px;
  }
  .financing-program-title {
    font-size: rem(32px);
    @extend %GothamBold;
    margin-bottom: rem(24px);
    line-height: 32px; /* 100% */
    letter-spacing: -1.28px;
    text-transform: uppercase;
    @include tablet {
      font-size: rem(20px);
    }
  }
  .sinceDate {
    font-size: rem(21px);
    @extend %GothamMedium;
    line-height: rem(32px);
    padding: 32px 0 0 0;
    @include tablet{
      font-size: rem(16px);
      line-height: rem(22px);
    }
  }
  .subtitle {
    font-size: rem(17px);
    @extend %GothamBook;
    line-height: rem(26px);
  }
}
.pagination{
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;

  &.pagination-alt{
    @include tablet-wide{
      align-items: center;
    }
    .button{
      @include tablet-wide{
        //width: 50px;
        //height: 50px;
        //border-radius: 100%;
        //padding: 0;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //flex-flow: column;
      }
      .button-icon{
        &:first-child{
          @include tablet-wide{
            //margin-right: 0;
          }
        }
        &:last-child{
          @include tablet-wide{
            //margin-left: 0;
          }
        }
      }
      span{
        @include tablet-wide{
          //display: none;
        }
      }
    }
    .pagination-pages{
      @include tablet-wide{
        display: flex;
      }
      @include tablet{
        display: none;
      }
      &>li{
        @include tablet-wide{
          margin: 0;
        }
        a,span{
          @include tablet-wide{
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .pagination-pages{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &>li{
      a, span{
        align-items: center;
        display: flex;
        justify-content: center;
        @extend %GothamMedium;
        font-size: rem(14px);
        margin: 0 3px;
        transition: all .3s ease;
        @include circle(48px);
      }
      a{
        background-color: transparent;
        color: $turquoise-black;

        &:hover{
          background-color: $turquoise-black;
          color: $white;
        }
      }
      span{
        background-color: $turquoise-black;
        color: #fff;
      }
    }
  }
}

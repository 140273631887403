.media-kit{
  overflow: hidden;
}
.media-kit-title{
  align-items: center;
  display: flex;

  .section-title{
    margin-bottom: 0;
    margin-right: 30px;

    @include mobile{
      margin-right: 0;
    }
  }

  .media-kit-slider-prev,
  .media-kit-slider-next{
    background-color: $white;
    border: 1px solid rgba($black, .15);
    cursor: pointer;
    display: block;
    margin-left: 5px;
    outline: none;
    position: relative;
    //right: 60px;
    //top: 100%;
    transition: background-color .3s ease, border-color .3s ease;
    z-index: 1;
    @include circle(48px);

    @include mobile{
      display: none;
    }

    &:hover{
      background-color: $black;
      border-color: $black;

      svg{
        &>g{
          fill: none;
          *[fill]{
            fill: $white;
          }
          *[stroke]{
            stroke: $white;
          }
        }
      }
    }
    svg{
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .media-kit-slider-prev{
    //right: 120px;

    svg{
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
.media-kit-slider{
  margin-right: -40px;
  margin-top: 40px;
  overflow: visible !important;

  @include mobile {
    margin-right: -10px;
    margin-top: 30px;
  }

  &>div{
    &>div{
      padding-right: 40px;

      @include mobile {
        padding-right: 10px;
      }
    }
  }
}
.presskit-item{
  background-color: #EBF9FA;

  &.wide{
    display: flex;

    @include mobile {
      flex-direction: column;
    }
    .presskit-item-visual{
      flex: 0 0 290px;

      @include mobile {
        flex: 1 1 auto;
      }
    }
    .presskit-item-infos{
      flex: 1 1 auto;

      .presskit-item-title{
        margin: 0 0 10px;
        @extend .heading-4;
      }
      .presskit-item-download{
        right: 40px;
        bottom: 40px;
        top: auto;

        @include mobile {
          bottom: auto;
          right: 20px;
          top: 20px;
        }
      }
    }
  }

  .presskit-item-visual{
    .presskit-item-visual-wrapper{
      height: 0;
      overflow: hidden;
      position: relative;
      padding-bottom: 120%;
    }
    img{
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .presskit-item-infos{
    padding: 40px;
    position: relative;

    @include mobile {
      padding: 15px 20px;
    }

    .presskit-item-title{
      margin: 0 0 10px;
      @extend .heading-5;
    }
    .presskit-item-details{
      color: $turquoise;
      @extend .small-link;
    }
    .presskit-item-download{
      background-color: $black;
      position: absolute;
      right: 20px;
      bottom: 20px;
      @include circle(48px);

      @include mobile {
        bottom: auto;
        right: 20px;
        top: 20px;
        @include circle(40px);
      }

      svg{
        @include absolute-center;

        &>g{
          fill: none;
          *[fill]{
            fill: $white;
          }
          *[stroke]{
            stroke: $white;
          }
        }
      }
    }
  }
}

.m-0 {
  margin: 0;
}
.m-0-top {
  margin-top: 0;
}
.m-0-right {
  margin-right: 0;
}
.m-0-bottom {
  margin-bottom: 0;
}
.m-0-left {
  margin-left: 0;
}

.m-1 {
  margin: 8px;
}
.m-1-top {
  margin-top: 8px;
}
.m-1-right {
  margin-right: 8px;
}
.m-1-bottom {
  margin-bottom: 8px;
}
.m-1-left {
  margin-left: 8px;
}

.m-2 {
  margin: 16px;
}
.m-2-top {
  margin-top: 16px;
}
.m-2-right {
  margin-right: 16px;
}
.m-2-bottom {
  margin-bottom: 16px;
}
.m-2-left {
  margin-left: 16px;
}

.m-3 {
  margin: 24px;
}
.m-3-top {
  margin-top: 24px;
}
.m-3-right {
  margin-right: 24px;
}
.m-3-bottom {
  margin-bottom: 24px;
}
.m-3-left {
  margin-left: 24px;
}

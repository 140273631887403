.talent-reasons{
  .talent-reasons-list{
    display: flex;
    margin: 30px 0 0;

    @media (max-width: 1200px){
      display: block;
      max-width: 740px;
    }

    &.has-open-item{
      .talent-reasons-list-item{
        flex: 1 1 auto;

        &:hover{
          .talent-reasons-list-moar{
            span{
              opacity: 0;
            }
          }
        }
        .talent-reasons-list-title{
          opacity: 0;
          visibility: hidden;

          @media (max-width: 1200px){
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .talent-reasons-list-item{
      border-left: 1px solid rgba($black, .35);
      cursor: pointer;
      display: flex;
      flex: 1 1 100%;
      height: 430px;
      overflow: hidden;
      padding: 30px;
      position: relative;
      transition: flex .4s ease;

      @media (max-width: 1200px){
        border-left: none;
        border-bottom: 1px solid rgba($black, .35);
        display: block;
        height: auto;
        padding: 0 0 20px;
      }
      &>div{
        &:first-child{
          @media (max-width: 1200px){
            align-items: flex-end;
            display: flex;
            padding-right: 60px;
          }
          @include mobile{
            padding-right: 40px;
          }
        }
      }

      &:first-child{
        border-left: none;
      }
      &:hover{
        .talent-reasons-list-num{
          color: $black;
        }
        .talent-reasons-list-moar{
          //color: $turquoise;

          &:before,
          &:after{
            //background-color: $turquoise;
          }
          span{
            opacity: 1;
          }
        }
      }
      &.active{
        flex: 0 0 60%;
        .talent-reasons-list-desc{
          position: static;
        }
        .talent-reasons-list-num{
          color: $black;
        }
        .talent-reasons-list-title{
          opacity: 1;
          visibility: visible;
        }
        .talent-reasons-list-moar{
          //color: $turquoise;

          &:before,
          &:after{
            //background-color: $turquoise;
            transform: translateY(-50%) rotate(-45deg);
          }
          &:after{
            transform: translateY(-50%) rotate(-135deg);
          }
        }
        .talent-reasons-list-moar{
          span{
            opacity: 1 !important;

            &:before{
              content: "Voir moins";
            }
          }
        }
      }
    }
    .talent-reasons-list-num{
      color: $turquoise;
      @extend %GothamNarrow;
      font-size: rem(140px);
      line-height: rem(125px);
      transition: color .3s ease;

      @media (max-width: 1200px){
        flex: 0 0 50px;
        font-size: rem(70px);
        line-height: rem(80px);
        position: relative;
        top: 22px;
        width: 50px;
      }
      @include mobile{
        flex: 0 0 35px;
      }
    }
    .talent-reasons-list-title{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(27px);
      letter-spacing: -.6px;
      min-width: 200px;
      transition: all .3s ease;
      word-break: break-word;
      @media (max-width: 1200px){
        font-size: rem(18px);
        line-height: rem(22px);
      }
    }
    .talent-reasons-list-desc{
      display: none;
      @extend %Gotham;
      font-size: rem(21px);
      left: 200px;
      line-height: rem(32px);
      margin-left: 30px;
      position: absolute;

      @media (max-width: 1200px){
        font-size: rem(16px);
        line-height: rem(22px);
        margin: 0;
        padding-top: 20px;
        position: static;
      }
    }
    .talent-reasons-list-moar{
      bottom: 30px;
      padding-right: 40px;
      right: 30px;
      position: absolute;

      @media (max-width: 1200px){
        bottom: auto;
        right: 20px;
        top: 65px;
      }
      @include mobile{
        right: 0;
      }

      &:before{
        background-color: $black;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color .3s ease, transform .3s ease, opacity .5s ease;
        @include size(24px, 2px);
      }
      &:after{
        background-color: $black;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        transition: background-color .3s ease, transform .3s ease, opacity .5s ease;
        @include size(24px, 2px);
      }
      span{
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(17px);
        opacity: 0;
        text-transform: uppercase;
        transition: opacity .3s ease;
        white-space: nowrap;

        @media (max-width: 1200px){
          display: none;
        }

        &:before{
          content: "Voir plus";
        }
      }
    }
  }
}

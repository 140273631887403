.investir-financial-documents .title-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.investir-financial-documents .cells-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.investir-financial-documents .documents-container {
  transition-duration: 0.3s;
  overflow: visible;
}
.investir-financial-documents .document-cell {
  flex: 0 0 auto;
  padding: 0 20px 0 0;
  margin-bottom: 40px;
}
.investir-financial-documents .document img {
  width: 100%;
  border: 1px solid #04142D;
  margin-bottom: 16px;
}

.investir-financial-documents .document .title {
  font-size: 21px;
  font-style: normal;
  @extend %GothamMedium;
  line-height: 26px; /* 123.81% */
  margin-bottom: 10px;
    &:hover {
      color: $turquoise-black-second;
    }
}
.investir-financial-documents .document .informations {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 5px;
}
.investir-financial-documents .document .informations .date {
  @extend %GothamMedium;
  font-size: 12px;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.investir-financial-documents .document .informations .size {
  color: #32BBC5;
  font-size: 12px;
  @extend %GothamMedium;
  line-height: 14px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  margin-left: 20px;
}
.investir-financial-documents .button {
  padding: 12px 24px;
}
.investir-financial-documents .document .button::after {
  content: url("../../images/investir-icade/icadeDownload.svg");
}

.investir-financial-documents .button-cell .button {
  width: 41px;
  height: 41px;
  padding: 9px;
  margin-right: 8px;
}
@media screen and (max-width: 420px) {
  .investir-financial-documents .title-cell {
    display: block;
  }
  .investir-financial-documents .document .type {
    font-size: 12px;
  }
  .investir-financial-documents .document .title {
    min-height: 0;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .investir-financial-documents .document .informations {
    margin-bottom: 16px;
  }
  .investir-financial-documents .button-cell .button {
    display: inline-block;
  }
}

.sub-menu {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: $black;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;

  @include tablet-wide {
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #FFF;
  }
  @include tablet {
    position: fixed;
  }



  .sub-menu-list {
    display: flex;
    align-items: center;
    @include tablet-wide {
      display: none;
    }
    .sub-menu-item {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        display: block ruby;
        padding-right: 0;
        margin: 0 0 0 auto;
      }
      .sub-menu-link {
        display: block;
        padding: 16px 0;
        @extend %GothamMedium;
        font-size: rem(14px);

        &.is-current, &:hover {
          color: $turquoise;
          border-bottom: solid 1px $turquoise;
        }
        &:last-child {
          display: inline-block;
        }
      }
      .sub-menu-separator {
        border-left: solid 1px $border;
        padding-right: 16px;
      }
    }

  }
}

.sub-menu-details {
  display: none;
  @include tablet-wide {
    display: block;
  }
  padding-bottom: 17px;
  @extend %GothamMedium;
  font-size: rem(12px);
  .sub-menu-link {
    font-size: rem(12px);
    padding-top: 16px;
    display: block;
  }
  .sub-menu-separator {
    border-top: 1px solid $border;
    margin-top: 16px;
    display: block;
  }

  .sub-menu-details,
  .sub-menu-summary {
    cursor: pointer;
    transition: margin 300ms ease-out;
    &::-webkit-details-marker {
      display:none;
    }
  }

  .sub-menu-summary {
    margin-bottom: -10px;
    border-bottom: solid 1px $border;
    display: flex;
    justify-content: space-between;
    list-style: none;
    &:after {
      content: "";
      transform: translateY(-50%) rotate(90deg);
      transition: transform .3s ease;
      background-color: transparent;
      background-image: url(../../images/dropdown_arrow.svg);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 14px 14px;
      height: 14px;
      width: 14px;
      margin-top: 18px;
    }
    & > span {
      padding: 13px 0 0;
    }

  }
}
.sub-menu-details[open] .sub-menu-summary {
  margin-bottom: 0;
  &:after {
    transform: translateY(-50%) rotate(-90deg);
  }
}

.sub-menu-fixed:after {
  content: "";
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  background-color: #00000040;
}

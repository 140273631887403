@function rem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
// Size
@mixin size($width : auto, $height : auto) {
    height: $height;
    width: $width;
}
@mixin square($size) {
    @include size($size, $size);
}

@mixin circle($size) {
    border-radius: $size;
    @include size($size, $size);
}

// Sprite
@mixin sprite($left : 0, $top : 0 , $color : transparent) {
	background: $color url(img/sprite.png) $left $top no-repeat;
	display: block;
}

@mixin background($path, $size: auto auto, $pos: left top, $repeat: no-repeat) {
  $at1x_path: "#{$path}";
  $at2x_path: str-replace(#{$path}, '.png', '@2x.png');
  $at2x_path: str-replace(#{$at2x_path}, '.jpg', '@2x.jpg');
  $at2x_path: str-replace(#{$at2x_path}, '.jepg', '@2x.jepg');
  // $at3x_path: str-replace(#{$path}, '.', '@3x.');

  background-image: url("#{$at1x_path}");
  background-size: $size;
  background-position: $pos;
  background-repeat: $repeat;

  /* @2x Images (Pixel Ratio of 1.25+) */
  @media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx) {
    background-image: url("#{$at2x_path}");
  }

  /* @3x Images (Pixel Ratio of 2.25+) */
  // @media only screen and (-o-min-device-pixel-ratio: 9/4),
  // only screen and (-webkit-min-device-pixel-ratio: 2.25),
  // only screen and (min-device-pixel-ratio: 2.25),
  // only screen and (min-resolution: 2.25dppx) {
  //   background-image: url("#{$at3x_path}");
  // }
}

// Font Stacks
@mixin font($weight: null, $size: null, $lineHeight: null) {
    @if($size) { font-size: $size; }
    @if($weight) { font-weight: $weight; }
    @if($lineHeight) { line-height: $lineHeight; }
}

// Gradients
@mixin gradient-horizontal ($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, right top, from($startColor), to($endColor)); // Konqueror
    background-image: -moz-linear-gradient(left, $startColor, $endColor); // FF 3.6+
    background-image: -ms-linear-gradient(left, $startColor, $endColor); // IE10
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $startColor), color-stop(100%, $endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, $startColor, $endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, $startColor, $endColor); // Opera 11.10
    background-image: linear-gradient(to right, $startColor, $endColor); // Le standard
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$startColor}, endColorstr=#{$endColor}, GradientType=1); // IE9 and down
}
@mixin gradient-vertical ($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from($startColor), to($endColor)); // Konqueror
    background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
    background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $startColor), color-stop(100%, $endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, $startColor, $endColor); // The standard
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$startColor}, endColorstr=#{$endColor}, GradientType=0); // IE9 and down
}
@mixin gradient-directional ($startColor: #555, $endColor: #333, $deg: 45deg) {
    background-color: $endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient($deg, $startColor, $endColor); // FF 3.6+
    background-image: -ms-linear-gradient($deg, $startColor, $endColor); // IE10
    background-image: -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient($deg, $startColor, $endColor); // Opera 11.10
    background-image: linear-gradient($deg, $startColor, $endColor); // The standard
}
@mixin gradient-vertical-three-colors($startColor: #00b3ee, $midColor: #7a43b6, $colorStop: 50%, $endColor: #c3325f) {
    background-color: $endColor;
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor));
    background-image: -webkit-linear-gradient($startColor, $midColor $colorStop, $endColor);
    background-image: -moz-linear-gradient(top, $startColor, $midColor $colorStop, $endColor);
    background-image: -ms-linear-gradient($startColor, $midColor $colorStop, $endColor);
    background-image: -o-linear-gradient($startColor, $midColor $colorStop, $endColor);
    background-image: linear-gradient($startColor, $midColor $colorStop, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$startColor}, endColorstr=#{$endColor}, GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin absolute-center($axis: "both"){
	position:absolute;
  	@if $axis == "y" {
    	top: 50%;
    	transform: translateY(-50%);
  	}
  	@if $axis == "x" {
    	left: 50%;
    	transform: translateX(-50%);
  	}
  	@if $axis == "both" {
    	top:50%;
    	left:50%;
    	transform: translate(-50%, -50%);
	}
}

//letter-spacing
@mixin letter-spacing($font-size, $letterspacing) {
    letter-spacing: (($font-size * $letterspacing) /1000) + px;
}

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}


// Input placeholder text
@mixin placeholder($color: $grayLight) {
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
  }
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: $color;
  }
  ::placeholder { /* Recent browsers */
    color: $color;
  }
}

@mixin svgicon($image, $w: 0, $h: 0){
    background-color: transparent;
    @if $image == null{
        background-image: none;
    } @else {
        background-image: url(#{$image});
    }
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: $w $h;
    @include size($w, $h);
}

.capital{
  .capital-title {
    margin-bottom: 32px;
  }
  .capital-item{
    border-top: 1px solid #bbbbcc;
    margin: 30px 0 0;
    padding: 20px 0;

    @include tablet {
      margin: 10px 0 0;
    }
  }
  .info-card {
    padding: 64px 44px!important;
    @include tablet-wide {
      padding: 44px 24px!important;
    }
    @include tablet {
        padding: 24px 16px!important;
    }
  }
  .capital-item-type{
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(14px);
    letter-spacing: 1px;
    text-transform: uppercase;

    small{
      color: $turquoise;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(14px);
      letter-spacing: 1px;
    }
  }
  .capital-item-summary {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75em;
  }

  .capital-item-value{
    color: $turquoise;
    @extend %GothamNarrow;
    font-size: rem(95px);
    line-height: rem(95px);
    letter-spacing: -4px;
    text-transform: uppercase;
    margin: 30px 0 0;

    @include tablet-wide {
      font-size: rem(52px);
      line-height: rem(52px);
      margin: 20px 0 0;
    }
    small{
      font-size: rem(56px);
      line-height: rem(115px);
      letter-spacing: -2px;
      text-transform: none;

      @include tablet-wide {
        font-size: rem(50px);
        line-height: rem(70px);
      }
    }
  }
  .capital-item-details{
    color: rgba($black, .5);
    @extend %Gotham;
    font-size: rem(17px);
    line-height: rem(26px);
    margin: 0;

    @include tablet {
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
  .capital-graph{
    border-top: 1px solid #bbbbcc;
    margin: 30px 0 0;
    overflow: hidden;
    padding: 20px 0;

    @include tablet {
      margin: 10px 0 0;
    }
    .capital-graph-info{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p{
        text-transform: uppercase;
        @extend .normal-link;

        &:last-child{
          text-align: right;
        }

        small{
          color: $turquoise;
          display: block;
          margin-top: 5px;
          @extend .small-link;
        }
      }
    }
  }
}

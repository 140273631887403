.related-project{
  .related-project-item{
    align-items: center;
    margin-top: 30px;

    @include tablet {
      margin-top: 20px;
    }
  }
  .related-project-visual{
    @include mobile {
      margin-bottom: 20px;
    }
    img{
      display: block;
      max-width: 100%;
    }
  }
  .related-project-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px 0;

    @include tablet {
      margin: 10px -5px 0;
    }

    &>li{
      padding: 5px;
    }
    a{
      border: 1px solid $black;
      color: $black;
      display: block;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(24px);
      padding: 0 10px;
      transition: all .3s ease;
      border-radius: 4px;

      &:hover{
        background-color: $black;
        color: $white;
      }
    }
  }
  .related-project-name{
    margin-top: 20px;
    @extend .heading-4;

    @include mobile {
      margin-top: 10px;
    }
  }
  .related-project-description{
    margin-top: 20px;
    @extend .body;

    @include mobile {
      margin-top: 10px;
    }
  }
  .related-project-link{
    color: $turquoise;
    display: inline-block;
    margin: 30px 0 0;
    padding-left: 0px;
    position: relative;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(17px);
    transition: all .3s ease;

    @include mobile {
      overflow: hidden;
      padding-left: 30px;
    }

    &:hover{
      margin-left: 40px;

      @include tablet {
        margin-left: 30px;
      }
      &:before{
        opacity: 1;
      }
    }
    &:before{
      content: "";
      display: block;
      left: -40px;
      opacity: 0;
      transition: opacity .5s ease;
      @include absolute-center('y');
      @include svgicon('../../images/icon_arrow_long_left.svg', 24px, 24px);

      @include mobile {
        left: -5px;
        opacity: 1;
      }
    }
  }
}

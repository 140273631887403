.talent-team{
  .talent-team-list{
    margin-top: 20px;

    a{
      color: $white;
      display: block;
      height: 0;
      overflow: hidden;
      position: relative;
      padding-bottom: 155%;

      @include tablet{
        margin-bottom: 10px;
      }

      &:hover{
        .talent-team-list-desc{
          max-height: 100px;
          opacity: 1;
        }
        img{
          opacity: 1;
          transform: scale(1.1);
        }
      }
      img{
        left: 0;
        width: 100%;
        opacity: .8;
        position: absolute;
        top: 0;
        transition: opacity .3s ease, transform 3s ease-out;
      }
    }
    .talent-team-list-info{
      bottom: 40px;
      left: 40px;
      position: absolute;
      right: 40px;

      @include tablet{
        bottom: 20px;
        left: 20px;
        right: 20px;
      }
    }
    .talent-team-list-name{
      @extend %GothamMedium;
      font-size: rem(32px);
      line-height: rem(40px);
      letter-spacing: -.8px;

      @include tablet{
        font-size: rem(20px);
        line-height: rem(26px);
        letter-spacing: -.2px;
      }
    }
    .talent-team-list-desc{
      @extend %Gotham;
      font-size: rem(21px);
      line-height: rem(32px);
      max-height: 0;
      opacity: 0;
      transition: max-height .3s ease-out, opacity .3s ease;

      @include tablet{
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
  }
}

.search-header{
  padding-top: 184px;
  position: relative;
  z-index: 2;

  @include tablet{
    padding-top: 60px;
  }
}
.search-container{
  background-color: #f1f9f8;
  padding: 24px 0 24px 0;
  text-transform: none;
  @include placeholder(rgba($black, .2));

  @include tablet{
    padding: 12px 0;
  }
  &.focus{
    .search-preview{
      opacity: 1;
      visibility: visible;
    }
  }
}
.search-form{
  align-items: center;
  display: flex;
  @include tablet{
    padding-bottom: 4px;
  }
  .search-submit{
    flex: 0 0 auto;
    @include tablet{
      background: transparent url(../../images/icon_search_dark.svg) 50% 50% no-repeat;
      border: none;
      padding: 5px;
      @include size(40px, 40px);

      &>*{
        display: none;
      }
      &:hover,
      &:active{
        background: transparent url(../../images/icon_search_dark.svg) 50% 50% no-repeat;
        border: none;
      }
    }
    &.button-disabled{
      margin: 0;
      color:white;
      background-color: #005D71;
      opacity: 0.5;
      .button-icon{
        svg{
          g path{
            fill:white;
          }
        }
      }
    }
  }
}
.search-input{
  background-color: transparent;
  border: none;
  flex: 1 1 auto;
  outline: none;
  @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(28px);
  @include size(100%, 48px);

  @include tablet{
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.search-preview{
  background-color: $white;
  box-shadow: 0 5px 8px 0 rgba(#000, .15);
  left: 0;
  max-height: 248px;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: all .3s ease;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 100%;

  @include tablet{
    max-height: calc(100vh - 136px);
    padding-bottom: 25px;
  }

  .search-preview-results{
    position: relative;
    @include tablet {
      margin-top: 16px;
    }
    hr {
      border: 1px solid rgba($black, .3);
      margin: 0;
    }
    &>li{
      position: relative;
      padding: 16px 0;
      &:before{
        background-color: #f1f9f8;
        content: "";
        opacity: 0;
        transition: all .3s ease;
        visibility: hidden;
        @include absolute-center;
        @include size(4000px, 100%);
      }
      &:hover{
        &:before{
          opacity: 1;
          visibility: visible;
        }
      }
      &:last-child{
        a{
          border: none;
        }
      }
    }
    a{
      color: rgba($black, 1);
      display: block;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
      z-index: 1;
      @extend %GothamMedium;
      font-size: rem(21px);
        line-height: rem(28px);
      @include tablet {
        font-size: rem(17px);
        line-height: rem(26px);
      }

      em{
        color: $black;
        font-style: normal;
      }
      span{
        color: $turquoise-black-second;
        right: 0;
        text-transform: uppercase;
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        letter-spacing: -0.28px;
        background: white; padding: 10px 20px;
        @include absolute-center('y');

        @include tablet{
          display: block;
          margin: 0 0 8px;
          position: relative;
          right: auto;
          transform: none;
          top: auto;
        }
      }
    }
  }
}

.jobboard-list{
  margin-top: rem(100px);
  &__head{
    font-size: rem(12px);
    @extend %GothamMedium;
    text-transform: uppercase;
    color:rgba($black, 0.5);
    border-bottom: 1px solid $border;
    padding-bottom: 25px;
  }

  &__block-right{
    background-color: $yellow;
    padding: 50px 40px;
    min-width: 332px;
    margin-top: 36px;
    &.no-top-margin{
      margin-top: 0;
    }
    &__title{
      @extend .heading-4;
      margin-bottom: rem(25px);
    }
    &__text{
      margin-bottom: rem(30px);
      line-height: normal;
    }
    .button{
      width: 100%;
      justify-content: center;
    }
  }

  &__items{
    margin-bottom: rem(60px);
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__intro{
    margin-bottom: rem(70px);
    &__title{
      @extend .heading-4;
      margin-bottom: rem(13px);
    }
    &__subhead{
      font-size: rem(28px);
      letter-spacing: -.8px;
      line-height: rem(40px);

      @include tablet {
        font-size: rem(25px);
        letter-spacing: -.5px;
        line-height: rem(30px);
      }
      @include mobile {
        font-size: rem(20px);
        letter-spacing: -.2px;
        line-height: rem(26px);
      }
    }
  }

  &__content{
    &__title{
      @extend .heading-3;
      margin-bottom: rem(33px);
    }
  }

  .col-right{
    @include tablet-wide{
      display: none;
    }
  }
}
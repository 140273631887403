.faq{

}
.faq-list{
  border-bottom: 1px solid rgba($black, .15);
  margin: 32px 0 40px 0;

  &>li{


    &.active{
      .faq-list-question{
        &:before{
          opacity: 0;
          transform: translateY(-50%) rotate(-90deg);
          background-color: $turquoise;
        }
        &:after{
          transform: translateY(-50%) rotate(0deg);
          background-color: $turquoise;
        }
      }
    }
  }
  .faq-list-question{
    border-top: 1px solid rgba($black, .15);
    display: block;
    @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(27px);
    position: relative;
    transition: color .3s ease;
    color: $turquoise;
    padding: 24px 50px 24px 0;

    @include tablet{
      font-size: rem(18px);
      line-height: rem(22px);
    }

    &:before{
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color .3s ease, transform .3s ease, opacity .5s ease;
      @include size(14px, 2px);
      background-color: $black;
    }
    &:after{
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      transition: background-color .3s ease, transform .3s ease, opacity .5s ease;
      @include size(14px, 2px);
      background-color: $black;
    }
  }
  .faq-list-answer{
    display: none;
    @extend %Gotham;
    font-size: rem(21px);
    line-height: rem(32px);
    padding-bottom: 24px;

    @include tablet{
      font-size: rem(16px);
      line-height: rem(22px);
    }
  }
}

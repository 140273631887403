.slideshow{
  &__images{
    width: 100%;
    position: relative;
    padding-bottom: 53%;
    @include tablet{
      width: auto;
      margin-left: -20px;
      margin-right: -20px;
      padding-bottom: 77%;
    }

    &__item{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-size: cover;
      opacity: 0;
      transition: all 1000ms;
      &.is-active{
        opacity: 1;
      }
    }
  }

  &__legend{
    margin-top: rem(25px);
    position: relative;
    &__bubble{
      background-color: $white;
      border-radius: 2px;
      position: absolute;
      padding: 20px;
      top:-130px;
      transform: translateX(-50%) translateY(15px);
      opacity: 0;
      visibility: hidden;
      transition: all 400ms;
      @include tablet{
        display: none;
      }
      &.is-active, &.is-hovered{
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) translateY(0);
      }
      &:after{
        content:"";
        width: 9px;
        height: 3px;
        background-image: url('../../images/project-anim/triangle.svg');
        background-size: cover;
        position: absolute;
        bottom:-3px;
        left:50%;
        transform: translateX(-50%);
      }
      .state{
        @extend .normal-link;
        text-transform: uppercase;
      }
      .date{
        @extend .body-small;
        color:$turquoise;
      }
      &.first{
        left:15px !important;
        transform: none;
        &:after{
          left: 15px;
          transform: none;
        }
      }
    }
    &__bullet-wrap{
      width: 100%;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
    }
    &__bullet{
      background-color:$black;
      width: 11px;
      height: 11px;
      border-radius: 100%;
      position: absolute;
      left:0;
      top:50%;
      transform: translateY(-50%);
      transition: all 250ms linear;
      z-index:2;
      cursor: pointer;
      &.is-active{
        background-color: $turquoise;
      }
    }
    &__empty{
      width: 100%;
      border-top:1px solid $black;
      opacity: 0.3;
    }
    &__full{
      width: 100%;
      border-top:1px solid $turquoise;
      position: absolute;
      top:0;
      left:0;
      transform-origin: left;
      transform:scaleX(0);
      z-index:1;
    }
  }

  &__bubbles-wrap-mobile{
    margin-top: rem(40px);
    display: none;
    @include tablet{
      display: block;
    }
    &__item{
      border-radius: 2px;
      background-color: $black;
      padding: 20px;
      display: none;
      &.is-active{
        display: block;
      }
      .state{
        color:white;
        margin-bottom: rem(3px);
        @extend .normal-link;
        text-transform: uppercase;
      }
      .date{
        @extend .body-small;
        color:$turquoise;
      }
    }
  }
} 
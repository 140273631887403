.talent-career{
  position: relative;

  .shapes-left{
    left: 0;
    position: absolute;
    top: 50%;

    @include tablet {
      display: none;
    }
  }
  .shapes-right{
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;

    @include tablet {
      display: none;
    }
  }
  .talent-career-list{
    display: flex;
    justify-content: space-between;
    padding: 40px 0 0;
    position: relative;
    z-index: 1;
    gap: 40px;
    @include tablet-wide {
      display: block;
      padding: 20px 0 0;
    }
  }
  .talent-career-list-item{
    display: block;
    flex: 1 1 33.33%;
    @include tablet-wide {
      margin-bottom: 24px;
      aspect-ration: 0.8;
    }
    .talent-career-list-visual {
      border-radius: 1rem;
      overflow: hidden;
      height: 513px;
      position: relative;
      margin-bottom: 24px;
      @include tablet-wide {
        height: 100%;
        margin-bottom: 16px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 162px;
        height: 162px;
        background-size: 100%;
        background-repeat: no-repeat;
        transition-duration: 0.3s;
        @include tablet-wide {
          bottom: 3px;
        }
        @include mobile {
          width: 88px;
            height: 88px;
        }
      }
      &.yellow::after {
        background-image: url(../../images/yellow-quart-circle.svg);
      }
      &.blue::after {
        background-image: url(../../images/blue-quart-circle.svg);
      }
      &.light-blue::after {
        background-image: url(../../images/light-blue-quart-circle.svg);
      }
      &.green::after {
        background-image: url(../../images/green-quart-circle.svg);
      }
    }

    &:hover .talent-career-list-visual::after {
      width: 200px;
      height: 200px;
        @include mobile {
            width: 100px;
            height: 100px;
        }
    }

    span {
      color: $black;
      @extend %GothamBold;
      font-size: rem(32px);
      line-height: rem(32px);
      letter-spacing: -1.28px;
      text-transform: uppercase;
      @include tablet {
        font-size: rem(24px);
        line-height: rem(32px);
        letter-spacing: -0.96px;
      }
    }
  }
}

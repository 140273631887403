.twitter-slider{
  background-color: $turquoise;
  color: $white;
  padding: 0;
  position: relative;

  @include mobile {
    height: auto;
    margin: 0 -20px;
    padding: 80px 0 40px;
  }

  .icon{
    display: block;
    top: 50px;
    z-index: 2;
    @include absolute-center('x');
    @include size(56px, 48px);

    svg{
      max-height: 100%;
      max-width: 100%;
      @include size(100%, 100%);

      &>g{
        fill: none;
        *[fill]{
          fill: $white;
        }
        *[stroke]{
          stroke: $white;
        }
      }
    }
  }
  .twitter-container{
    position: relative;

    &>div{
      height: 100%;
    }

    .twitter-item{
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 660px;
      justify-content: center;
      padding: 50px;
      position: relative;
      text-align: center;

      @include tablet {
        height: 400px;
        padding: 30px;
      }
      @include mobile {
        height: auto;
      }
    }
    .twitter-item-date{
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(14px);
      letter-spacing: 1px;
      margin: 0 0 20px;
      text-transform: uppercase;
    }
    .twitter-item-tweet{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(27px);
      letter-spacing: -.6px;
      margin: 0 auto 20px;
      max-width: 510px;

      @include tablet {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -.2px;
      }
      a{
        border-bottom: 1px solid $white;
        position: relative;
        text-decoration: none;
      }
    }
    .twitter-item-button{
      border: 1px solid $white;
      border-radius: rem(4px);
      margin-top: 20px;
      padding: 5px 10px;
      @extend .small-link;
    }
  }
  .dots{
    bottom: 50px;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    justify-content: center;

    @include tablet {
      bottom: 30px;
    }

    .dot{
      @include text-hide;
      background-color: rgba($white, .15);
      display: block;
      margin: 0 5px;
      padding: 0;
      @include circle(12px);

      @include tablet {
        @include circle(8px);
      }

      &.active{
        background-color: $white;
      }
    }
  }
}

.message-flash{
  background-color: $yellow;
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    &__text{
      margin-left: 17px;
      @extend .heading-5;
    }
  }
}
.bar-vertical{
 
    .bar-vertical-subhead{
        @extend .chapo;
        opacity: 0.6;
    } 
    
    .row-title{
        margin-bottom: rem(30px);
    }
}
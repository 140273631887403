.title-filters{
  margin-bottom: rem(30px);
  &__inner{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid $border;
  }
  &__title{
    @extend .heading-3;
  }
  .publications-filters-container{
    border-top: 0;
  }
  .publications-filter-item{
    &:last-child{
      .publications-filter-dropdown{
        left:auto;
        right: 0;
      }
    }
  }
}
.achievements{

  .achievements-grid{
//    overflow: hidden;
    @include tablet {
      padding-top: 20px;
      margin-top: 10px;
    }
    &>.row{
      align-items: center;
      margin-top: 120px;
      .light-blue::after {
        background-image: url(../../images/light-blue-quart-circle.svg);
      }
      .blue::after {
        background-image: url(../../images/blue-quart-circle.svg);
      }
      .yellow::after {
        background-image: url(../../images/yellow-quart-circle.svg);
      }
      &.portrait {
        //margin-left: -100px;
        //margin-right: 60px;
        @include tablet-wide {
          margin-right: 30px;
        }
        @include tablet {
          margin-left:  -45px;
          margin-right: 0;
        }
        @include mobile {
          margin-left: -25px;
        }
        .achievements-visual {
          position: relative;
          aspect-ratio: 0.8;
          @include tablet {
            margin: 0 0 24px 0;
            max-height: 570px;
          }
          img {
            border-radius: 0px 16px 16px 0px;
            overflow: hidden;
          }
          &::after {
            content: "";
            width: 162px;
            height: 162px;
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -40px;
            right: -40px;
            z-index: 1;
            rotate: -90deg;
            @include tablet {
              width: 88px;
              height: 88px;
              bottom: auto;
              top: -10px;
              right: -10px;
              rotate: 180deg;
            }
          }
        }
        .achievements-info {
          padding-left: 64px;
          @include tablet-wide {
            padding-left: 20px;
          }
        }
        .achievements-tags {
          @include tablet-wide {
            padding-left: 5px;
          }
        }
      }
      &.landscape {
        margin-top: 80px;
        @include tablet-wide {
          margin-top: 64px;
        }
        .achievements-visual {
          position: relative;
          @include tablet {
            margin-bottom: 24px;
          }
          img {
            border-radius: 1rem;
            overflow: hidden;
          }
          &::after {
            content: "";
            width: 162px;
            height: 162px;
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            top: -20px;
            left: -20px;
            z-index: 1;
            rotate: 90deg;
            @include tablet {
              width: 88px;
              height: 88px;
              top: -10px;
              left: -10px;
            }
          }
        }
        .achievements-info .achievements-tags {
          padding-left: 60px;
          @include tablet-wide {
            padding-left:5px;
          }
        }
      }
      @include tablet {
        margin-top: 45px;
      }

      &:first-child{
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:nth-child(even){
        &.portrait {
          margin-left: 60px;
          margin-right: -100px;
          @include tablet-wide {
            margin-left: 30px;
          }
          @include tablet {
            margin-left: 0;
            margin-right:  -45px;
          }
          @include mobile {
            margin-right: -25px;
          }
          .achievements-visual {
            margin-left: auto;
            img {
              border-radius: 16px 0 0 16px;
            }
            @include mobile {
              margin: 0 0 24px 60px;
            }
            &::after {
              right: auto;
              left: -40px;
              rotate: 0deg;
              @include tablet {
                left: -10px;
                rotate: 90deg;
              }
            }
          }
          .achievements-info {
            padding-right: 64px;
            @include tablet-wide {
              padding-right:20px;
              margin-left: -20px;
            }
          }
        }
        &.landscape {
          .achievements-visual {
            &::after {
              left: auto;
              right: -20px;
              rotate: 180deg;
              @include tablet {
                right: -10px;
              }
            }
          }
          .achievements-info {
            padding-right: 60px;
            @include tablet-wide {
              padding-right: 5px;
            }
          }
        }
        &>div:first-child{
          order: 2;

          @include tablet {
            order: 1;
          }
        }
        &>div:last-child{
          order: 1;

          @include tablet {
            order: 2;
          }
        }
      }
    }
  }
  .achievements-visual{
    position: relative;
    img{
      border-radius: rem(4px);
      display: block;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
  .achievements-info{
    margin: 0 auto;
    @include tablet {
      margin: 0;
    }
    p{
      @extend %Gotham;
      font-size: rem(17px);
      line-height: rem(26px);

      @include tablet {
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
    .button {
      margin-top: 40px;
      font-size: rem(14px);
      line-height: rem(14px);
      letter-spacing: -0.28px;
      padding: 12px 16px;
      @include tablet {
        margin-top: 32px;
        width: fit-content;
      }
    }
  }
  .achievements-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 16px;

    @include tablet {
      margin: 0 0 8px;
    }

    &>li{
      padding: 5px;
      border: 1px solid $black;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
  .achievement-title{
    @extend %GothamBold;
    font-size: rem(56px);
    line-height: rem(64px);
    margin: 0 0 24px;
    letter-spacing: -2.24px;
    text-transform: uppercase;

    @include tablet {
      font-size: rem(32px);
      line-height: rem(38px);
      letter-spacing: -1.28px;
      margin: 0 0 16px;
    }
  }
  .landscape .achievement-title {
    font-size: rem(32px);
    line-height: rem(32px);
    letter-spacing: -1.28px;
    @include tablet {
      font-size: rem(24px);
      letter-spacing: -0.96px;
    }
  }
  .achievement-link{
    color: $turquoise;
    display: inline-block;
    margin: 30px 0 0;
    padding-left: 35px;
    position: relative;
    text-transform: uppercase;
    @extend %GothamBold;
    font-size: rem(14px);
    line-height: rem(17px);

    @include tablet{
      margin: 20px 0 0;
    }

    &:before{
      content: "";
      display: block;
      left: 0px;
      transition: opacity .5s ease;
      @include absolute-center('y');
      @include svgicon('../../images/icon_arrow_long_left.svg', 24px, 24px);

    }
  }
}

.achievements-grid {
  margin-top: 40px;
}

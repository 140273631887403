.investir-most-researched .section-title {
  margin-bottom: 30px;
}
.investir-most-researched .button {
  margin-bottom: 20px;
  width:100%;
  text-transform: initial;
  color: $turquoise;
  &:hover  {
    color: $black;
  }
  @include tablet {
    padding: 12px 26px;
  }
}
.investir-most-researched .button span {
  margin: auto;
  font-size: 21px;
  @extend %GothamMedium;

}
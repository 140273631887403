.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.tag-list-button {
  padding: rem(8px) rem(16px);
  cursor: pointer;
  font-size: rem(17px);
    line-height: rem(26px);
  text-transform: none;
  @include tablet {
    padding: rem(8px) rem(16px);
    line-height: rem(14px);
  }
}
.filter .tag-list-button {
  text-align: left;
  sup {
    font-size: 10px;
    vertical-align: super;
    padding-left: 4px;
  }
}
.tag-list-button-is-active {
  background: rgba(0, 132, 161, 0.10);
  border: none;
}

.tag-list-select {
  max-width: 100% !important;
}
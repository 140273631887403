.questions{
  .questions-slider{
    align-items: center;
    display: flex;
    margin-top: 30px;
    position: relative;
    gap: 40px;
    height: 620px;

    @include tablet{
      flex-direction: column;
      height: auto;
      gap: 0;
    }

    .questions-slider-visual{
      border-radius: 1rem;
      overflow: hidden;
      height: 100%;
      top: 0;
      right: 0;
      width: 50%;
      position: relative;

      @include tablet{
        height: 600px;
        right: auto;
        position: relative;
        top: auto;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
      }
      @include mobile {
        height: 335px;
      }

      .questions-slider-visual-img{
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        position: absolute;
        //transform: translateX(-50px);
        top: 0;
        transition: all .8s ease;
        visibility: hidden;

        &.active{
          opacity: 1;
          position: absolute;
          //transform: translateX(0px);
          visibility: visible;
        }
      }
      .questions-slider-author{
        bottom: 40px;
        color: $white;
        left: 40px;
        right: 40px;
        position: absolute;
        @extend %Gotham;

        .name {
          font-size: rem(21px);
            line-height: rem(28px);
          @include tablet {
            font-size: rem(18px);
            line-height: rem(26px);
          }
        }
        .function {
          @extend %GothamMedium;
          text-transform: uppercase;
          font-size: rem(14px);
            line-height: rem(14px);
          letter-spacing: -0.28px;
          @include tablet {
            font-size: rem(14px);
            line-height: rem(16px);
            letter-spacing: -0.28px;
          }
        }

        @include tablet{
          bottom: 25px;
          left: 25px;
          right: 25px;
        }
        @include mobile{
          bottom: 20px;
          left: 20px;
          right: 20px;
        }
      }
    }
    .questions-slider-info{
      border-radius: 1rem;
      background-color: $yellow-faded;
      align-items: center;
      color: $black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      padding: 48px;
      position: relative;
      width: 50%;
      height: 100%;

      @include tablet{
        padding: 24px 16px;
        min-height: 0px;
        width: 100%;
        order: 2;
        border-radius: 0 0 1rem 1rem;
      }
    }
    .questions-slider-content{
      @extend %Gotham;
      font-size: rem(21px);
      line-height: rem(32px);
      position:relative;
      transition: all .8s ease;
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      @include tablet{
        font-size: rem(14px);
        line-height: rem(18px);
        gap: 40px;
      }
      @include mobile {
        gap: 0;
      }
    }
    .questions-slider-number{
      display: block;
      @extend %GothamMedium;
      font-size: rem(14px);
      line-height: rem(14px);
      margin: 0 0 16px;
    }
    .questions-slider-title{
      @extend %GothamBold;
      font-size: rem(40px);
      line-height: rem(42px);
      margin: 0 0 20px;
      text-transform: uppercase;
      letter-spacing: -1.6px;

      @include tablet{
        font-size: rem(30px);
        line-height: rem(36px);
      }
      @include mobile{
        font-size: rem(24px);
        line-height: rem(28px);
      }
    }
    .questions-slider-show-answer{
      @extend %GothamMedium;
      font-size: rem(14px);
      line-height: rem(17px);
      position: relative;
      text-transform: uppercase;
      padding: 8px 16px 8px 16px;
      border: 1px solid $black;
      border-radius: 100px;
      &:hover {
        opacity: 0.5;
      }
    }
    .questions-slider-slides{
      height: 90%;
      &>div{
        display: none;
        height: 100%;
        &:first-child{
          display: block;
        }
      }
    }
    .questions-slider-nav{
      display: flex;
      margin: auto 0 0;
      justify-content: flex-start;

      @include mobile {
        bottom: 30px;
        margin-top: 32px;
      }

      .prev{
        border: 1px solid $black;
        display: block;
        margin-right: 10px;
        position: relative;
        @include circle(48px);
        &:hover{
          opacity: 0.5;
        }
        &:before{
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%) rotate(180deg);
          @include svgicon('../../images/icon-arrow-black.svg', 24px, 24px);

          @include tablet{
            @include svgicon('../../images/icon-arrow-black.svg', 20px, 20px);
          }
        }
      }
      .next{
        border: 1px solid $black;
        display: block;
        margin-right: 10px;
        position: relative;
        @include circle(48px);
        &:hover{
          opacity: 0.5;
        }
        &:before{
          content: "";
          display: block;
          @include absolute-center();
          @include svgicon('../../images/icon-arrow-black.svg', 24px, 24px);

          @include tablet{
            @include svgicon('../../images/icon-arrow-black.svg', 20px, 20px);
          }
        }
      }
      .prev.disabled,.next.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
    .questions-slider-yellow-quart {
      width: 172px;
      height: 172px;
        position: absolute;
      bottom: -20px;
        right: -20px;
        z-index: 3;
        background-image: url(../../images/yellow-quart-circle.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        transform: rotate(-90deg);

      @include tablet-wide {
        width: 88px;
        height: 88px;
        right: -10px;
        bottom: -10px;
      }
    }
  }
  .questions-slider-answers{
    border-radius: 16px;
    overflow: hidden;
    margin-left: 21px;
    bottom: 0;
    display: none;
    left: 50%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;

    @include mobile {
      left: 0;
      margin-left: 0;
    }

    .questions-slider-answer{
      background-color: $yellow-faded;
      color: $black;
      display: none;
      height: 100%;
      overflow: auto;
      padding: 77px 8px 48px 48px;
      @extend %Gotham;
      font-size: rem(21px);
      line-height: rem(32px);

      @media (max-width: 1200px){
        padding: 60px 40px;
      }
      @include tablet{
        font-size: rem(16px);
        line-height: rem(22px);
        padding: 40px;
      }
      @include mobile {
        font-size: rem(14px);
        line-height: rem(18px);
        padding: 24px 8px 24px 16px;
      }
    }
    .questions-slider-answer-title{
      @extend %Gotham;
      font-size: rem(14px);
      line-height: rem(17px);
      margin: 0 0 10px;
      text-transform: uppercase;
    }
    .questions-slider-answer-text {
      height: 360px;
      overflow: auto;
      margin-top: 16px;
      z-index: 5;
      mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
      padding-bottom: 50px;
      padding-right: 40px;
      font-size: rem(17px);
        line-height: rem(26px);
      @-moz-document url-prefix() {
        scrollbar-color: $yellow transparent;
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar {
        width: 4px;  /* Largeur de la scrollbar verticale */
        height: 12px; /* Hauteur de la scrollbar horizontale */
      }

      /* Style du thumb (la partie déplaçable) */
      &::-webkit-scrollbar-thumb {
        background-color: $yellow; /* Couleur normale du thumb */
        border-radius: 16px;     /* Coins arrondis */
      }

      /* Désactiver les changements sur hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: $yellow; /* Couleur normale du thumb */
        width: 4px; /* Conserve la même largeur */
        height: 12px; /* Conserve la même hauteur */
      }

      /* Désactiver les changements sur active (clic) */
      &::-webkit-scrollbar-thumb:active {
        background-color: $yellow; /* Couleur normale du thumb */
        width: 4px; /* Conserve la même largeur */
        height: 12px; /* Conserve la même hauteur */
      }
      @include tablet {
        padding-right: 8px;
        margin-top: 32px;
        height: 90%;
        font-size: rem(14px);
        line-height: rem(22px);
      }
    }
    .questions-slider-answer-close{
      border: 1px solid $white;
      display: block;
      position: absolute;
      right: 30px;
      top: 30px;
      filter: invert(1);
      @include circle(48px);

      @include tablet {
        right: 20px;
        top: 20px;
        @include circle(30px);
      }
      &:hover{
        opacity: 0.5;
      }
      &:after{
        content: "";
        display: block;
        @include absolute-center;
        @include svgicon('../../images/icon_close_white.svg', 20px, 20px);

        @include tablet {
          @include svgicon('../../images/icon_close_white.svg', 14px, 14px);
        }
      }
    }
  }
}

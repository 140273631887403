
.short-section {
  margin: 80px 0 0 0;
  padding-bottom: 0;
  @include tablet {
    margin: 40px 0 0 0;
    padding-top: 40px;
  }
}
.key-figure .title-cell {
  margin-bottom: 32px;
}
.key-figure .section-title {
  margin: 0!important;
}
.key-figure .subtitle {
  padding-top: 24px;
  font-size: rem(17px);
  line-height: rem(26px);
  @include tablet {
    padding-top: 16px;
  }
}
.key-figure .graph__toggles__item__btns {
  margin-bottom: 40px;
    @include tablet {
        margin-bottom: 32px;
      margin-top: 24px;
    }
}
.key-figure .cells-container {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  width: 100%;
  @include tablet {
    gap: 16px 0;
  }
}
.key-figure .row-container-infos {
  gap: 40px 0;
  @include mobile {
    gap: 24px 0;
  }
}
.key-figure .info-card {
  box-shadow: 0px 0px 32px 0px rgba(41, 34, 101, 0.08);
  background-color: white;
    border-radius: 16px;
  padding: 38px 28px;
  width: 100%;
  height: 100%;
  text-align: center;
  @include tablet-wide {
    padding: 32px 24px;
  }
    @include tablet {
      padding: 24px 16px;
    }
}
.key-figure .info-card .title {
  color: $black;
  font-size: rem(12px);
  @extend %GothamBold;
  line-height: rem(14px);
  letter-spacing: -0.28px;
  text-transform: uppercase;
  padding-bottom: 4px;
  margin: 0;
  @include tablet {
    padding-bottom: 16px;
  }
}
.key-figure .info-card .value {
  color: $black;
  @extend %GothamBold;
  font-size: rem(40px);
  line-height: rem(42px);
  letter-spacing: -1.6px;
  b {
    font-size: rem(100px);
    line-height: rem(115px);
    letter-spacing: -4px;
    @include tablet-wide {
      font-size: rem(60px);
      line-height: normal;
      letter-spacing: -3.2px;
    }
    @include tablet {
      font-size: rem(80px);
    }
  }
  @include tablet-wide {
    font-size: rem(32px);
    line-height: rem(36px);
    letter-spacing: -1.28px;
  }
  @include tablet {
    font-size: rem(24px);
    line-height: rem(32px);
    letter-spacing: -0.96px;
  }
}
.key-figure .info-card .info {
  p {
    color: rgba(0, 0, 0, 0.60);
    font-size: rem(14px);
    @extend %GothamBook;
    line-height: rem(20px);
    @include tablet {
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }
}
.turquoise {
  padding: 80px 0;
  background-color: rgba(117, 194, 189, 0.1);
    @include tablet {
        padding: 40px 0;
    }
  &.key-figure .info-card {
    box-shadow: none;
  }
}

.key-figure-graph {
  &-row {
    margin-top: 40px;
    gap: 16px 0;
    @include tablet {
      margin-top: 32px;
    }
  }
  .info-card {
    padding: 64px 44px;
    position: relative;
    overflow: hidden;
    @include tablet {
      padding: 24px 16px;
    }
    &::after {
      content: "";
      display: block;
      width: 120px;
        height: 120px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../images/blue-quart-circle.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      rotate: 90deg;
      z-index: 0;
      @include tablet {
        width: 115px;
        height: 115px;
      }
    }
    .value, .title {
      position: relative;
      z-index: 1;
    }
  }
  .capital-graph{
    overflow: hidden;
    padding: 20px 0;

    @include tablet {
      margin: 0;
      padding-top: 0;
    }
    .capital-graph-info{
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      p{
        @extend %GothamMedium;
        font-size: rem(21px);
        line-height: rem(28px);

        &:last-child{
          text-align: right;
        }

        small{
          text-transform: uppercase;
          color: $turquoise-black-second;
          display: block;
          margin-top: 8px;
          font-size: rem(14px);
          line-height: rem(14px);
          letter-spacing: -0.28px;
        }
      }
    }
  }
}

.key-figure-graph-row .g-cell-m-2:nth-child(2) {
  .info-card::after {
    background-image: url('../../images/yellow-quart-circle.svg');
    rotate: 0deg;
    bottom: 0;
    top: unset;
  }
}

.visual-citation {
}
.visual-citation-cell-right {
  height: 100%;
  @include tablet {
    margin-bottom: 32px;
  }
}
.visual-citation-cell-left {
  background-color: rgba(255, 198, 0, 0.10);
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 0;
  margin: 0 20px;
  @include tablet {
    margin: 0 15px;
    width: calc(100% - 30px);
    border-radius: 0 0 16px 16px;
    order: 2;
    padding: 42px 16px 32px 16px;
  }
  @include mobile {
    margin: 0 5px;
    width: calc(100% - 10px);
    padding: 42px 16px 24px 16px;
  }
  .visual-citation-blockquote {
    position: relative;
    @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(28px);
    margin-bottom: 32px;
    @include tablet {
      font-size: rem(17px);
      line-height: rem(26px);
    }
    &-left {
      font-size: rem(100px);
        line-height: rem(20px);
      @extend %GothamBold;
      letter-spacing: -4px;
      text-transform: uppercase;
      color: #FFC600;
      position: absolute;
      top: 10px;
      left: 0;
      @include tablet {
        letter-spacing: -3.2px;
        font-size: rem(80px);
        top: 0;
      }
    }
  }
  .visual-citation-right-frame {
    padding: 0 48px;
    position: relative;
    @include tablet {
      padding: 0;
    }
  }
  .visual-citation-name {
    font-size: rem(21px);
    @extend %GothamMedium;
    line-height: rem(28px);
    margin-bottom: 4px;
    @include tablet {
      font-size: rem(17px);
      line-height: rem(16px);
    }
  }
  .visual-citation-function {
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(20px);
  }
}

.visual-citation-row {
  display: grid;
    grid-template-columns: 1fr 1fr;
  padding: 0;
  min-height: 640px;
    @include tablet {
        grid-template-columns: 1fr;
    }
}
.visual-citation-picture-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.visual-citation-picture {
  width: 100%;
  height: 100%;
    object-fit: cover;
  border-radius: 16px;
  overflow: hidden;
  @include tablet {
    border-radius: 16px 16px 0 0;
    min-height: 500px;
  }
  @include mobile {
    min-height: 335px;
  }
}
.visual-citation-picture-form {
  background-image: url('../../images/yellow-quart-circle.svg');
    background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 172px;
  height: 172px;
  transform: rotate(-90deg);
  @include tablet {
    &.desktop {
      display: none;
    }
  }
  &.mobile {
    display: none;
    width: 88px;
    height: 88px;
    bottom: -45px;
    right: -30px;
    @include tablet {
      display: block;
    }
    @include mobile {
      bottom: -30px;
      right: -25px;
    }
  }
}
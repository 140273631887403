.quote-people{
  overflow: hidden;
  @include mobile{
  }
  blockquote{
    margin-bottom: rem(30px);
  }

  &__left{
    position: relative;
    min-height: 770px;
    @include tablet-wide{
      min-height: 750px;
    }
    @include tablet{
      min-height: 600px;
    }
    @include mobile{
      min-height: 0;
    }
    &__wrap{
      position: absolute;
      left:0;
      top:50%;
      transform: translateY(-50%);
      z-index: 2;
      color:white;
      @include mobile{
        position: relative;
        top:0;
        transform:none;
        padding-top:68px;
        padding-bottom: 70px;
      }
    }
    blockquote{
    }
    &:before{
      content:"";
      position: absolute;
      top:0;
      right: -111px;
      width: 851px;
      height: 771px;
      background-image: url('../../images/project-anim/organic_form_a_alt.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 1;
      mix-blend-mode: multiply;
      @media (max-width: 1350px) {

      }
      @include tablet-wide{
        top: 0;
        left: -52px;
        width: calc(100vw + 50px);
        height: 100%;
        background-image: url('../../images/project-anim/organic_form_a_alt_no_viewbox.svg');
        background-size: 100% 100%;
      }
      @include mobile{ 
        //background-image: url('../../images/project-anim/organic_form_a_alt_mobile.svg');
        width:100vw;
        left:-20px;
        right:-20px;
        height: 100%;
        top:0;
        background-size: auto 100%;
      }
    }

  }
  
  &__right{
    position: relative;
    min-height: 863px;
    @include tablet-wide{
      min-height: 753px;
    }
    @include mobile{
      min-height: 0;
    }
    &__wrap{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 510px;
      z-index: 2;
      @include tablet-wide{
        max-width: 100%;
        top: 430px;
      }
      @include mobile{
        position: relative;
        top:0;
        transform:none;
        padding-top:68px;
        padding-bottom: 70px;
      }
    }
    &:before{
      content:"";
      position: absolute;
      top:0;
      left:14px;
      width:787px;
      height: 863px;
      background-image: url('../../images/project-anim/organic_form_b_alt.svg');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
      mix-blend-mode: multiply;
      @media (max-width: 1350px) {

      }
      @include tablet-wide{
        left: unset;
        right: -40px;
        position: absolute;
        top: 0;
        width: calc(100vw + 50px);
        height: 753px;
        background-size: 100% 100%;
        background-image: url('../../images/project-anim/organic_form_b_alt_no_viewbox.svg');
      }
      @include tablet{
        left:-81px;
      }
      @include mobile{
        background-image: url('../../images/project-anim/organic_form_b_alt_mobile.svg');
        width:100vw;
        left:-20px;
        right:-20px;
        height: 100%;
        background-size: 100% 100%;
      }
    }
  }

  &__author{
    display: flex;
    align-items: center;
    img{
      border-radius: 100%;
      margin-right: 30px;
    }
    &__name{
      @extend .heading-5;
      margin-bottom: rem(5px);
    }
    &__status{
      opacity: 0.5;
      @extend .small-link;
      text-transform: uppercase;
    }
  }

  .cell-left{
    @include tablet-wide{
      width: 100%;
    }
  }

  .cell-right{
    @include tablet-wide{
      width: 100%;
      margin-top: -150px;
    }
    @include mobile{
      position: relative;
      top: -60px;
      margin-top: 0;
    }
  }

}
.push-jobboard{
  background-color: $white;
  background-image: url("../../images/jobboard/push_desktop.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: $black;
  height: 730px;
  position: relative;

  @include tablet {
    height: 380px;
  }
  @include mobile{
    background-size: cover;
    background-image: url("../../images/jobboard/push_mobile.svg");
  }
  &__content{
    max-width: 840px;
    text-align: center;
    width: 100%;
    @include absolute-center;

    @include tablet {
      padding: 0 15px;
    }
  }
  &__title{
    @extend %GothamMedium;
    font-size: rem(82px);
    line-height: rem(86px);
    letter-spacing: -2.5px;
    margin: 0 0 30px;

    @include tablet {
      font-size: rem(32px);
      line-height: rem(38px);
      letter-spacing: -.5px;
    }
  }
  .button{
    margin: 0 10px;
    min-width: 200px;
    justify-content: center;

    @include tablet {
      margin: 0;
    }
    @include mobile {
      margin: 0 0 10px;
    }
  }
}

.homepage-intro{
  p{
    @extend %Gotham;
    font-size: rem(28px);
    line-height: rem(38px);

    @include tablet {
      font-size: rem(18px);
      line-height: rem(26px);
    }
  }
  .homepage-intro-actions{
    margin: 40px 0 0;

    @include tablet {
      margin: 20px 0 0;
    }
  }
}

.show-search{
  //overflow: hidden;

  .header,
  .header.transparent-header {
    background-color: $white;

    .header-btn{
      color: $black;
    }
    .main-logo{
      img{
        display: block;
      }
      .main-logo-reverse{
        display: none;
      }
    }
    .header-tools{
      hr{
        background-color: rgba($black, .3);

        @include tablet {
          background-color: rgba($black, .3);
        }
      }
    }
    .header-projects-btn{
      .icon{
        @include svgicon('../../images/icon_projects_dark.svg', 20px, 20px);
        background-size: contain;
        height: 12px;

        @include tablet {
          @include svgicon('../../images/icon_projects_dark.svg', 20px, 20px);
        }
      }
    }
    .header-search-btn{
      .icon{
        @include svgicon('../../images/icon_search_dark.svg', 20px, 20px);

        @include tablet {
          @include svgicon('../../images/icon_search_dark.svg', 20px, 20px);
        }
      }
    }
    .header-menu-btn{
      .icon{
        @include svgicon('../../images/icon_menu_dark.svg', 20px, 20px);
        height: 8px;
        background-size: contain;

        @include tablet {
          @include svgicon('../../images/icon_menu_dark.svg', 20px, 20px);
        }
      }
    }
    .header-search-btn{

      .text{
        .open{
          display: block;
        }
        .closed{
          display: none;
        }
      }
      .icon{
        @include svgicon('../../images/icon_close.svg', 20px, 20px);

        @include tablet {
          @include svgicon('../../images/icon_close.svg', 20px, 20px);
        }
      }
    }
    .header-search{
      opacity: 1;
      visibility: visible;
    }
  }
}
.show-nav{
  overflow: hidden;

  .header,
  .header.transparent-header{
    .header-search-btn,
    hr{
      @include tablet {
        display: none;
      }
    }
    .header-menu-btn{
      color: $turquoise-black-second;

      .text{
        @include tablet {
          //display: block;
        }
        .open{
          display: block;
        }
        .closed{
          display: none;
        }
      }
      .icon{
        @include svgicon('../../images/icon_close.svg', 14px, 14px);
      }
    }
  }
  .main-nav,
  .main-nav-visuals{
    opacity: 1;
    visibility: visible;
  }
}
.page-projects{
  .header-projects-btn{
    //color: $turquoise !important;

    .icon{
      background-image: url(../../images/icon_projects_turquoise.svg) !important;
    }
  }
}
.header {
  background-color: $white;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color .3s ease;
  width: 100%;
  z-index: 10;

  @include tablet {
    background-color: $white;
    box-shadow: 0 1px 0px rgba($border, .5);
    padding: 0;
    position: fixed;
  }

  .header-tools-lang{
    select{
      appearance: none;
      color: $black;
      letter-spacing: 1px;
      @extend %GothamMedium;
      font-size: rem(14px);
      line-height: rem(18px);
      text-transform: uppercase;
      border:0;
      cursor: pointer;
      padding-right: 20px;
      background: transparent url("../../images/icon_select_arrow.svg") 95% 50% no-repeat;
      padding-left:10px;
        @include tablet{
          font-size: 12px;
        }
      option{
        color:$black;
      }
      &:focus{
        outline-offset: 1px;
        outline: blue 1px solid;
      }
    }
    @include tablet{
      //display: none;
    }
  }

  .header-wrapper{
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 104px;
    width: $width;
    padding: 0 $outter;
    position: relative;
    max-width: 100%;
    margin: 0 auto;

    @include tablet {
      height: 60px;
      padding: 0 $outterMobile;
    }
  }
  .main-logo{
    height: 104px;
    width: 151px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include tablet {
      height: 32px;
      width: 95px;
    }
    img{
      display: block;
      height: 100%;
      width: 100%;
    }
    .main-logo-reverse{
      display: none;
    }
  }
  .header-btn{
    color: $black;
    letter-spacing: 1px;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(18px);
    text-transform: uppercase;
    transition: color .3s ease;
    @include tablet{
      margin-left: 10px;
    }
    &:hover{
      .header-projects-btn-text{
        &:after{
          width: 100%;
        }
      }
    }
    @include tablet {
      color: $black;
      font-size: rem(12px);
      line-height: rem(14px);
    }
    .header-projects-btn-text{
      position: relative;
      &:after{
        content:"";
        position: absolute;
        background-color: white;
        height: 1px;
        width: 0%;
        bottom: 0;
        left:0;
        transition: all 250ms;
      }
    }
  }
  .header-tools{
    align-items: center;
    display: flex;

    hr{
      background-color: rgba($black, .3);
      border: none;
      margin: 0 30px;
      @include size(1px, 30px);
      @include tablet-wide{
        margin:0 10px;
      }
      @include tablet {
        margin: 0 15px;
      }
    }
  }
  .header-projects-btn{
    align-items: center;
    display: flex;

    .icon{
      margin-right: 10px;
      @include svgicon('../../images/icon_projects_dark.svg', 20px, 20px);
      background-size: contain;
      height: 12px;

      @include tablet {
        @include svgicon('../../images/icon_projects_dark.svg', 20px, 20px);
      }
    }
  }
  .header-search-btn{
    align-items: center;
    display: flex;
    @include tablet{
      display: none;
    }
    &:hover{
      .text{
        &:after{
          width: 100%;
        }
      }
    }
    .text{
      position: relative;
      &:after{
        content:"";
        position: absolute;
        background-color: white;
        height: 1px;
        width: 0%;
        bottom: 0;
        left:0;
        transition: all 250ms;
      }
      @include tablet {
        margin-right: 10px;
      }

      .open{
        display: none;
      }
      .closed{
        display: block;
      }
    }
    .icon{
      margin-left:10px;
      @include svgicon('../../images/icon_search_dark.svg', 20px, 20px);

      @include tablet {
        margin-left: 0;
        @include svgicon('../../images/icon_search_dark.svg', 20px, 20px);
      }
    }
  }
  .header-menu-btn{
    align-items: center;
    display: flex;
    &:hover{
      .text{
        &:after{
          width: 100%;
        }
      }
    }
    .text{
      position: relative;
      &:after{
        content:"";
        position: absolute;
        background-color: white;
        height: 1px;
        width: 0%;
        bottom: 0;
        left:0;
        transition: all 250ms;
      }
      @include tablet {
        margin-right: 10px;
      }

      .open{
        display: none;
      }
      .closed{
        display: block;
      }
      @include tablet {
        display: none;
      }
    }
    .icon{
      margin-left: 10px;
      @include svgicon('../../images/icon_menu_dark.svg', 24px, 24px);
      height: 8px;
      background-size: contain;


      @include tablet {
        margin-left: 0;
        @include svgicon('../../images/icon_menu_dark.svg', 24px, 24px);
      }
    }
  }
  .header-search{
    box-shadow: 0 5px 8px 0 rgba(#000, .15);
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 100%;
    transition: all .3s ease;
    visibility: hidden;
    z-index: 3;
  }
}
.main-nav{
  background-color: $white;
  height: 100vh;
  min-width: 600px;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: all .4s ease;
  visibility: hidden;
  width: 64%;
  z-index: 101;

  @include tablet {
    border-top: 1px solid rgba($black, .15);
    bottom: 0;
    height: auto;
    left: 0;
    min-width: 0;
    right: auto;
    top: 60px;
    width: 100%;
  }

  .main-nav-top{
    height: 104px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 0 80px 32px 108px;

    @include tablet {
      display: none;
    }
    .main-nav-top-logo{
      position: fixed;
      height: 104px;
      width: 151px;
      left: 50%;
      top: 52px;
      transform: translate(-50%, -50%);
    }
    .header-menu-btn{
      align-items: center;
      display: flex;
      letter-spacing: -0.28px;
      @extend %GothamMedium;
      font-size: rem(14px);
      line-height: rem(16px);
      text-transform: uppercase;
      &:hover {
        opacity: 0.5;
      }
      @include tablet {
        color: $black;
        font-size: rem(12px);
        line-height: rem(14px);
      }

      .text{
        .open{
          display: block;
        }
        .closed{
          display: none;
        }
      }
      .icon{
        display: inline-block;
        margin-left: 10px;
        @include svgicon('../../images/icon_close.svg', 14px, 14px);

      }
    }
  }
  .main-nav-list-ctn{
    margin: 0 80px 0 110px;
    //margin-top: 190px;
    position: relative;

    @include tablet {
      margin: 0;
    }

    .main-nav-list{
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include tablet {
        width: 100%;
        padding: 32px 24px;
      }

      &>li{
        &.active{
          &>a{
            color: $turquoise-black-second;

            @include tablet {
              &:after{
                transform: translateY(-50%) rotate(-90deg);
              }
            }

            &:hover{
              color: $turquoise-black-second;
            }
          }
          ul{
            opacity: 1;
            visibility: visible;
          }
        }
        &>a{
          display: block;
          position: relative;
          transition: color .3s ease;
          @extend %GothamMedium;
          font-size: rem(21px);
          line-height: rem(28px);

          @include tablet {
            font-size: rem(17px);
            line-height: rem(26px);

            &:after{
              content: "";
              opacity: .5;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%) rotate(90deg);
              transition: transform .3s ease;
              @include svgicon('../../images/dropdown_arrow.svg', 14px, 14px);
            }
          }

          &:hover{
            color: $turquoise-black-second;

            @include tablet {
              color: $black;
            }
          }
        }
      }
      ul{
        left: 42%;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .4s ease, visibility .4s ease;
        display: flex;
        flex-direction: column;
        visibility: hidden;
        gap: 24px;

        @include tablet {
          display: none;
          left: auto;
          opacity: 1;
          right: auto;
          position: relative;
          top: auto;
          visibility: visible;
        }

        li{
          a{
            @extend %GothamMedium;
            color: $turquoise-black;
            font-size: rem(14px);
            line-height: rem(14px);
            letter-spacing: -0.28px;
            display: block;
            text-transform: uppercase;
            &:hover {
              color: $turquoise-black-second;
            }
            @include tablet {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  .main-nav-search{
    border-bottom: 1px solid rgba($black, .15);
    padding:24px 15px;
    display: none;
    @include tablet{
      display: block;
      border-top: 1px solid rgba($black, .15);
      margin: 0 24px;
      padding: 32px 0;
    }
    .main-nav-search-input{
      background-color: rgba(117, 194, 189, 0.10);
      border-radius: 100px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .main-nav-search-input-icon{
        @include svgicon('../../images/icon_search_dark.svg', 24px, 24px);
        background-size: 22px 22px;
        order: 2
      }
      input{
        width: 100%;
        border:0;
        background-color: transparent;
        padding: 14px 0;
        flex-grow: 1;
        margin-left: 12px;
        text-transform: uppercase;
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        letter-spacing: -0.28px;
        @include placeholder(rgba($black, .5));
        order: 1;
        @include tablet {
          padding: 0;
        }
      }
    }
  }
  .main-nav-footer{
    border-top: 1px solid rgba($black, .20);
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 40px 0;

    @include tablet {
      border-top: none;
      display: block;
      margin-top: 0px;
      padding: 10px 15px;
      padding-top:14px;
    }
    .button{
      font-size: 0.77778rem;
      line-height: 0.77778rem;
      letter-spacing: -0.28px;
      padding: 12px 16px;
      margin: 0;
      &.button-transparent {
        margin-right: 16px;
        @include tablet {
            margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
    .main-nav-actions{
      order: 1;
    }
    .main-nav-lang{
      order: 2;
      padding: 10px 0;
      display:none;
      @include tablet{
        //display:block;
      }
      select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $white url('../../images/icon_select_arrow.svg') 95% 50% no-repeat;
        border: none;
        border-radius: 0;
        color: $black;
        display: block;
        height: 40px;
        min-width: 80px;
        padding: 0px 20px 0 10px;
        position: relative;
        text-align: center;
        @extend %GothamMedium;
        font-size: rem(14px);
        letter-spacing: .8px;
        line-height: rem(42px);

        @include tablet {
          padding-left: 0;
        }
      }
    }
  }
}
.main-nav-visuals{
  background: #99BFCD url('../../images/nav-default.jpg') 50% 50%/cover no-repeat;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  transition: all .4s ease;
  visibility: hidden;
  width: 36%;
  z-index: 100;

  @include tablet {
    display: none;
  }
  ul{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &>li{
      background-color: transparent;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all .4s ease;
      visibility: hidden;

      &.active{
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }
  }
}

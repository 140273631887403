.bar-horizontal{
 
    .bar-horizontal-subhead{ 
        @extend .chapo;
        opacity: 0.6;
    } 
    
    .row-title{
        margin-bottom: rem(30px);
    }
}
.section-quote{
  &.small{
    border-left: 1px solid rgba($black, .3);
    padding-left: 40px;

    @include tablet {
      padding-left: 20px;
    }
  }
  &.portrait-quote{
    .quote-portrait{
      img{
        mix-blend-mode: normal;
        position: relative;
        z-index: 2;
      }
    }
    .quote-info{
      blockquote{
        p{
          @extend .heading-4;
        }
      }
    }
  }
  .grid{
    .row{
      align-items: center;
    }
  }
  .quote-portrait{
    margin-bottom: 100px;
    position: relative;

    @include tablet {
      margin-bottom: 90px;
    }

    &:before{
      bottom: -130px;
      content: "";
      left: -100px;
      mix-blend-mode: multiply;
      position: absolute;
      transform: rotate(189deg);
      @include svgicon('../../images/organic-form-c.svg', 680px, 450px);

      @include tablet {
        bottom: -50px;
        left: -20px;
        @include svgicon('../../images/organic-form-c.svg', 346px, 230px);
      }
    }
    &:after{
      bottom: -70px;
      content: "";
      left: -50%;
      mix-blend-mode: multiply;
      position: absolute;
      transform: rotate(1deg);
      @include svgicon('../../images/organic-form-a.svg', 500px, 330px);

      @include tablet {
        bottom: -40px;
        left: -100px;
        @include svgicon('../../images/organic-form-a.svg', 255px, 169px);
      }
    }
    img{
      display: block;
      margin: 0;
      max-width: 100%;
      //mix-blend-mode: multiply;
      position: relative;
      z-index: 1;
    }
    .quote-portrait-infos{
      bottom: 30px;
      color: $white;
      left: 30px;
      position: absolute;
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(32px);
      z-index: 1;

      @include tablet {
        font-size: rem(16px);
        line-height: rem(22px);
      }

      small{
        display: block;
        font-size: rem(12px);
        line-height: rem(14px);
        text-transform: uppercase;
      }
    }
  }
  .quote-info{
    overflow: hidden;
    blockquote{
      p{
        @extend %GothamMedium;
        font-size: rem(32px);
        line-height: rem(44px);

        @include tablet {
          font-size: rem(20px);
          line-height: rem(26px);
        }
      }
    }
  }
  .quote-info-author{
    @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(27px);
    letter-spacing: -.6px;
    margin: 30px 0 0;

    @include tablet {
      font-size: rem(18px);
      line-height: rem(22px);
      margin: 20px 0 0;
    }

    small{
      color: $turquoise;
      display: block;
      margin-top: 5px;
      text-transform: uppercase;
      @extend .small-link;
    }
  }
  .quote-link{
    color: $turquoise;
    display: inline-block;
    margin: 30px 0 0;
    position: relative;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(17px);
    transition: all .3s ease;
    padding-left: 50px;
    transform: translateX(-50px);

    &:hover{
      transform: translateX(0px);
      @include tablet {

      }
      &:before{
      }
    }
    &:before{
      content: "";
      display: block;
      left: 0px;
      @include absolute-center('y');
      @include svgicon('../../images/icon_arrow_long_left.svg', 24px, 24px);
      background-size: contain;
      background-position: center;

    }
  }
}

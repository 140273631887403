.cta-arrow {
  color: #32BBC5;
  font-size: 0.77778rem;
  text-transform: uppercase;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.cta-arrow svg {
  margin-right: 10px;
  @include tablet {
    margin-right: 8px;
  }
}
// Import SCSS files from node_modules
@import '~lightgallery/scss/lightgallery';
@import '~js-datepicker/dist/datepicker.min';
@import '~intl-tel-input/build/css/intlTelInput';

.iti__flag {background-image: url("images/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("images/flags/flags@2x.png");}
}
@import "scss/core/reset";
@import "scss/core/fonts";
@import "scss/core/variables";
@import "scss/core/media-queries";
@import "scss/core/extends";
@import "scss/core/functions";
@import "scss/core/helpers";
@import "scss/core/transitions";
@import "scss/core/typography";
@import "scss/core/rte";
@import "scss/core/cta";

@import "scss/layout/base";
@import "scss/layout/grid";
@import "scss/layout/section";
@import "scss/layout/header";
@import "scss/layout/footer";

@import "scss/ui/button";
@import "scss/ui/forms";
@import "scss/ui/image";
@import "scss/ui/video";
@import "scss/ui/display";
@import "scss/ui/m";
@import "scss/ui/p";

@import "scss/blocks/homepage-header";
@import "scss/blocks/homepage-intro";
@import "scss/blocks/homepage-push";

@import "scss/blocks/page-header";
@import "scss/blocks/search";

@import "scss/blocks/cookies";
@import "scss/blocks/quote";
@import "scss/blocks/key-numbers";
@import "scss/blocks/key-numbers";
@import "scss/blocks/expertise";
@import "scss/blocks/publications";
@import "scss/blocks/proof";
@import "scss/blocks/questions";
@import "scss/blocks/innovation";
@import "scss/blocks/achievements";
@import "scss/blocks/contact";
@import "scss/blocks/news";
@import "scss/blocks/pressreleases";
@import "scss/blocks/history";
@import "scss/blocks/team";
@import "scss/blocks/faq";
@import "scss/blocks/infos";
@import "scss/blocks/capital";
@import "scss/blocks/dividends";
@import "scss/blocks/results";
@import "scss/blocks/graph";
@import "scss/blocks/talent-career";
@import "scss/blocks/talent-reasons";
@import "scss/blocks/talent-team";
@import "scss/blocks/linkedin";
@import "scss/blocks/push-project";
@import "scss/blocks/more";
@import "scss/blocks/pagination";
@import "scss/blocks/news-list";
@import "scss/blocks/news-single";
@import "scss/blocks/presskit";
@import "scss/blocks/photo-gallery";
@import "scss/blocks/section-bubbles";
@import "scss/blocks/rich-content";
@import "scss/blocks/twitter-slider";
@import "scss/blocks/related-project";
@import "scss/blocks/related-projects";
@import "scss/blocks/projects";
@import "scss/blocks/error";
@import "scss/blocks/doughnut";
@import "scss/blocks/bar-vertical";
@import "scss/blocks/bar-horizontal";
@import "scss/blocks/back-to-top";
@import "scss/blocks/project-images-anim";
@import "scss/blocks/quote-alt";
@import "scss/blocks/title-left-content-right";
@import "scss/blocks/numbers";
@import "scss/blocks/quote-people";
@import "scss/blocks/project-link";
@import "scss/blocks/place";
@import "scss/blocks/slideshow";
@import "scss/blocks/parallax";
@import "scss/blocks/table";
@import "scss/blocks/jobboard-top";
@import "scss/blocks/jobboard-list";
@import "scss/blocks/jobcard";
@import "scss/blocks/jobboard-offer-intro";
@import "scss/blocks/push-jobboard";
@import "scss/blocks/jobboard-fixed";
@import "scss/blocks/account-switch";
@import "scss/blocks/message-flash";
@import "scss/blocks/form-login";
@import "scss/blocks/event-block";
@import "scss/blocks/event-featured";
@import "scss/blocks/title-filters";
@import "scss/blocks/event-page-intro";
@import "scss/blocks/streaming-live";
@import "scss/blocks/list-participants";
@import "scss/blocks/list-gallery";
@import "scss/blocks/twitter-carousel";
@import "scss/blocks/push-events";
@import "scss/blocks/events";
@import "scss/blocks/cover-alt";
@import "scss/blocks/push-investir";
@import "scss/blocks/keyfigures-img-slider";
@import "scss/blocks/keyfigures-mosaic";
@import "scss/blocks/interactive-map";
@import "scss/blocks/section-flocker";
@import "scss/blocks/hero-banner";
@import "scss/blocks/notice";
@import "scss/blocks/sub-menu";
@import "scss/blocks/push-block";
@import "scss/blocks/tag-list";
@import "scss/blocks/visual-citation";
@import "scss/blocks/financial-actus";
@import "scss/blocks/key-figure";
@import "scss/blocks/financing-program";
@import "scss/blocks/visual-infolink";
@import "scss/blocks/info-card";
@import "scss/blocks/list-documents";
@import "scss/blocks/investir-become-actioner";
@import "scss/blocks/list-keyword";
@import "scss/blocks/investir-stock-info";
@import "scss/blocks/filter";
@import "scss/blocks/page-number";
@import "scss/blocks/video";

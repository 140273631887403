.push-events{
  margin: rem(100px) 0;
  overflow: hidden;
  @include tablet{
    margin: rem(50px) 0;
  }
  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $border;
    flex-wrap: wrap;
    margin-bottom: rem(30px);
  }
  &__title{
    @extend .heading-3;
    @include tablet{
      margin-bottom: 15px;
    }
  }
  &__bottom{
    .row{
      @include tablet{
        flex-wrap: nowrap;
        touch-action: pan-y;
        .col-item{
          width: 90%;
          flex:0 0 90%;
        }
      }
    }
  }
  &__item{
    &__top{
      background-size: cover;
      background-position: center;
      padding-top: 41px;
      padding-bottom: 52px;
      padding-left: 15px;
      padding-right: 15px;
      color: $white;
      text-align: center;
      border-radius: 4px;
      overflow: hidden;
      &__number{
        @extend .key-figures;
      }
      &__month{
        @extend .heading-5;
        margin-top: -25px;
        margin-bottom: 35px;
      }
      &__place{
        @extend .small-link;
        text-transform: uppercase;
      }
    }
    &__bottom{
      padding-top: 30px;
      &__tag{
        color: $turquoise;
        @extend .small-link;
        text-transform: uppercase;
        margin-bottom: rem(15px);
      }
      &__title{
        @extend .heading-5;
        margin-bottom: rem(20px);
      }
      &__text{
        @extend .body-small;
        margin-bottom: rem(20px);
      }
      &__tags{
        display: flex;
        flex-wrap: wrap;
        .tag{
          margin-right: 5px;
        }
      }
    }
  }
}
.video{
  .section-title{
    margin: 0 0 40px;
  }
}
.video-wrapper{
  background-color: #000;
  border-radius: rem(4px);
  padding-bottom: 56.25%;
  position: relative;
  @include size(100%, 0);

  .video-trigger{
    display: block;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    @include size(100%, 100%);

    img{
      border-radius: rem(4px);
      display: block;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      @include size(100%, 100%);
    }
    &:after{
      content: "";
      @include absolute-center();
      @include svgicon('../../images/icon_play.svg', 48px, 48px);
    }
  }
}
.video-caption{
  color: rgba($black, .45);
  @extend %Gotham;
  font-size: rem(17px);
  line-height: rem(26px);
  margin: 15px 0 0;

  @include tablet {
    font-size: rem(14px);
    line-height: rem(18px);
  }
}

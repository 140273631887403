:root {
    color: $black;
    font-size: $base-font-size;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    @extend %Gotham;
    @extend %antialiased;
}
body{
  overflow-x: hidden;
  position: relative;

  &:after{
    background-color: rgba(#000, .6);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all .3s ease;
    visibility: hidden;
    z-index: 99;
  }
  @include mobile {
    //padding-top: 60px;
  }
}
.outer-color{
  @media(max-width: 1520px){
    display: none;
  }
  &:before{
    background-color: #F2F2F2;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin-right: 760px;
    position: fixed;
    right: 50%;
    top: 0;
    z-index: 98;
  }
  &:after{
    background-color: #F2F2F2;
    bottom: 0;
    content: "";
    display: block;
    left: 50%;
    margin-left: 760px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 98;
  }
}

.sr-only.sr-only{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  border: 0 !important;
}

.related-projects{
  overflow: hidden;

  .related-projects-list{
    display: flex;
    margin-left: -$gutter;
    margin-right: -$gutter;

    @include mobile {
      flex-wrap: wrap;
    }

    &:after{
      display: block;
      content: "";
      clear: both;
    }

    &.related-projects-small{
      @include tablet {
        //margin-right: -5px;
        margin-bottom: 30px;
      }
      @include mobile {
        //margin-right: 25px;
      }
      .related-projects-list-item{
        flex: 0 1 50%;
        padding: 0 $gutter;

        @include tablet {
          padding: 0 5px;
        }
      }
    }

    @include tablet {
      margin-left: -5px;
      margin-right: -5px;
      //margin-right: -5px;
      //margin-right: 120px;
      margin-top: 10px;
      overflow: visible !important;
    }
    @include mobile {
      //margin-right: 25px;
    }

    .related-projects-list-item{
      flex: 0 0 50%;
      padding: 0 $gutter;

      @include tablet {
        padding: 0 5px;
      }
      @include mobile {
        flex: 0 0 100%;
        margin: 0 0 20px;
      }
    }
  }
  .related-projects-item{
    display: block;
    margin-top: 30px;
    position: relative;

    @include tablet {
      margin-top: 10px;
    }
    &:hover{
      .related-projects-item-visual img{
        opacity: 1;
        transform: scale(1.1);
      }
    }

    p{
      // @extend %Gotham;
      // font-size: rem(17px);
      // line-height: rem(26px);
      // margin: 15px 0 0;
    }
  }
  .related-projects-item-visual{
    border-radius: rem(4px);
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;

    img{
      left: 0;
      opacity: .8;
      position: absolute;
      top: 0;
      width: 100%;
      transition: opacity .3s ease, transform 3s ease-out;
      min-height: 100%;
    }
  }
  .related-projects-item-event{
    background: $violet url(../../images/event_bg@2x.jpg) 50% 50%/cover no-repeat;
    border-radius: rem(4px);
    display: flex;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    text-align: center;

    @include tablet{
      margin: 0 0 15px;
      width: auto;
    }
    .related-projects-item-event-content{
      color: $white;
      @include absolute-center;
      @include size(90%, auto);
    }
    .date{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(27px);
      letter-spacing: -.6px;

      @include tablet {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -.2px;
      }

      strong{
        display: block;
        @extend %GothamNarrow;
        font-size: rem(115px);
        line-height: rem(85px);
        letter-spacing: -2px;

        @media (max-width: 1024px){
          font-size: rem(95px);
          line-height: rem(65px);
          letter-spacing: -1.6px;
        }
        @include tablet {
          font-size: rem(95px);
          line-height: rem(65px);
          letter-spacing: -1.6px;
        }
      }
    }
    .place{
      margin: 15px 0 0;
      text-transform: uppercase;
      @extend .small-link;

      @include tablet {
          margin: 10px 0 0;
      }
    }
  }
  .related-projects-item-date{
    color: $turquoise;
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(14px);
    letter-spacing: 1px;
    margin: 30px 0 0;
    text-transform: uppercase;

    @include tablet {
      margin: 20px 0 0;
    }
  }
  .related-projects-item-title{
    @extend .heading-4;
    margin: 20px 0 0;

    @include tablet {
      margin: 10px 0 0;
    }
  }
  .related-projects-item-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px 0;

    @include tablet {
      margin: 10px -5px 0;
    }

    &>li{
      padding: 5px;
    }
    a{
      border: 1px solid $black;
      border-radius: rem(2px);
      color: $black;
      display: block;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(24px);
      padding: 0 10px;
      transition: all .3s ease;

      &:hover{
        background-color: $black;
        color: $white;
      }
    }
  }
}

.jobboard-offer-intro{
  border-bottom: 1px solid $border;
  padding-bottom: rem(20px);
  padding-top: rem(25px);
  &__suphead{
    text-transform: uppercase;
    color: $turquoise;
    font-size: rem(12px);
    @extend %GothamMedium;
  }
}
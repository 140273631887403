.proof{
  &.bg-alt {
    .proof-slider-info {
        background-color: $bg-turquoise!important;
    }
    .proof-slider-blue-quart {
        background-image: url(../../images/blue-quart-circle.svg)!important;
    }
  }
  .proof-slider{
    align-items: center;
    display: flex;
    margin-top: 30px;
    position: relative;
    height: 580px;
    gap: 40px;

    @include tablet-wide{
      flex-direction: column;
      height: auto;
      gap: 0;
    }

    .proof-slider-visual{
      height: 100%;
      left: 0;
      top: 0;
      width: 50%;
      border-radius: 1rem;
        overflow: hidden;
      position: relative;

      @include tablet-wide{
        height: 335px;
        left: auto;
        position: relative;
        top: auto;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
      }

      .proof-slider-visual-img{
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        //transform: translateX(-50px);
        top: 0;
        transition: all .8s ease;
        visibility: hidden;
        position: absolute;

        &.active{
          opacity: 1;
          //transform: translateX(0px);
          visibility: visible;
        }
      }
    }
    .proof-slider-info{
      align-items: center;
      background-color: $bg-turquoise-light;
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: top;
      height: 100%;
      overflow: hidden;
      padding: 50px 8px 50px 50px;
      position: relative;
      width: 50%;
      border-radius: 1rem;

      @include tablet-wide{
        min-height: 500px;
      }

      @include tablet-wide{
        min-height: 0px;
        padding: 25px 0 24px 16px;
        width: 100%;
        border-radius: 0 0 1rem 1rem;
      }
    }
    .proof-slider-content{
      @extend %GothamBook;
      font-size: rem(21px);
      line-height: rem(32px);
      position:relative;
      transition: all .8s ease;
      z-index: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      @include tablet-wide{
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }
    .proof-slider-number{
      display: block;
      @extend %GothamBold;
      font-size: rem(14px);
      line-height: rem(14px);
      margin: 0 0 20px;
    }
    .proof-slider-title{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(28px);
      margin: 0 0 16px;

      @include tablet-wide{
        font-size: rem(20px);
        line-height: rem(26px);
      }
    }
    .proof-slider-slides{
      height: 450px;
      @include tablet-wide {
        height: fit-content;
      }
      &>div{
        display: none;

        &:first-child{
          display: block;
        }
      }
      p {
        font-size: rem(17px);
        line-height: rem(26px);
        height: 290px;
        overflow: auto;
          padding-right: 42px;
        mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
        padding-bottom: 24px;
        @-moz-document url-prefix() {
          scrollbar-color: $turquoise-black-second transparent;
          scrollbar-width: thin;
        }
        &::-webkit-scrollbar {
        width: 4px;  /* Largeur de la scrollbar verticale */
        height: 12px; /* Hauteur de la scrollbar horizontale */
        }

        /* Style du thumb (la partie déplaçable) */
        &::-webkit-scrollbar-thumb {
          background-color: $turquoise-black-second; /* Couleur normale du thumb */
          border-radius: 16px;     /* Coins arrondis */
        }

        /* Désactiver les changements sur hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: $turquoise-black-second; /* Couleur normale du thumb */
          width: 4px; /* Conserve la même largeur */
          height: 12px; /* Conserve la même hauteur */
        }

        /* Désactiver les changements sur active (clic) */
        &::-webkit-scrollbar-thumb:active {
          background-color: $turquoise-black-second; /* Couleur normale du thumb */
          width: 4px; /* Conserve la même largeur */
          height: 12px; /* Conserve la même hauteur */
        }
        @include tablet-wide {
          padding-right: 16px;
          height:fit-content;
          padding-bottom: 40px;
          mask-image: none;
        }
      }
    }
    .proof-slider-nav{
      display: flex;
      margin: auto 0 0;
      justify-content: flex-start;

      @include tablet-wide {
        margin-top: 2rem;
        bottom: 30px;
      }

      .prev{
        border: 1px solid $white;
        display: block;
        margin-right: 10px;
        position: relative;
        filter: invert(1);
        @include circle(48px);

        &:before{
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%) rotate(180deg);
          @include svgicon('../../images/icon-arrow.svg', 24px, 24px);
          background-size: contain;
          background-position: center;

        }
      }
      .next{
        border: 1px solid $white;
        display: block;
        margin-right: 10px;
        position: relative;
        filter: invert(1);
        @include circle(48px);
        &:before{
          content: "";
          display: block;
          @include absolute-center();
          @include svgicon('../../images/icon-arrow.svg', 24px, 24px);
          background-size: contain;
          background-position: center;

        }
      }
      .next:hover, .prev:hover{
        opacity: 0.5;
      }
      .prev.disabled,.next.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
    .proof-slider-blue-quart {
      position: absolute;
      bottom: -20px;
      left: -20px;
      width: 162px;
      height: 162px;
      background-image: url(../../images/light-blue-quart-circle.svg);
        background-size: 100%;
      background-repeat: no-repeat;
      @include tablet-wide {
        left: unset;
        right: -10px;
        bottom: -10px;
        rotate: -90deg;
      }
      @include tablet {
        width: 88px;
        height: 88px;
      }
    }
  }
}

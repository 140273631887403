.list-gallery{
  margin: rem(100px) 0;
  overflow: hidden;
  @include tablet{
    margin: rem(50px) 0;
  }
  &__title{
    @extend .heading-3;
    margin-bottom: rem(16px);
  }
  &__wrap{
    padding-top: 31px;
    border-top: 1px solid $border;
  }
  &__item{
    background-size: cover;
    background-position: center;
    padding-bottom: 70.5%;
  }
  .row{
    @include tablet{
      flex-wrap: nowrap;
      touch-action: pan-y;
    }
  }
  .col-item{
    @include tablet{
      width: 90%;
      flex:0 0 90%;
    }
  }
}
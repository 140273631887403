.cookies-warning{
  background-color: $white;
  box-shadow: 0 -2px 2px 0 rgba(#000, .15);
  bottom: 0;
  left: 0;
  padding: 20px 0;
  position: fixed;
  right: 0;
  z-index: 9999;

  .cookies-warning-content{
    align-items: center;
    display: flex;
    @extend %Gotham;
    font-size: rem(17px);
    line-height: rem(26px);

    @include tablet {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      font-size: rem(14px);
      line-height: rem(21px);
    }
    &>div{
      @include mobile {
        width: 100%;
      }
    }
  }
  .cookies-warning-link{
    color: $turquoise;
    display: inline-block;
    margin: 15px 0 0;
    padding-left: 0px;
    position: relative;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(17px);
    transition: all .3s ease;

    &:hover{
      margin-left: 40px;

      @include tablet {
        margin-left: 30px;
      }
      &:before{
        opacity: 1;
      }
    }
    &:before{
      content: "";
      display: block;
      left: -40px;
      opacity: 0;
      transition: opacity .5s ease;
      @include absolute-center('y');
      @include svgicon('../../images/icon_arrow_long_left.svg', 24px, 24px);

    }
  }
  .button{
    @include mobile {
      margin-top: 10px;
      width: 100%;
    }
  }
}

.title-left-content-right{
  &__intro{
    margin-bottom: rem(20px);
  }
  &__content{
    margin-bottom: rem(40px);
    font-size: rem(21px);
    line-height: rem(32px);
  }
  &__details{
    &__intro{
      margin-bottom: rem(10px);
    }
    &__item{
      margin-bottom: rem(5px);
    }
  }
  .col-left{
    @include tablet{
      margin-bottom: rem(25px);
    }
  }
}
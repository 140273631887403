.publications-filter-mobile{
  display: none;

  @include tablet {
    display: block;
  }

  .publications-filter-mobile-toggle{
    align-items: center;
    background-color: $white;
    bottom: 0;
    box-shadow: 0 1px 5px 2px rgba(#000, .15);
    color: $turquoise-black-second;
    display: flex;
    @extend %GothamBold;
    font-size: rem(12px);
    letter-spacing: 1px;
    line-height: rem(14px);
    justify-content: center;
    height: 45px;
    left: 0;
    position: fixed;
    right: 0;
    text-transform: uppercase;
    z-index: 90;

    span{
      @extend %Gotham;
      font-size: rem(26px);
      letter-spacing: 1px;
      line-height: rem(26px);
      margin-left: 5px;
    }
  }
  .publications-filter-mobile-container{
    background-color: $white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    position: fixed;
    right: 0;
    top: 0;
    transition: transform .4s ease;
    transform: translateX(260px);
    width: 260px;
    z-index: 100;

    .publications-filter-mobile-head{
      align-items: center;
      border-bottom: 1px solid $border;
      flex: 0 0 60px;
      height: 60px;
      color: $turquoise-black-second;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      @extend %GothamBold;
      font-size: rem(12px);
      letter-spacing: 1px;
      line-height: rem(14px);
      text-transform: uppercase;

      .publications-filter-mobile-close{
        svg *{
          fill: $turquoise-black-second;
        }
      }
    }
    .publications-filter-mobile-body{
      flex: 1;
      min-height: 60px;
      overflow: auto;
    }
    .publications-filter-mobile-footer{
      //border-top: 1px solid $border;
      flex: 0;
      padding: 20px;

      .button{
        margin: 10px 0;
      }
    }
    .publications-filter-mobile-list{
      @extend %GothamBold;
      font-size: rem(12px);
      letter-spacing: 1px;
      line-height: rem(14px);
      text-transform: uppercase;

      &>li{
        &.disabled{
          .publications-filter-mobile-list-toggle{
            color: rgba($black, .15);
          }
        }
        &.open{
          .publications-filter-mobile-list-toggle{
            color: $turquoise-black-second;

            &:after{
              transform: translateY(-50%) rotate(-90deg);
            }
          }
        }
      }

      .publications-filter-mobile-list-toggle{
        border-bottom: 1px solid rgba($border, .25);
        display: block;
        padding: 12px 15px;
        position: relative;

        &:after{
          content: "";
          opacity: .45;
          position: absolute;
          right: 15px;
          top: 50%;
          transition: transform .3s ease;
          transform: translateY(-50%) rotate(90deg);
          @include svgicon('../../images/dropdown_arrow.svg', 7px, 10px);
        }
      }
      .publications-filter-mobile-sublist{
        display: none;
        li{
          border-bottom: 1px solid rgba($border, .25);
        }
        select{
          margin: 0 15px 10px;
          text-align: center;
          text-align-last: center;
          width: calc(100% - 30px);
        }
      }
      label{
        color: $black;
        display: flex;
        padding: 12px 15px;
      }
      select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $white url('../../images/icon_select_arrow.svg') 95% 50% no-repeat;
        border: 1px solid #CCD0D6;
        border-radius: 0;
        color: $black;
        display: block;
        height: 40px;
        padding: 10px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        @extend %GothamBold;
        font-size: rem(12px);
        letter-spacing: .8px;
        line-height: rem(20px);
      }
    }
  }
}
body.show-publications-filter-mobile{
  overflow: hidden;

  &:after{
    opacity: 1;
    visibility: visible;
  }

  .publications-filter-mobile-container{
    transform: translateX(0px);
  }
}
.publications-filters-container{
  border-top: 1px solid $border;
  display: flex;
  justify-content: space-between;

  @include tablet {
    border-top: none;
  }
}
.publications-filter{
  display: flex;
  padding: 20px 0;
  @extend %GothamBold;
  font-size: rem(14px);
  letter-spacing: 1px;
  line-height: rem(17px);
  position: relative;
  text-transform: uppercase;
  z-index: 1;

  @include tablet-wide {
    display: none;
  }

  .publications-filter-toggle{
    padding-right: 20px;
    position: relative;

    &:after{
      content: "";
      opacity: .45;
      position: absolute;
      right: 0;
      top: 50%;
      transition: transform .3s ease;
      transform: translateY(-50%) rotate(90deg);
      @include svgicon('../../images/dropdown_arrow.svg', 7px, 10px);
    }
    &.has-filters{
      color: $turquoise-black-second;
    }
  }
  .publications-filter-item{
    //min-width: 150px;
    margin-right: 40px;
    position: relative;

    &.disabled{
      opacity: .15;
    }
    &.open{
      .publications-filter-toggle{
        color: $turquoise-black-second;

        &:after{
          transform: translateY(-50%) rotate(-90deg);
        }
      }
      .publications-filter-dropdown{
        opacity: 1;
        visibility: visible;
        ul{
          max-height: 600px;
          overflow-y: auto;
        }
      }
    }
  }
  .publications-filter-dropdown{
    background-color: $white;
    border: 1px solid $border;
    left: 0;
    margin-top: 15px;
    opacity: 0;
    padding: 30px;
    position: absolute;
    top: 100%;
    transition: all .3s ease;
    visibility: hidden;
    width: 360px;
    z-index: 3;

    .button{
      margin-top: 15px;
    }
    .select-twin{
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;

      select{
        width: calc(50% - 10px);
      }
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $white url('../../images/icon_select_arrow.svg') 95% 50% no-repeat;
      border: 1px solid #CCD0D6;
      border-radius: 0;
      color: $black;
      display: block;
      height: 40px;
      padding: 10px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      @extend %GothamBold;
      font-size: rem(12px);
      letter-spacing: .8px;
      line-height: rem(20px);
    }
  }
}
.publications{
  //overflow: hidden;
  .row-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      @include tablet {
        display: none;
      }
    }
  }
  .show-mobile {
    display: none;
    @include tablet {
      margin-top: 32px!important;
      display: block;
    }
  }
  .section-title {
    text-transform: uppercase;
    font-size: rem(40px);
    line-height: rem(42px);
    letter-spacing: -1.6px;
    @extend %GothamBold;
  }
  .section-intro{
    max-width: 730px;
  }
  .publications-count{
    color: $turquoise;
    @extend %GothamBold;
    font-size: rem(14px);
    letter-spacing: 1px;
    line-height: rem(17px);
    margin: 20px 0 0;
    text-transform: uppercase;

    @include tablet {
      font-size: rem(12px);
      line-height: rem(14px);
    }
  }
  .publications-push{
    border-top: 1px solid $border;
    margin: 30px 0 0;
    padding: 40px 0 40px;

    .row {
        justify-content: space-between;
        align-items: center;
    }

    @include tablet {
      padding: 16px 0;
    }

    .publications-push-preview{
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
      border: 1px #C1C1C1;
      height: 560px;

      @include tablet {
        margin: 0 0 16px;
        max-width: 180px;
        height: auto;
      }
      img{
        display: block;

        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        position:relative;
        z-index: 1;

        @include tablet {
          min-width: 0;
          min-height: 0;
          width: 180px;
        }
      }
    }
    .publications-push-info{
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      @extend %Gotham;
      &:hover .publications-push-title {
        color: $turquoise-black-second;
      }
      font-size: rem(17px);
      line-height: rem(26px);

      @include tablet {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }
    .publications-push-title{
      @extend %GothamBold;
      margin: 0 0 24px;
      color: $black;
      font-size: rem(32px);
      line-height: rem(32px); /* 100% */
      letter-spacing: -1.28px;
      text-transform: uppercase;

      @include tablet {
        margin: 0 0 10px;
        font-size: rem(24px);
        letter-spacing: -0.96px;
      }
    }
    .publications-push-subtitle{
      color: $turquoise-black-second;
      @extend %GothamBold;
      font-size: rem(14px);
      line-height: rem(14px);
      margin: 0 0 40px;
      text-transform: uppercase;
      letter-spacing: -0.28px;


      @include tablet {
        margin: 0 0 32px;
      }
    }
    .publications-push-date{
      color: $black;
      @extend %GothamBold;
      font-size: rem(14px);
      letter-spacing: -0.28px;
      line-height: rem(14px);
      margin: 0 16px 0 0;
      text-transform: uppercase;
    }
    .button{
      font-size: rem(14px);
      line-height: rem(14px);
      margin: 0;
      @include tablet {
        width: fit-content;
      }
    }
  }
  .publications-list{
    border-bottom: 1px solid $border;
    margin: 30px 0 0;

    @include tablet {
      margin: 0;
    }

    &>li{
      border-top: 1px solid $border;

      .publications-list-entry{
        align-items: center;
        display: flex;
        padding: 40px 0;

        @include tablet {
          align-items: flex-start;
          flex-direction: column;
          padding: 16px 0;
        }
      }
    }
    .publications-list-icon{
      margin-right: 50px;
      @include size(110px, auto);
      border-radius: 1rem;
      border: 1px solid $border;
      overflow: hidden;

      @include tablet {
        margin: 0 0 16px 0;
        //max-height: 156px;
        @include size(110px, auto);
      }

      img{
        display: block;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .publications-list-info{
      display: flex;
      flex: 1 1;
      justify-content: space-between;

      @include tablet {
        flex-direction: column;
        width: 100%;
      }
      .publications-list-file{
        display: flex;
        flex-direction: column;
        &:hover .publications-list-title {
          color: $turquoise-black-second;
        }
      }
      .publications-list-title{
        @extend %GothamMedium;
        font-size: rem(21px);
        line-height: rem(28px);
        margin: 16px 0;
        max-width: 730px;

        @include tablet {
          font-size: rem(17px);
          line-height: rem(26px);
          letter-spacing: -0.68px;
        }
      }
      .publications-list-subtitle{
        color: $turquoise-black-second;
        @extend %GothamMedium;
        font-size: rem(14px);
        letter-spacing: 1px;
        line-height: rem(14px);
        margin: 0;
        text-transform: uppercase;
      }
      .publications-list-date{
        color: $black;
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        margin: 0 15px 0 0;
        text-transform: uppercase;
        letter-spacing: -0.28px;
      }
    }
    .publications-list-action{
      margin-left: 30px;

      @include tablet {
        margin: 20px 0 0;
      }
      @include tablet {
        width: 100%;
      }
    }
  }
  .publications-list-load-more{
    text-align: center;
    margin-top: 30px;
  }
}

.publications .publications-list .publications-list-icon {
  width: 180px;
}
.publications .publications-list-type {
  display: flex;
  padding: 6px 8px;
  justify-content: left;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #04142D;
  width: fit-content;
  font-size: rem(12px);
  line-height: rem(16px);
}
.publications-push .publications-list-title {
  font-size: 21px!important;
}
.publications .button {
  @include tablet {
    width: fit-content;
  }
  @include mobile {
    width: 100%;
  }
}
.publications-push .button::after,.publications-list-action .button::after {
  content: url(../../images/investir-icade/icadeDownload.svg) !important;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  line-height: normal;
  transition-duration: 0.3s;
  filter: none;
  margin-top: -10px;
}
.publications .publications-list .publications-list-action {
  display: flex;
  margin: auto 0;
}

@include tablet {
  .publications .publications-list .publications-list-info .publications-list-title {
    margin-bottom: 16px;
    margin-top: 8px;
    font-size: rem(17px);
    line-height: rem(26px);
    letter-spacing: -0.68px;
  }
  .publications .publications-list .publications-list-info .publications-list-subtitle {
    margin-bottom: 32px;
  }
}

.alt-publications {
  .publications-list {
    border-bottom: none;
    & > li {
      margin-top: 40px;
      border-top: none;
      border-bottom: 1px solid $border;
      @include tablet {
        margin-top: 32px;
      }
      .publications-list-entry {
        padding-top: 24px;
      }
    }
    .publications-subtitle {
      @extend %GothamMedium;
        font-size: rem(21px);
      line-height: rem(28px);
    }
  }
}

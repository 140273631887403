.section-flocker{
  &__content{
    margin-top:rem(49px)
  }
  &__feed{
    //display: none;
    &.is-active{
      //display: block;
    }
  }
  &__list{
    padding-top: rem(76px);
    @include tablet(){
      padding-top:0;
    }
    &__item{
      margin-bottom: rem(8px);
      &:last-child{
        margin-bottom: 0;
      }
      &__btn{
        appearance: none;
        background:transparent;
        border:0;
        cursor: pointer;
        @extend .body;
        overflow: hidden;
        color:$black;
        &__inner{
          position: relative;
          display: flex;
          align-items: center;
          opacity: 0.5;
          transition: all 250ms ease;
          transform: translateX(-16px);
          &:before{
            content:"";
            width:8px;
            height: 8px;
            border-radius:50%;
            background-color: $turquoise;
            margin-right: 8px;
          }
        }
        &:hover &{
          &__inner{
            opacity: 1;
            transform: translateX(0px);
          }
        }
        &.is-active &{
          &__inner{
            opacity: 1;
            transform: translateX(0px);
          }
        }
      }
    }
  }
  .flockler-grid-item__media__post-type-icon{
    display:none;
  }
  .flockler-grid-item{
    cursor: pointer;
  }
  .flockler-grid-item__wrapper{
    border-radius: 0;
  }
  .flockler-grid-item__meta{
    position: absolute;
    top:0;
    left:0;
    z-index: 5;
    padding:0;
    height: 60px;
    width: 60px;
    .flockler-grid-item__context{
      position: absolute;
      top: 50%;
      margin: 0 !important;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      a{
        color:white !important;
      }
    }
  }
  .flockler-grid-item__profile{
    display: none;
  }
  .flockler-grid-item__footer{
    display:none;
  }
}
.gm-style {
  .controls {
    font-size: 28px; /* this adjusts the size of all the controls */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 300;
    height: 1em;
    margin: 6px;
    text-align: center;
    user-select: none;
    padding: 2px 6px;
    width: 1em;
    button {
      border: 0;
      background-color: white;
      color: black;
    }
    button:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .controls.zoom-control {
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 8px;
    background: var(--fff, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    button {
      font: 0.85em Arial;
      margin: 1px;
      padding: 0;
    }
    .zoom-control-in {
      border-bottom: 1px solid rgba(4, 20, 45, 0.30);
    }
  }
  .gm-style-iw-c {
    padding: 0;
  }
  .gm-style-iw-d {
    width: auto;
    height: auto;
    -webkit-appearance: initial;
  }
  .gm-style-iw-d::-webkit-scrollbar {
    display: none;
  }
  .gm-ui-hover-effect {
    top: 16px!important;
    right: 16px!important;
    width: 24px!important;
    height: 24px!important;
  }
  .poi-info-window {
    padding: 40px 20px 20px 20px;
    .view-link {
      display: none;
    }
  }
  .gm-ui-hover-effect span {
    width: 24px!important;
    height: 24px!important;
    margin: 0!important;
  }
  .transit-container {
    .transit-line-group {
      padding: 15px 0;
    }
  }
}

.filter-map {
    .tag-list-button-type {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
.grid-map {
  margin-top: 40px;
  @include tablet {
    padding: 0;
    margin-top: 24px;
  }
  .list-projets-cell {
    @include tablet {
      padding: 0 20px;
    }
  }
  .switch-container {
    @include tablet {
      padding-left: 16px;
    }
  }
  .row-map {
    display: flex;
    @include tablet {
      display: block;
    }
  }
}
.cell-map {
  position: relative;
  padding: 0;
  #map {
    height: 600px;
    @include tablet {
      height: 500px!important;
    }
  }
  .switch-container {
    position: absolute;
    bottom: -32px;
    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 17px;
      .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      }

      /* Hide default HTML checkbox */
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input:checked + .slider {
        background-color: #2196F3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 17px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.list-projets-cell {
  .count-result {
    margin-bottom: 24px;
    color: #32BBC5;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px; /* 121.429% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    @include tablet {
      margin-top: 62px;
    }
  }
  #list-result {
    max-height: 560px;
    overflow-y: scroll;
    overflow-x: hidden;
    @include tablet {
      max-height: 450px;
    }
    .result {
      padding-bottom: 24px;
      padding-top: 24px;
      border-bottom: 1px solid #D2D2D3;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.48px;
      text-transform: uppercase;
      cursor: pointer;
      .result-title {
        .dot {
          margin-left: 0;
          margin-right: 10px;
        }
        .Bureaux {background-color: #EEC44F;}
        .LocauxActiviteIndus {background-color: #300A69;}
        .Terrain {background-color: #ABC653;}
        .Autres {background-color: #C98094;}
      }
      @include tablet {
        padding-bottom: 16px;
        padding-top: 16px;
      }
    }
    @include tablet {
      margin-right: 3rem !important;
    }
  }
}
#infoWindow {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    .info-container {
      padding: rem(16px) rem(10px);
      .tag:hover {
        color: #04142D!important;
        background-color: initial;
      }
      .info-title {
        font-size: rem(21px);
        font-weight: 600;
        line-height: rem(26px); /* 123.81% */
        margin-bottom: rem(8px);
      }
      .info-address {
        font-size: rem(12px);
        font-weight: 600;
        line-height: rem(14px); /* 116.667% */
        letter-spacing: 0.48px;
        text-transform: uppercase;
        margin-bottom: rem(16px);
      }
      .button {
        padding: rem(12px) rem(24px);
        font-size: rem(12px);
        font-weight: 600;
        line-height: rem(14px); /* 116.667% */
        letter-spacing: 0.48px;
        text-transform: uppercase;
      }
    }
  }
#infoWindow, .gm-style-iw-tc, .poi-info-window {
  @include tablet {
    display: none;
  }
}
#infoWindow-mobile {
  @include tablet {
  }
}
#infoWindow-mobile {
      background-color: #FFFFFF;
      position: fixed;
      bottom: -1000px;
      left: 0;
      width: 100vw;
      z-index: 1000;
      padding: rem(56px) rem(20px) rem(32px) rem(20px);
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
      transition: bottom 0.3s ease-in-out;
      #btn-close {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 14px;
        height: 14px;
        background-image: url("../../images/icon_close.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
      }
      #infoWindow {
        display: flex;
        max-width: none;
        width: 100%;
        img {
          margin-bottom: rem(24px);
        }
        .info-container {
          padding: 0;
          .button-dotted {
            font-size: rem(12px);
            margin-bottom: rem(8px);
          }
          .button {
            margin: 0;
          }
        }
      }
}
.news{

}
.news-count{
  color: $turquoise;
  @extend %GothamMedium;
  font-size: rem(14px);
  letter-spacing: 1px;
  line-height: rem(17px);
  margin: 20px 0 0;
  text-transform: uppercase;

  @include mobile {
    font-size: rem(12px);
    line-height: rem(14px);
  }
}
.news-listing{
  border-top: 1px solid rgba($black, .35);
  margin: 30px 0 0;

  @include mobile {
    margin: 15px 0 20px;
  }

  &>li{
    border-bottom: 1px solid rgba($black, .35);
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    @include tablet{
      flex-direction: column;
    }
  }
  .news-listing-infos{
    flex: 1 1 100%;
    max-width: 730px;
    order: 1;
    @extend %Gotham;
    font-size: rem(17px);
    line-height: rem(26px);

    @include tablet{
      order: 2;
    }

    .news-listing-type{
      color: $turquoise;
      margin: 0 0 15px;
      text-transform: uppercase;
      @extend .small-link;
    }
    .news-listing-title{
      margin: 0 0 15px;
      @extend .heading-4;

      a{
        color: $black;
      }
    }
    .news-listing-tags{
      display: flex;
      flex-wrap: wrap;
      margin: 15px -5px 0;

      @include tablet {
        margin: 10px -5px 0;
      }

      &>li{
        padding: 5px;
      }
      a{
        border: 1px solid $black;
        border-radius: rem(2px);
        color: $black;
        display: block;
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(24px);
        padding: 0 10px;
        transition: all .3s ease;

        &:hover{
          background-color: $black;
          color: $white;
        }
      }
    }
  }
  .news-listing-media{
    flex: 0 0 290px;
    order: 2;

    @include tablet{
      flex: 0 0 auto;
      order: 1;
    }

    .news-listing-image{
      border-radius: rem(4px);
      display: block;
      height: 0;
      overflow: hidden;
      padding-bottom: 74.48%;
      position: relative;
      width: 290px;

      &:hover{
        img{
          opacity: 1;
          transform: scale(1.1);
        }
      }
      @include tablet{
        margin: 0 0 15px;
        width: auto;
      }

      img{
        left: 0;
        opacity: .8;
        position: absolute;
        top: 0;
        width: 100%;
        transition: opacity .3s ease, transform 3s ease-out;
      }
    }
    .news-listing-pdf{
      display: block;
      text-align: right;

      @include tablet{
        margin: 0 0 15px;
        text-align: left;
      }
    }
    .news-listing-event{
      background: $violet url(../../images/event_bg@2x.jpg) 50% 50%/cover no-repeat;
      border-radius: rem(4px);
      display: flex;
      height: 0;
      overflow: hidden;
      padding-bottom: 74.48%;
      position: relative;
      text-align: center;
      width: 290px;

      @include tablet{
        margin: 0 0 15px;
        width: auto;
      }
      .news-listing-event-content{
        color: $white;
        @include absolute-center;
        @include size(90%, auto);
      }
      .date{
        @extend %GothamMedium;
        font-size: rem(21px);
        line-height: rem(27px);
        letter-spacing: -.6px;

        strong{
          display: block;
          @extend %GothamNarrow;
          font-size: rem(115px);
          line-height: rem(85px);
          letter-spacing: -2px;
        }
      }
      .place{
        margin: 15px 0 0;
        text-transform: uppercase;
        @extend .small-link;
      }
    }
    .news-listing-presskit{
      background: $yellow url(../../images/presskit_bg@2x.jpg) 50% 50%/cover no-repeat;
      border-radius: rem(4px);
      display: flex;
      height: 0;
      overflow: hidden;
      padding-bottom: 74.48%;
      position: relative;
      text-align: center;
      width: 290px;

      @include tablet{
        margin: 0 0 15px;
        width: auto;
      }
      .news-listing-presskit-content{
        color: $white;
        @include absolute-center;
        @include size(90%, auto);
      }
      .news-listing-presskit-icon{
        display: block;
        margin: 0 auto 30px;
        @include svgicon('../../images/presskit_icon.svg', 48px, 61px);
      }
      .button{
        background-color: $white;
      }
    }
  }
}

.account-switch{
  position: relative;
  &.is-open &{
    &__menu{
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
  }
  &__menu{
    position: absolute;
    top: calc(100%  + 15px);
    left: 0;
    right: 0;
    background-color: $white;
    border: 1px solid $border;
    padding: 0px 18px;
    padding-bottom: 17px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms;
    ul{
      li{
        display: block;
        padding-top: 18px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border;
        a{
          display: inline-flex;
          align-items: center;
          @extend %GothamMedium;
          font-size: rem(12px);
          text-transform: uppercase;
          transition: all 250ms;
          &:hover{
            color: $turquoise;
          }
          svg{
            margin-right: 10px;
          }
        }
      }
    }
  }
  &__btn{
    width: 100%;
    margin-top: 17px;
    justify-content: center;
  }
}
.numbers{

  .col-wrap{
    margin-bottom: rem(30px);
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__wrap{
    border-top:1px solid rgba(4,20,45,0.3);
    padding-top: 30px;
  }

  &__number{
    color:$turquoise;
  }

  &__text{
    opacity: 0.5;
    max-width: 290px;
    @extend .body-small;
  }
}
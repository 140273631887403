.investir-stock-info-subtitle {
  font-family: "Avenir Next", sans-serif;
  font-size: 21px;
  font-weight: 400;
  color: $black;
  margin-bottom: 32px;
  @include tablet {
    font-size: 16px;
  }
}

.investir-stock-info-cell {
  @include tablet {
    margin-bottom: 10px;
  }
}

.investir-stock-info-block {
  background-color: $turquoise-third;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    font-size: rem(14px);
    @extend %GothamMedium;
    color: $turquoise;
    margin-bottom: 24px;
  }
  &-value {
    font-size: rem(115px);
    @extend %GothamNarrow;
    color: $turquoise;
    @include tablet {
      font-size: rem(70px);
    }
  }
  &-change {
    font-size: rem(28px);
    margin-bottom: 16px;
  }
  &-date {
    font-size: rem(17px);
    color: $grey-fourth;
  }
}
.custom-checkbox{
  color: rgba($black, .5);
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  sup{
    font-size: 10px;
    display: inline;
    line-height: 10px;
    margin-top: -3px;
    margin-left: 5px;
  }

  input[type="checkbox"]{
    position: absolute;
    inset: 0;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: hidden;
    width: 0;
    height: 0;
  }
  input[type="checkbox"]:checked + .checkbox{
    background-color: $black;
    border-color: $black;
  }
  .checkbox{
    border: 1px solid $border;
    display: block;
    margin-right: 10px;
    @include size(12px, 12px);
  }
}
.custom-radio{
  display: flex;
  padding: 10px 0;
  cursor: pointer;

  input[type="radio"]{
    display: none;
  }
  input[type="radio"]:checked + .radio{
    background-color: $black;
    border-color: $black;
  }
  .radio{
    border: 1px solid $border;
    display: block;
    margin-right: 10px;
    @include circle(12px);
  }
}

.input-wrap{
  display: flex;
  margin-bottom: rem(30px);
  &.errors{
    label{
      color:$red;
    }
  }
  &__error{
    font-size: rem(12px);
    line-height: rem(12px);
    margin-left: 15px;
    color: $red;
    align-self: center;
    @extend %GothamMedium;
    @include tablet{
      margin-top: 15px;
    }
  }
  &.inline{
    display: block;
  }
  @include tablet{
    flex-wrap: wrap;
  }
  label{
    color:$turquoise;
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(12px);
    width: 140px;
    margin-top: 15px;
    text-transform: uppercase;
    @include tablet{
      width: 100%;
      margin-top: 0;
      margin-bottom: rem(15px);
    }
  }

  &__input{
    width: 399px;
    &--doubled{
      display: flex;
    }
    .item{
      flex-grow: 1;
      width: 50%;
      &:first-child{
        margin-right: 15px;
      }
    }
    @include tablet{
      width: 100%;
    }
  }
  &__message{
    margin-left: 30px;
    font-size: rem(12px);
    line-height: rem(12px);
    padding-top: 10px;
    @include tablet{
      display: none;
    }
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="tel"]{
    @extend .body-small;
    border:1px solid $border;
    width: 100%;
    border-radius: 26px;
    padding: 9px 15px;
    font-size: rem(17px);
    line-height: rem(26px);
    color: rgba($black, 0.5);
    &:focus{
      outline: 0;
    }
    &.input-error{
      border-color: $red;
    }
    &.js-input-phone{
      padding-left: 60px;
    }
  }
  .iti{
    width: 100%;
    &__flag-container{
      padding-left: 10px;
    }
  }

  &__link{
    display: block;
    text-align: right;
    color:$turquoise;
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(12px);
    margin-top: 8px;
    text-transform: uppercase;
  }
}

.form-text{
  display: flex;
  margin-bottom: rem(30px);
  @include tablet{
    flex-wrap: wrap;
  }
  &__left{
    color:$turquoise;
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(12px);
    width: 140px;
    text-transform: uppercase;
    margin-top: 5px;
    @include tablet{
      width: 100%;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  &__right{
    @extend .body-small;
    @include tablet{
      width: 100%;
    }
  }
}

.js-upload-input{
  input{
    display: none;
  }
  &__wrap{
    &__left{
      border:1px solid $border;
      width: 100%;
      border-radius: 26px;
      padding: 9px 15px;
      font-size: rem(17px);
      line-height: rem(26px);
      cursor: pointer;
      color: rgba($black, 0.5);
      margin-bottom: rem(17px);
      background-image: url('../../images/attachment.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 30px) center;
    }
    &__right{
      display: inline-flex;
      .text{
        @include mobile{
          font-size:13px;
        }
      }
      .remove{
        width: 15px;
        height: 14px;
        background-image: url("../../images/icon_close_grey.svg");
        background-size: cover;
        background-position: center;
        margin-right: 12px;
        cursor: pointer;
        display: none;
      }
      &.has-value{
        .remove{
          display: block;
        }
      }
    }
  }
}
.js-input-date{
  background-image: url('../../images/icon_calendar.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 30px) center;
}
.form{
  &__head{
    border-bottom: 1px solid $border;
    padding-bottom: 15px;
    margin-bottom: 47px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &__title{
      @extend .heading-5;
    }

    &__right{
      color: rgba($black, .45);
      @include tablet{
        display: none;
      }
    }
  }
  .button{
    @include tablet{
      display: none;
    }
  }
}

.form-info{
  border-top: 1px solid $border;
  padding-top: 10px;
  @extend .body-small;
}

.btn-submit{
  cursor: pointer;
  background-color: $turquoise;
  width: 100%;
  color: $white;
  font-size: rem(12px);
  line-height: rem(20px);
  padding: 14px 30px;
  text-transform: uppercase;
  text-align: center;
  transition: all .3s ease;
  border-radius: rem(24px);
  border: 1px solid $turquoise;
  appearance: none;
  @extend %GothamMedium;
  &:hover{
    background-color: $white;
    color:$turquoise;
  }
  &--alt{
    color: $white;
    background-color:$black;
    border-color: $black;
    &:hover{
      background-color: transparent;
      color:$black;
    }
  }
}
.form-go-back{
  @extend %GothamMedium;
  font-size: rem(12px);
  line-height: rem(18px);
  list-style: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: rgba($black, .45);
  margin-bottom: rem(30px);

  &:before{
    content:"";
    margin-right: 5px;
    opacity: .45;
    display: inline-block;
    transform: rotate(-180deg);
    @include svgicon('../../images/icon_arrow_small_left.svg', 6px, 9px);
  }
}

.select-input{
  appearance: none;
  @extend .body-small;
  border:1px solid $border;
  width: 100%;
  border-radius: 26px;
  padding: 9px 15px;
  padding-right: 30px;
  font-size: rem(17px);
  line-height: rem(26px);
  color: rgba($black, 0.5);
  cursor: pointer;
  background-image: url('../../images/carret-black.svg');
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  &::-ms-expand {
    display: none;
  }
  &:focus{
    outline: 0;
  }
}
.js-select-multiple{
  position: relative;
  .js-select-multiple-vals{
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(10px);
  }
  .js-select-multiple-val{
    display: inline-flex;
    margin-right: rem(10px);
    .remove{
      width: 15px;
      height: 14px;
      background-image: url("../../images/icon_close_grey.svg");
      background-size: cover;
      background-position: center;
      margin-right: 12px;
      cursor: pointer;
    }
  }
  .js-select-multiple-checkboxes{
    position: absolute;
    top:50px;
    left:0;
    right:0;
    border:1px solid $border;
    display: none;
    background-color: white;
    z-index: 10;
    &.is-open{
      display: block;
    }
    label{
      display: block;
      cursor: pointer;
      margin: 0;
      width: 100%;
      input{
        display: none;
        &:checked{
        ~ span{
          background-color: $border;
          color:$black;
        }
        }
      }
      span{
        color: rgba($black, 0.5);
        display: block;
        padding: 10px;
        &:hover{
          background-color: rgba($border, 0.4);
        }
      }
    }
  }
}

.js-input-cloner{
  position: relative;
  @include mobile{
    max-width: calc(100% - 50px);
  }
  .js-input-cloner-original, .js-input-clone-clone{
    margin-bottom: rem(15px);
  }
  .js-input-cloner-tools{
    position: absolute;
    bottom:27px;
    left: calc(100% + 10px);
    display: flex;
  }
  .add{
    position: relative;
    width: 19px;
    height: 19px;
    cursor: pointer;
    margin-right: 10px;
    flex: 0 0 19px;
   &:before{
     content:"";
     width: 2px;
     height: 19px;
     position: absolute;
     background-color: $black;
     top:50%;
     left:50%;
     transform: translateY(-50%) translateX(-50%);
   }
   &:after{
     content:"";
     width: 19px;
     height: 2px;
     position: absolute;
     background-color: $black;
     top:50%;
     left:50%;
     transform: translateY(-50%) translateX(-50%);
   }
  }
  .remove{
    position: relative;
    width: 19px;
    height: 19px;
    cursor: pointer;
    display: none;
    flex: 0 0 19px;
    &:after{
      content:"";
      width: 19px;
      height: 2px;
      position: absolute;
      background-color: $black;
      top:50%;
      left:50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
/* Headings */

.heading-1 {
  @extend %GothamMedium;
  font-size: rem(82px);
  letter-spacing: -2.5px;
  line-height: rem(86px);

  @include tablet {
    font-size: rem(35px);
    letter-spacing: -.6px;
    line-height: rem(38px);
  }
  @include mobile {
    font-size: rem(32px);
    letter-spacing: -.6px;
    line-height: rem(38px);
  }
}

.heading-2 {
  @extend %GothamMedium;
  font-size: rem(60px);
  letter-spacing: -1.8px;
  line-height: rem(74px);

  @include tablet {
    font-size: rem(32px);
    letter-spacing: -.6px;
    line-height: rem(36px);
  }
  @include mobile {
    font-size: rem(28px);
    letter-spacing: -.7px;
    line-height: rem(32px);
  }
}

.heading-3 {
  @extend %GothamMedium;
  font-size: rem(44px);
  letter-spacing: -1.4px;
  line-height: rem(54px);

  @include tablet {
    font-size: rem(30px);
    letter-spacing: -.7px;
    line-height: rem(36px);
  }
  @include mobile {
    font-size: rem(24px);
    letter-spacing: -.5px;
    line-height: rem(28px);
  }
}
.heading-4 {
  @extend %GothamMedium;
  font-size: rem(32px);
  letter-spacing: -.8px;
  line-height: rem(40px);

  @include tablet {
    font-size: rem(25px);
    letter-spacing: -.5px;
    line-height: rem(30px);
  }
  @include mobile {
    font-size: rem(20px);
    letter-spacing: -.2px;
    line-height: rem(26px);
  }
}
.heading-5 {
  @extend %GothamMedium;
  font-size: rem(21px);
  letter-spacing: -.6px;
  line-height: rem(27px);

  @include tablet {
    font-size: rem(20px);
    letter-spacing: -.5px;
    line-height: rem(22px);
  }
  @include mobile {
    font-size: rem(18px);
    letter-spacing: -.1px;
    line-height: rem(22px);
  }
}

/* Chapo */

.chapo {
  @extend %Gotham;
  font-size: rem(28px);
  line-height: rem(38px);

  @include tablet {
    font-size: rem(18px);
    line-height: rem(24px);
  }
  @include mobile {
    font-size: rem(18px);
    line-height: rem(26px);
  }
}

/* others */

.body {
  @extend %Gotham;
  font-size: rem(21px);
  line-height: rem(32px);

  @include tablet {
    font-size: rem(16px);
    line-height: rem(22px);
  }
  @include mobile {
    font-size: rem(16px);
    line-height: rem(22px);
  }
}
.body-small {
  @extend %Gotham;
  font-size: rem(17px);
  line-height: rem(26px);

  @include tablet {
    font-size: rem(14px);
    line-height: rem(20px);
  }
  @include mobile {
    font-size: rem(14px);
    line-height: rem(18px);
  }
}
.normal-link {
  @extend %GothamMedium;
  font-size: rem(14px);
  letter-spacing: 1px;
  line-height: rem(17px);

  @include tablet {
    font-size: rem(14px);
    letter-spacing: 1px;
    line-height: rem(17px);
  }
  @include mobile {
    font-size: rem(14px);
    letter-spacing: 1px;
    line-height: rem(17px);
  }
}
.small-link {
  @extend %GothamMedium;
  font-size: rem(12px);
  letter-spacing: .5px;
  line-height: rem(14px);

  @include tablet {
    font-size: rem(12px);
    letter-spacing: .5px;
    line-height: rem(14px);
  }
  @include mobile {
    font-size: rem(12px);
    letter-spacing: 1px;
    line-height: rem(14px);
  }
}
.key-figures {
  @extend %GothamNarrow;
  font-size: rem(115px);
  letter-spacing: -2px;
  line-height: rem(125px);

  @include tablet {
    font-size: rem(70px);
    letter-spacing: -2px;
    line-height: rem(80px);
  }
  @include mobile {
    font-size: rem(70px);
    letter-spacing: -2px;
    line-height: rem(80px);
  }
}
.key-figures-big {
  @extend %GothamNarrow;
  font-size: rem(230px);
  letter-spacing: -4px;
  line-height: rem(200px);

  @include tablet {
    font-size: rem(90px);
    letter-spacing: -2.5px;
    line-height: rem(80px);
  }
  @include mobile {
    font-size: rem(90px);
    letter-spacing: -2.5px;
    line-height: rem(80px);
  }
}


.finances{
  @extend %GothamMedium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: rem(1px);
  display: inline-flex;
  position: relative;
  align-items: center;
  &:before{
    content:"";
    width: 7px;
    height: 4px;
    display: inline-block;
    margin-right: 8px;
  }
  &.up{
    color:$green-second;
    &:before{
      background: url('../../images/carret-green.svg');
      background-size: contain;
    }
  }
  &.down{
    color:$red;
    &:before{
      background: url('../../images/carret-red.svg');
      background-size: contain;
    }
  }
}

.tag{
  border: 1px solid $black;
  border-radius:4px;
  display: inline-flex;
  padding: 5px 10px;
  color: $black;
  align-items: center;
  @extend %GothamMedium;
  font-size: rem(12px);
  line-height: rem(14px);
  letter-spacing: rem(0.5px);
  transition: all 250ms;
  margin-bottom: 5px;
  &:hover{
    background-color: $black;
    color: $white;
    cursor: pointer;
  }
  &--white{
    border-color: $white;
    color: $white;
    &:hover{
      background-color: $white;
      color: $black;
    }
  }
  svg{
    margin-right: 5px;
  }
}

.color-turquoise {
  color: $turquoise;
}
p {
  line-height: 32px;
  font-size: 1.16667rem;
  @include tablet {
    font-size: rem(16px);
    line-height: 22px;
  }
}

p{
  margin: 20px 0 40px;
  @extend .body;

  @include tablet {
    margin: 10px 0 22px;
  }
}
strong,b{@extend %GothamMedium;}
i{font-style: italic}
h1, h2, h3, h4 {
  margin: 0 0 32px;
  text-transform: uppercase;
  @extend %GothamBold;
}
h1 {
  font-size: rem(56px);
  line-height: rem(64px);
  letter-spacing: -2.24px;
  @include tablet {
    font-size: rem(32px);
    line-height: normal;
    letter-spacing: -1.28px;
  }
}
h2 {
  font-size: rem(40px);
  line-height: rem(42px);
  letter-spacing: -1.6px;
  @include tablet {
    font-size: rem(24px);
    line-height: normal;
    letter-spacing: -0.96px;
  }
}
h3 {
  font-size: rem(32px);
  line-height: rem(32px);
  letter-spacing: -1.28px;
  @include tablet {
    font-size: rem(24px);
    line-height: normal;
    letter-spacing: -0.96px;
  }
}
H4 {
  font-size: rem(21px);
  line-height: rem(28px);
  text-transform: none;
}
p {
  font-size: rem(17px);
  line-height: 26px;
  font-weight: 400;
  u, a {
    text-underline-offset: 3px;
  }
  a {
    color: $turquoise-black;
    text-decoration: underline;
    &:hover {
      opacity: 50%;
    }
  }
}
sup, sub {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}
sub {
  vertical-align: sub;
}

.js-cover-alt-top{
  position: sticky;
  z-index: 0;
  top:0;
  left:0;
  right: 0;
}
.js-cover-alt-page{
  margin-top: rem(60px);
  position: relative;
  z-index: 1;
}
.js-cover-alt-page-bg{
  background-color: $white;
  .js-cover-alt-page-bg-spacer{
    height: 1px;
  }
}
.show-nav{
  .js-cover-alt-top{
    z-index: 99;
  }
}
.cover-alt-top{
  padding-top: rem(260px);
  @include tablet{
    padding-top: rem(110px);
  }
  &__title{
    @extend .heading-2;
    text-align: center;
    margin-bottom: rem(20px);
  }
  &__content{
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    font-size: rem(18px);
    line-height: rem(24px);
  }
  .row-top{
    justify-content: center;
    margin-bottom: rem(60px);
    @include tablet{
      margin-bottom: rem(50px);
    }
  }
}
.cover-alt-bottom{
  &__cover{
    position: relative;
    line-height: 0;
    max-width: 1520px;
    margin: 0 auto;
    &__layer{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      background: rgba(0,0,0,0.20);
      &:before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background-image: linear-gradient(179deg, rgba(4,20,45,0.00) 50%, #000000 100%);
      }
      &:after{
        content: "";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background-image: linear-gradient(0deg, rgba(4,20,45,0.00) 75%, #000000 100%);
      }
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}
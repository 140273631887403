.push-project{
  background-color: $white;
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  .cell-12 {
    position: relative;
  }
  @include tablet-wide {
    width: auto;
  }
  &-content {
    background-color: rgba(0, 133, 161, 0.10);
    border-radius: 1rem;
    overflow: hidden;
    padding: 80px;
    text-align: center;
    @include tablet {
      padding: 48px 32px 64px;
    }
  }
  &-quart-circle {
    position: absolute;
    width: 162px;
    height: 162px;
    background-size: 100%;
    bottom: -40px;
    right: -20px;
    background-image: url(../../images/light-blue-quart-circle.svg);
    background-repeat: no-repeat;
    rotate: -90deg;
    @include tablet-wide {
      bottom: -10px;
      right: -5px;
    }
    @include tablet {
      width: 88px;
        height: 88px;
    }
  }
  &.alt &-content {
    background-color:rgba(255, 198, 0, 0.10);
  }
  &.alt &-quart-circle {
    background-image: url(../../images/yellow-quart-circle.svg);
    right: auto;
    left: -20px;
    rotate: 0deg;
    @include tablet-wide {
      left: -5px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include tablet {
      flex-direction: column;
    }
  }
  .push-project-title{
    @extend %GothamBold;
    font-size: rem(56px);
    line-height: rem(64px);
    letter-spacing: -2.24px;
    margin: 0 80px 40px;
    text-transform: uppercase;

    @include tablet {
      font-size: rem(32px);
      line-height: rem(38px);
      letter-spacing: -1.28px;
      margin: 0 0 32px;
    }
  }
  .button {
    font-size: rem(14px);
    line-height: rem(14px);
    letter-spacing: -0.28px;
    width: fit-content;
    margin: 0;
  }
}

.error{
  .back-to-top {
    display: none;
  }
  .error-wrapper{
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    @include size(100vw, 100vh);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background-image: url('../../images/yellow-quart-circle.svg');
      width: 488px;
        height: 488px;
        bottom: 0;
        right: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      rotate: -90deg;
      @include mobile {
        width: 303px;
        height: 303px;
      }
    }
  }
  .error-name{
    margin-top: 40px;
    font-size: rem(100px);
    line-height: rem(115px);
    letter-spacing: -4px;
    text-transform: uppercase;
    @extend %GothamBold;
    position: relative;
    z-index: 2;
    @include mobile {
        font-size: rem(80px);
        line-height: rem(80px);
      letter-spacing: -3.2px;
    }
  }
  .error-message{
    margin-top: 16px;
    @extend %GothamBold;
    font-size: rem(32px);
    line-height: rem(32px);
    letter-spacing: -1.28px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    @include mobile{
      font-size: rem(24px);
      letter-spacing: -0.96px;
    }
  }
  .error-title{
    margin-top: 16px;
    @extend %GothamBold;
    font-size: rem(60px);
    line-height: rem(60px);
    letter-spacing: -1.28px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    @include mobile{
      font-size: rem(24px);
      letter-spacing: -0.96px;
    }
  }
  p{
    margin-top: 16px;
    @extend %Gotham;
    position: relative;
    font-size: rem(17px);
    line-height: rem(26px);
    z-index: 2;
    font-weight: 100;
    @include mobile{
      margin-top: 8px;
      font-size: rem(17px);
    }
  }
  .error-actions{
    margin-top: 32px;
    position: relative;
    z-index: 2;
    display: flex;
    gap: 1rem;
    justify-content: center;

    .button{
      padding: 12px 16px;
      font-size: 14px;
      line-height: 14px;
      width: fit-content;
      margin: 0;

      @include mobile{
        margin: 5px 0px;
      }
    }
  }
}

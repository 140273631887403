.filter {
  margin: 32px 40px 0 40px;
  @include tablet {
    border-top: none;
    border-bottom: none;
    margin: 0;
  }
}
.filter-grid {
  width: 1360px;
  padding: 24px 0;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  .p-3-bottom:last-child {
    padding-bottom: 0;
  }
}
.filter-cell {
  display: flex;
}

.filter-title {
  text-transform: uppercase;
  font-size: rem(14px);
  line-height: rem(16px);
  font-weight: 600;
  margin: 14px 16px 0 0;
}
.filter-map .filter-title{
  width: 120px!important;
}
.filter-details {
  @include tablet {
    border-top: 1px solid $border;
    &:last-child {
      border-bottom: 1px solid $border;
    }
  }
}

.filter-summary {
  border-bottom: none !important;
  color: $black;
  font-size: rem(14px);
  @extend %GothamMedium;
  span {
    text-transform: uppercase;
  }
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.filter-desktop {
  @include tablet {
    display: none;
  }
}
.filter-tablet {
  display: none;
  @include tablet {
    display: block;
  }
}
.contact{
  .contact-container{
    display: flex;
    justify-content: space-between;
    padding: 24px;
    margin-top: 30px;
    border-radius: 16px;
    background: var(--Blanc, #FFF);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    @include tablet {
      flex-direction: column;

      &:last-child{
        margin: 24px 0;
      }
    }
    &.contact-container-small{
      border-bottom: none;
      flex-wrap: wrap;
      min-height: 260px;
      margin-bottom: 20px;
      @include tablet {
        min-height: 275px;
      }

      .contact-left{
        .contact-picture{
          flex: 0 0 80px;
        }
        .contact-info{
          flex: 1 1 auto;
        }
        .contact-name{
          @extend %GothamMedium;
          font-size: rem(21px);
          line-height: rem(27px);
          letter-spacing: -.6px;
          margin: 0 0 5px;
          @include tablet {
            font-size: rem(17px);
            line-height: rem(26px);
          }
        }
      }
      .contact-actions{
        align-items: flex-start;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: flex-start;
        margin: 10px 0 0;

        @include tablet {
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          align-items: stretch;
        }
        @include mobile {
          width: 100%;
        }
        .button{
          margin: 10px 0 0;
        }
      }
    }
  }
  .contact-left{
    .contact-picture{
      @include tablet {
        flex: 0 0 120px;
        @include size(120px, auto);
      }
      img{
        display: block;
        max-width: 100%;
        overflow: hidden;
        border-radius: 16px;
        width: 88px;
        height: 88px;
      }
      .contact-initial {
        font-size: rem(28px);
        background-color: $turquoise-third;
        color: $turquoise;
        display: flex;
        flex-direction: column;
        @extend %GothamMedium;
      }
    }
    .contact-info{
      flex: 1 1 auto;
      flex-basis: auto;
      margin-top: 16px;
      @include mobile {
        margin: 10px 0 0;
        width: 100%;
      }
    }
    .contact-name{
      @extend %GothamMedium;
      color: $turquoise-black-second;
      font-size: rem(21px);
      line-height: rem(28px);
      margin: 0 0 8px;

      @include tablet {
        font-size: rem(20px);
        line-height: rem(26px);
      }
    }
    .contact-position{
      @extend %GothamBook;
      font-size: rem(14px);
      line-height: rem(20px);
      margin: 0;

      @include tablet {
        margin: 0 0 15px;
      }
    }
  }
  .contact-actions{
    flex: 0;
    flex-basis: auto;
    margin-left: 20px;
    white-space: nowrap;

    @include tablet {
      display: flex;
      flex-direction: column;
    }
    @include mobile {
      margin-left: 0px;
      width: 100%;
    }
    .button{
      display: inline-flex;
      justify-content: center;
      margin-left: 10px;
      white-space: nowrap;

      @include tablet {
        margin: 10px 0 0px;
      }
    }
  }
}

.visual-infolink {
  margin-top: 0;
}
.visual-infolink > .grid {
  padding: 0 150px 0 80px;
  @include tablet {
    padding: 0 20px;
  }
}
.visual-infolink .row {
  display: flex;
  align-items: center;
}
.visual-infolink .visual {
  width: 100%;
  height: 450px;
  border-radius: 4px;
  @include tablet {
    margin-top: 48px;
    height: 250px;
    background-position: right 50% top 20%;
  }
}
.visual-infolink .infolink {
  padding-left: 130px;
  color: $black;
  @include tablet {
    padding: 20px 0;
  }
}
.visual-infolink .infolink .text {
  position: relative;
  font-size: rem(32px);
  @extend %GothamMedium;
  line-height: rem(44px);
  margin-bottom: 32px;
  padding-right: 110px;
  @include tablet {
    font-size: rem(20px);
    line-height: rem(26px);
    margin-bottom: 20px;
    padding-right: 0;
  }
}

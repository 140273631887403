.footer {
  border-top: 1px solid $border;
  margin-top: 100px;
  .grid{
    position: relative;
  }
  .btn-back-to-top{
      position: absolute;
      top:-60px;
      right:15px;
      transform: rotate(-180deg);
      cursor: pointer;
      z-index:99;
      @include mobile{
        top:85px;
      }
    }

  @include tablet {
    margin-top: 40px;
  }
  .footer-top{
    border-bottom: 1px solid $border;
    padding: 40px 20px;
    @include tablet {
      padding: 40px 20px 0;
    }
    >.grid {
      @include tablet {
        padding: 0;
        padding: 0;
      }
    }
  }
  .footer-bottom{
    padding: 24px 80px 24px;
    @include tablet {
      padding: 24px 40px;
    }
  }
  .icon-row {
    margin-bottom: 40px;
    img {
      width: 180px;
      @include tablet-wide {
        width: 163px;
        margin-left: 25px;
        margin-right: 15px;
      }
      @include mobile {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .first-row{
    position: relative;
    margin-left: -40px;
    margin-right: -40px;
    @include tablet {
        margin-left: -5px;
        margin-right: -5px;

    }
  }
  .footer-social{
    display: flex; 
    margin: 0 0 30px;
    @include tablet {
      margin: 0;
    }

    &>li{
      margin: 0 10px 0 0;

      &:last-child{
        margin: 0;
      }
      a{
        border: 1px solid $turquoise-black-second;
        border-radius: rem(24px);
        display: block;
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        transition: border-color .3s ease;
        background-color: transparent;
        position: relative;
        @include square(48px);

        &.facebook{
          &:before{
            content: "";
            display: block;
            @include absolute-center;
            @include svgicon('../../images/icon-facebook.svg', 24px, 24px);
          }
        }
        &.twitter{
          &:before{
            content: "";
            display: block;
            @include absolute-center;
            @include svgicon('../../images/icon-x.svg', 24px, 24px);
          }
        }
        &.linkedin{
          &:before{
            content: "";
            display: block;
            @include absolute-center;
            @include svgicon('../../images/icon-linkedin.svg', 24px, 24px);
          }
        }
        &.youtube{
          &:before{
            content: "";
            display: block;
            @include absolute-center;
            @include svgicon('../../images/icon-youtube.svg', 24px, 24px);
          }
        }
        &.instagram{
          &:before{
            content: "";
            display: block;
            @include absolute-center;
            @include svgicon('../../images/icon-instagram.svg', 24px, 24px);
          }
        }
        &:hover{
          opacity: 0.5;
        }
      }
    }
  }
  .footer-title{
    @extend %GothamMedium;
    font-size: rem(21px);
    letter-spacing: rem(-0.06px);
    line-height: rem(27px);
    margin-bottom: rem(16px);
    text-transform: uppercase;
  }
  .footer-btns{
    .footer-btns-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .footer-title {
        margin-bottom: 0!important;
      }
      a {
        font-size: rem(17px);
        line-height: rem(20px);
        position: relative;
        display: inline-block;
        width: fit-content;
        @extend %Gotham;
        &:after{
          background-color: $black;
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          transition: width .5s ease;
          @include size(0, 1px);
        }
        &:hover{
          &:after{
            width: 100%;
          }
        }
        &.external-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
        }
      }
    }
  }
  hr{
    border: none;
    border-top: 1px solid $border;
    margin: 40px 0;
    @include size(auto, 0px);
  }
  .footer-actions{
    bottom: 0;
    left: $gutter;
    margin: 0 0 30px;
    position: absolute;
    &.is-desktop{
      @include mobile{
        display: none;
      }
    }
    &.is-mobile{
      display: none;
      @include mobile{
        display: block;
      }
    }
    @include mobile {
      bottom: auto;
      left: auto;
      position: static;
    }

    .button{
      margin: 0 0 10px;

      @include mobile {
        display: block;
        width: 100%;
      }
    }
    br{
      @include mobile {
        display: none;
      }
    }

  }
  .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 40px;

    &>li{

      a{
        color: $black;
        @extend %Gotham;
        font-size: rem(17px);
        line-height: rem(20px);
        position: relative;
        display: inline-block;

        &:after{
          background-color: $black;
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          transition: width .5s ease;
          @include size(0, 1px);
        }

        @include tablet {
          font-size: rem(16px);
          line-height: rem(20px);
        }
        &:hover{
          &:after{
            width: 100%;
          }
        }
      }
    }
  }
  .footer-address{
    @extend %Gotham;
    font-size: rem(20px);
    line-height: rem(30px);
    position: relative;
    margin-bottom: rem(32px);
    @include tablet {
      font-size: rem(16px);
      line-height: rem(20px);
    }
    .bold {
      @extend %GothamBold;
      text-transform: uppercase;
    }

    .footer-address-title{
      @extend %GothamBold;
      font-size: rem(20px);
      line-height: rem(30px);
      margin: 0 0 10px;
      text-transform: uppercase;

      @include tablet {
        font-size: rem(18px);
        line-height: rem(22px);
      }
    }
    .footer-address-group{
      margin-top: 30px;

      @include mobile {
        margin-top: 25px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .footer-copyright{
    @extend %GothamBold;
    font-size: rem(12px);
    line-height: rem(14px);
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      margin-bottom: 15px;
    }
  }
  .footer-right{
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      justify-content: center;
    }
  }
  .footer-tools{
    @extend %GothamBold;
    font-size: rem(12px);
    line-height: rem(14px);
    text-transform: uppercase;
    display: flex;
    gap: 8px 24px;
    flex-wrap: wrap;
    justify-content: center;

    @include tablet {
      flex-flow: column;
      gap: 8px;
      text-align: center;
    }

    &>li{
      position: relative;
      &:after{
        background-color: $black;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        transition: width .5s ease;
        @include size(0, 1px);
      }

      @include tablet {
        font-size: rem(16px);
        line-height: rem(20px);
      }
      &:hover{
        &:after{
          width: 100%;
        }
      }
    }
  }
  .col-links{
    @include tablet-wide{
      width:50%;
      margin-bottom: rem(22px);
    }
    @include tablet{
      width: 100%;
    }
  }
  .col-btns{
    @include tablet-wide{
      width:50%;
      margin-bottom: rem(22px);
    }
    @include tablet{
      width: 100%;
    }
  }
  .col-address{
    @include tablet-wide{
      width:50%
    }
    @include tablet{
      width: 100%;
      margin-bottom: rem(22px);
    }
  }
  .col-access{
    @include tablet-wide{
      width:50%
    }
    @include tablet{
      width: 100%;
    }
  }
  .col-copyright{
    margin-bottom: 8px;
    @include tablet{
      text-align: center;
    }
  }
  .col-submenu{
    text-align: center;
  }
  .footer-links, .footer-btns-container {
    @include tablet {
      &.inactive {
        display: none;
      }
    }
  }
  .js-footer-accordion-header {
    @include tablet {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 22px;
        height: 22px;
        background-image: url('../../images/chevron-down.svg');
        background-size: contain;
        transform: translateY(-50%);
        background-repeat: no-repeat;
      }
      &.active:after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
}
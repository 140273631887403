.event-page-intro{
  margin-top: rem(20px);
  &__top{
    margin-bottom: rem(40px);
    @include mobile{
      margin-bottom: rem(20px);
    }
  }
  &__bottom{
    position: relative;
    @include tablet-wide{
      display: none;
    }
    &__img{
      width: calc(100% - 210px);
      background-size: cover;
      background-position: center;
      margin-left: -80px;
      position: relative;
      &__overlay{
        content:"";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
      }
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left:0;
        top:0;
        background-image: linear-gradient(179deg, rgba(4,20,45,0.00) 46%, #000000 93%);
      }
      &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left:0;
        top: 0;
        background-image: linear-gradient(0deg, rgba(4,20,45,0.00) 84%, #000000 100%);
      }
    }
    &__right{
      position: absolute;
      width: 290px;
      top:0;
      background: $white;
      right: 0;
      display: flex;
      flex-flow: column;
      &__wrap{
        border: 1px solid $border;
        border-top: 0;
        flex-grow: 1;
      }
      &__date{
        text-align: center;
        background-color: $turquoise;
        padding: 25px 15px;
        color:$white;
        &__number{
          @extend .key-figures;
        }
        &__month{
          @extend .normal-link;
          margin-top: -15px;
          text-transform: uppercase;
          @include target-metro{
            margin-top: -5px;
          }
        }
      }
      &__counter{
        padding: 26px 10px 15px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $turquoise;
        @extend .heading-5;
        font-weight: 400;
        border-bottom: 1px solid $border;
        span{
          font-weight: 700;
          margin-left: 10px;
        }
      }
      &__details{
        padding: 30px;
        padding-bottom: 70px;
        &__title{
          @extend .heading-5;
          margin-bottom: rem(20px);
        }
        &__list{
          li{
            border-top:1px solid $border;
            padding:10px 0;
            display: flex;
            align-items: center;
            svg{
              width: 24px;
              height: auto;
              margin-right: 10px;
              @include target-metro{
                height: 24px;
              }
            }
            &:last-child{
            }
          }
        }
      }
      .button{
        width: 100%;
        justify-content: center;
        margin-top: rem(30px);
      }
    }
  }
  &__mobile{
    display: none;
    @include tablet-wide{
      display: block;
    }
    &__top{
      background-color: $turquoise;
      padding: 15px 0;
      color: $white;
      text-align: center;
      margin-bottom: 20px;
      &__number{
        @extend .key-figures;
      }
      &__month{
        @extend .normal-link;
        text-transform: uppercase;
        margin-top: -25px;
      }
    }
    &__top-wide{
      position: relative;
      padding-bottom: 61%;
      background-size: cover;
      background-position: center;
      margin-bottom: rem(20px);
      &:before{
        content: "";
        background-image: linear-gradient(179deg, rgba(4,20,45,0.00) 46%, #000000 93%);
        position: absolute;
        top:0;
        height: 100%;
        left: 0;
        width: 100%;
      }
      &:after{
        content: "";
        background-image: linear-gradient(0deg, rgba(4,20,45,0.00) 84%, #000000 100%);
        position: absolute;
        bottom:0;
        left: 0;
        width: 100%;
      }
      &__encart{
        position: absolute;
        width: 94px;
        height: 94px;
        background-color: $turquoise-alt;
        color: $white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        &__number{
          @extend .key-figures;
          font-size: rem(50px);
          line-height: rem(50px);
        }
        &__month{
          @extend %GothamMedium;
          font-size: rem(10px);
          line-height: rem(17px);
          margin-top: rem(-10px);
          text-transform: uppercase;
        }
      }
    }
    &__bottom{
      text-align: center;
      &__list{
        margin-bottom: 20px;
        li{
          justify-content: center;
          border-top:1px solid $border;
          padding:10px 0;
          display: flex;
          align-items: center;
          svg{
            width: 24px;
            height: auto;
            margin-right: 10px;
            @include target-metro{
              height: 24px;
            }
          }
          &:last-child{
          }
        }
      }
      .button{
        @include mobile{
          width: 100%;
        }
      }
    }
  }
}
.section-bubbles{
  color: $white;
  overflow: hidden;

  .section-bubbles-left{
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 613px;
    position: relative;

    @include tablet {
      margin-right: 20px;
    }
    @include mobile {
      background-color: $turquoise;
      margin: 0 -20px;
      min-height: 0;
      padding: 40px 15px;
    }

    &:before{
      content: "";
      mix-blend-mode: multiply;
      position: absolute;
      right: -60px;
      top: 50%;
      transform: translate(0, -50%);
      @include svgicon('../../images/organic-form-a.svg', 930px, 613px);

      @include tablet {
        right: -80px;
        @include svgicon('../../images/organic-form-a.svg', 830px, 513px);
      }
      @include mobile {
        display: none;
      }
    }
  }
  .section-bubbles-right{
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 100px 0 40px;
    min-height: 613px;
    position: relative;

    @include tablet {
      margin-left: 20px;
    }
    @include mobile {
      background-color: $yellow;
      margin: 0 -20px;
      min-height: 0;
      padding: 40px 15px;
    }

    &:before{
      content: "";
      left: -60px;
      mix-blend-mode: multiply;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(180deg);
      @include svgicon('../../images/organic-form-c.svg', 1042px, 688px);

      @include tablet {
        left: -80px;
        @include svgicon('../../images/organic-form-c.svg', 942px, 588px);
      }
      @include mobile {
        display: none;
      }
    }
  }
  .section-bubbles-twitter{
    max-width: 100%;
    width: 510px;
    //position: relative;
    text-align: center;
    z-index: 1;
    @include absolute-center;

    @include mobile{
      left: auto;
      position: relative;
      top: auto;
      transform: none;
    }

    .icon{
      display: block;
      margin: 0 auto 30px;
      @include size(56px, 48px);

      svg{
        max-height: 100%;
        max-width: 100%;
        @include size(100%, 100%);

        &>g{
          fill: none;
          *[fill]{
            fill: $white;
          }
          *[stroke]{
            stroke: $white;
          }
        }
      }
    }
    .section-bubbles-twitter-date{
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(14px);
      letter-spacing: 1px;
      margin: 0 0 20px;
      text-transform: uppercase;
    }
    .section-bubbles-twitter-tweet{
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(27px);
      letter-spacing: -.6px;
      margin: 0 0 20px;

      @include tablet {
        font-size: rem(18px);
        line-height: rem(22px);
        letter-spacing: -.2px;
      }

      a{
        border-bottom: 1px solid $white;
        position: relative;
        text-decoration: none;
      }
    }
    .button{
      margin-top: 30px;
    }
    .twitter-slider{
      background-color: transparent;

      @include mobile {
        margin: 0;
      }

      .icon{
        @include tablet {
          top: 0;
        }
      }
      .twitter-container{
        .twitter-item{
          background-color: transparent;
          height: 500px;
          padding: 0;

          @include tablet {
            height: 400px;
          }
          @include mobile {
            height: auto;
          }
        }
      }
      .dots{
        @include mobile {
          bottom: 0;
        }
      }
    }
  }
  .section-bubbles-youtube{
    max-width: 100%;
    //position: relative;
    text-align: center;
    width: 430px;
    z-index: 1;
    @include absolute-center;

    @include mobile{
      left: auto;
      position: relative;
      top: auto;
      transform: none;
    }

    .icon{
      display: block;
      margin: 0 auto 30px;
      @include size(98px, 24px);

      svg{
        max-height: 100%;
        max-width: 100%;
        @include size(100%, 100%);

        &>g{
          fill: none;
          *[fill]{
            fill: $white;
          }
          *[stroke]{
            stroke: $white;
          }
        }
      }
    }
    .button{
      margin-top: 30px;

      &:hover{
        border-color: $black;
        color: $black;
      }
    }
  }
  .section-bubbles-publication{
    color: $black;
    margin: 0 auto;
    max-width: 100%;
    width: 290px;
    @include absolute-center;

    @include tablet {
      width: 200px;
    }
    @include mobile{
      left: auto;
      position: relative;
      top: auto;
      transform: none;
      width: auto;
    }

    .publications-list-icon{
      position: relative;
      width: 180px;

      @include mobile {
        margin: 0 auto;
      }

      img{
        display: block;
        max-width: 100%;
        width: 100%;
      }
      .publications-list-download{
        background-color: $black;
        position: absolute;
        right: -24px;
        bottom: 20px;
        @include circle(48px);

        @include mobile {
          right: -20px;
          @include circle(40px);
        }

        svg{
          @include absolute-center;

          &>g{
            fill: none;
            *[fill]{
              fill: $white;
            }
            *[stroke]{
              stroke: $white;
            }
          }
        }
      }
    }
    .publications-list-info{
      @include mobile {
        margin: 0 auto;
        width: 180px;
      }
    }
  }
  .publications-list-info{
    .publications-list-title{
      margin: 15px 0 0;
      @extend .heading-5;
    }
    .publications-list-subtitle{
      margin: 15px 0 0;
      text-transform: uppercase;
      @extend .small-link;
    }
    .publications-list-tag{
      border: 1px solid $black;
      border-radius: rem(4px);
      display: inline-block;
      margin: 15px 0 0;
      padding: 5px 10px;
      @extend .small-link;
    }
  }
}

.next-event {
  margin-top: -75px;
  margin-left: 40px;
  margin-right: 40px;
  z-index: 1;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background: #FFF;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
  @include tablet {
    border-radius: 16px;
  }
}
.next-event .grid {
  padding: 0 20px;
}
.next-event .main-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* CSS global pour les cases de date bleus utilisée sur toute la page exceptée pour les padding */
.next-event .date-cell {
  background-image: url('../../images/yellow-quart-circle-alt.svg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 160px 160px;
  @include tablet {
    background-size: 120px 120px;
  }
}
.date-cell {
  min-width: 27%;
  padding: 75px 0;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-cell.has-two-dates {
    padding-left: 38px !important;
    padding-right: 38px !important;
}
.date-cell .days {
  text-align: center;
  font-size: 5.5rem;
  letter-spacing: -4px;
  text-transform: uppercase;
  @extend %GothamBold;
  @include tablet-wide {
    font-size: rem(80px);
  }
}
.date-cell .monthYear {
  @extend %GothamMedium;
  font-size: 12px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}
.date-cell .separator {
  @extend %GothamBold;
  font-size: 32px;
  line-height: 32px; /* 100% */
  letter-spacing: -1.28px;
  text-transform: uppercase;
  margin: 0 8px;
}
/* */
.info-cell {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.info-cell .tags-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.info-cell .tags-container .tag-nonClickable:hover {
  cursor: default;
  background-color: transparent;
  color: black;
}
.next-event, .old-events {
  .info-cell .title {
    font-size: 21px;
    @extend %GothamMedium;
    line-height: 28px; /* 152.381% */
    &:hover {
      color: $turquoise-black-second;
    }
  }
}
.info-cell .description {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px; /* 150% */
  margin-bottom: 8px;
}
.next-event .info-cell .infos-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}
.next-event .info-cell .infos-container * {
  font-size: 14px;
  font-weight: 400;
  img {
    width: 16px;
    height: 16px;
  }
}
.next-event .info-cell .infos-container > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
@media screen and (max-width: 768px) {
  .next-event {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .next-event .grid {
    padding: 0 5px;
  }
  .date-cell {
    padding: 24px 22px;
  }
  .date-cell .days {
    font-size: 80px;
    line-height: 80px;
  }
  .date-cell .monthYear {
    font-size: 12px;
    letter-spacing: -0.24px;
  }
  .next-event .info-cell {
    padding: 20px;
  }
  .next-event .info-cell .tags-container .tag {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 8px;
    letter-spacing: initial;
  }
  .next-event .info-cell .title {
    font-size: 21px;
    line-height: 26px;
    font-weight: bold;
  }
  .next-event .info-cell .description {
    font-size: 16px;
    line-height: 22px;
  }
  .next-event .info-cell .infos-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .next-event .date-cell {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 420px) {
  .next-event .date-cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (min-width: 1450px) {
  .next-event {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.titleOldEvents {
  margin: 0;
  @include tablet {
    margin-bottom: 24px;
  }
}
.old-events {
  margin-top: 32px;
  @include tablet {
    margin-top: 16px;
  }
}
.old-events .date-cell {
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.old-events .info-cell {
  gap: 24px;
}
.dividends-list-files {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 40px;
  margin: 0;
}
.old-events .button {
  padding: 12px 24px;
}
.old-events .filter-tablet {
  padding: 0;
}
@include tablet {
  .old-events .date-cell {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .old-events .info-cell {
    padding-top: 20px;
    gap: 16px;
  }
  .old-events .dividends-list-files {
    grid-template-columns: 1fr;
  }
}

.list-events {
  .section-title {
    margin-bottom: 32px;
    @include tablet {
        margin-bottom: 24px;

    }
  }
  .events-list-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    @include tablet {
      grid-template-columns: 1fr;
      gap: 10px 0;
    }

    .event-card {
      border-radius: 16px;
      box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .date-cell {
        padding: 37px 70px 48px;
        background-image: url('../../images/light-blue-quart-circle.svg');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 160px 160px;
        border-radius: 0px;
        @include tablet {
          background-size: 120px 120px;
          padding: 32px 38px;
        }
      }
      &:nth-child(4n-2) .date-cell {
        background-image: url('../../images/yellow-quart-circle-alt.svg');
        background-position: left top;
      }
      &:nth-child(4n-1) .date-cell {
        background-image: url('../../images/green-quart-circle-alt.svg');
        background-position: right bottom;
      }
      &:nth-child(4n) .date-cell {
        background-image: url('../../images/blue-quart-circle-alt.svg');
        background-position: left top;
      }

      .info-cell {
        padding: 24px 24px 40px;
        @include tablet {
          padding: 20px;
        }

        .title {
          font-size: 21px;
          line-height: rem(28px);
          @include tablet {
            font-size: rem(17px);
            line-height: rem(26px);
          }
        }

        .description {
          font-size: rem(17px);
          line-height: rem(26px);
        }

        .infos-container .hours {
          padding-bottom: 16px;
        }

        .infos-container .location {
          padding-bottom: 24px;
          @include tablet {
            padding-bottom: 0;
          }
        }

        .infos-container .hours, .infos-container .location {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          @extend %GothamBook;
          @include tablet {
            font-size: rem(16px);
            line-height: rem(22px);
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .dividends-list-files {
        grid-template-columns: 1fr;
      }

      .addCalendar {
        margin-top: auto;
      }
    }
  }
}

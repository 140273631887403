.event-featured{
  margin: rem(100px) 0;
  color: $white;
  @include mobile{
    margin: rem(50px) 0;
  }
  &__inner{
    min-height: 630px;
    position: relative;
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    .col-details{
      position: absolute;
      width: 290px;
      right: 0;
      top:0;
      z-index: 1;
    }
    &__bg{
      position: absolute;
      margin-left: -80px;
      top:0;
      left:0;
      width: calc(100% + 80px);
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
      &__overlay{
        content:"";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.2);
      }
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left:0;
        top:0;
        background-image: linear-gradient(179deg, rgba(4,20,45,0.00) 46%, #000000 93%);
      }
      &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left:0;
        top: 0;
        background-image: linear-gradient(0deg, rgba(4,20,45,0.00) 84%, #000000 100%);
      }
    }
  }
  .row{
    position: relative;
    z-index: 1;
    height: 100%;
    align-items: flex-end;
    margin-top: auto;
    @include target-metro{
      margin-top: 170px;
    }
  }
  &__wrap{
    @include tablet-wide{
      max-width: calc(100% - 49px);
    }
  }
  &__tags{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(30px);
    .tag{
      margin-right: 10px;
    }
  }
  &__title{
    @extend .heading-3;
    margin-bottom: rem(30px);
  }
  &__content{
    @extend .body;
    margin-bottom: rem(30px);
  }
  &__btns{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .button{
      margin-right: rem(30px);
      @include tablet-wide{
        margin-bottom: rem(30px);
      }
    }
  }
  &__details{
    background-color: $turquoise;
    text-align: center;
    padding: 40px 35px;
    @include tablet{
      display: none;
    }
    &__numbers{
      @extend .key-figures;
    }
    &__month{
      @extend .normal-link;
      margin-top: rem(-15px);
      text-transform: uppercase;
      margin-bottom: rem(35px);
      @include target-metro{
        margin-top: -10px;
      }
    }
    &__list{
      list-style-type: none;
      li{
        border-top:1px solid $white;
        padding:10px 0;
        display: flex;
        align-items: center;
        svg{
          width: 24px;
          height: auto;
          margin-right: 10px;
          @include target-metro{
            height: 24px;
          }
        }
        &:last-child{
          border-bottom:1px solid $white;
        }
      }
    }
  }
  &__desktop{
    @include tablet{
      display: none;
    }
  }
  &__mobile{
    display: none;
    @include tablet{
      display: block;
    }
    &__top{
      background-size: cover;
      background-position: center;
      height: 200px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      &:before{
        content:"";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.3;
        color: $white;
      }
      &__number{
        position: relative;
        z-index: 1;
        @extend %GothamNarrow;
        font-size: rem(70px);
        line-height: rem(80px);
        letter-spacing: rem(-0.2px);
      }
      &__month{
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        @extend .normal-link;
        margin-top: rem(-15px);
      }
    }
    &__bottom{
      margin-top: rem(20px);
      padding: 0 15px;
      color:$black;
      &__tags{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(20px);
        a{
          margin: 0 5px;
        }
      }
      &__title{
        text-align: center;
        @extend .heading-4;
        color:$black;
        margin-bottom: rem(20px);
      }
      &__list{
        list-style-type: none;
        margin-bottom: rem(20px);
        li{
          border-top:1px solid $border;
          padding:10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            width: 24px;
            height: auto;
            margin-right: 10px;
          }
          &:last-child{
            border-bottom:1px solid $border;
          }
        }
      }
      &__btns{
        .button{
          width: 100%;
          margin-bottom: rem(10px);
          justify-content: center;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.homepage-header{
  .homepage-header-cover{
    background-color: #000;
    color: $white;
    height: calc(100vh - 120px);
    margin: 0 auto;
    max-width: $width + ($outter * 2) + 15;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    &-image {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-size: cover;
      background-position: center;
      &.desktop {
        display: block;
        @include tablet {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }
    &:before{
      content:"";
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: black;
      opacity: 0.2;
      z-index: 1;
    }
    @include tablet {
      display: flex;
      flex-direction: column;
      padding-bottom: 48px;
    }
    .homepage-header-buttons{
      margin: 40px 0 0;
      @include tablet {
        margin: 32px 0 0;
      }
      .button{
        margin: 0 1rem 0 0;
        @include tablet {
          width: fit-content;
          margin-bottom: 16px;
        }
      }
    }

    &--has-video{
      video{
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 0;
        &.desktop {
          display: block;
          @include tablet {
            display: none;
          }
        }
        &.mobile {
          display: none;
          @include tablet {
            display: block;
          }
        }
      }
    }
  }

  .homepage-header-content{
    bottom: 80px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;

    @include tablet {
      position: unset;
      margin-top: auto;
    }
  }
  .homepage-header-title{
    @extend %GothamMedium;
    font-size: rem(21px);
    line-height: rem(28px);

    @include tablet {
      font-size: rem(17px);
      line-height: rem(26px);
    }
  }
  .homepage-header-subtitle{
    @extend %GothamBold;
    font-size: rem(56px);
    letter-spacing: -2.24px;
    line-height: rem(64px);
    margin: 24px 0 0;
    text-transform: uppercase;

    @include tablet {
      font-size: rem(32px);
        letter-spacing: -1.28px;
        line-height: rem(38px);
      margin: 16px 0 0;
    }
    &.small{
      font-size: rem(60px);
      letter-spacing: -1.8px;
      line-height: rem(74px);

      @include tablet {
        font-size: rem(32px);
        letter-spacing: -.6px;
        line-height: rem(38px);
      }
      @include mobile {
        font-size: rem(32px);
        letter-spacing: -.6px;
        line-height: rem(38px);
      }
    }
  }
  .homepage-header-actions{
    margin: 40px 0 0;

    @include tablet {
      margin: 16px 0;
    }
    &.homepage-header-actions-mobile{
      display: none;

      @include tablet {
        display: block;
      }
    }
    a{
      color: $white;
      @extend %GothamMedium;
      font-size: rem(14px);
      line-height: rem(17px);
      letter-spacing: 1px;
      margin-right: 60px;
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      display: inline-block;
      @include tablet-wide {
        margin-right: 0;
      }

      &:hover{
        span{
          transform: translateX(0px);
        }
      }
      @include tablet {
        font-size: rem(14px);
        line-height: rem(17px);
        margin: 0;
        padding: 8px 16px 8px 32px;
      }

      span{
        display: inline-block;
        padding-left: 40px;
        transform: translateX(-35px);
        transition: all 250ms;
        &:before{
          content: "";
          display: block;
          left: 0px;
          @include absolute-center('y');
          @include svgicon('../../images/icon-arrow.svg', 24px, 24px);
          @include tablet {
            display: none;
          }
        }
        @include tablet {
          padding-left: 8px;
        }
      }
    }
  }
  &.has-encart{
    .homepage-header-subtitle{
      @include tablet-wide{
        max-width: calc(100% - 110px);
        font-size: rem(40px);
        letter-spacing: -1.8px;
        line-height: rem(64px);
      }
      @include tablet{
        max-width: 100%;
      }
    }
  }
  .homepage-header-encart{
    position: absolute;
    right: 80px;
    bottom: 80px;
    z-index: 2;
    width: 290px;
    border-radius: 1rem;
    overflow: hidden;
    @include tablet{
      width: auto;

      position: unset;
      margin: 0 24px 0 24px;
    }
    .homepage-header-encart-top{
      background-color: $turquoise-black-second;
      text-align: center;
      padding: 40px 24px;
      @include tablet{
        padding: 32px 24px;
      }
      .number{
        @extend %GothamBold;
        font-size: rem(56px);
        line-height: rem(64px);
        letter-spacing: rem(-2.24px);
        color: $white;
        display: block;
        margin-bottom: 8px;
        @include tablet{
          font-size: rem(40px);
          line-height: rem(56px);
          letter-spacing: rem(-1.6px);
        }
      }
      .date{
        display: block;
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        font-weight: 500;
        letter-spacing: -0.28px;
        text-transform: uppercase;
      }
    }
    .homepage-header-encart-bottom{
      background-color: $white;
      flex-grow: 1;
      .homepage-header-encart-bottom-count{
        text-align: center;
        color: $black;
        @extend %Gotham;
        font-size: rem(17px);
        line-height: rem(26px);
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        .countdown{
          display: inline-block;
          margin-left: 5px;
          width: max-content;
          min-width: 130px;
          @extend %GothamMedium;
        }
        &:before {
          content: "";
          display: block;
          height: 24px;
          width: 24px;
          background-image: url('../../images/icon-clock.svg');
            background-size: contain;
          margin-right: 8px;
        }
      }
      .homepage-header-encart-bottom-content{
        padding:24px;
        text-align: center;
        color: $black;
        .homepage-header-encart-bottom-content-title{
          font-size: rem(21px);
          line-height: rem(28px);
          color: $turquoise-black-second;
          margin-bottom: 16px;
          @extend %GothamMedium;
          @include tablet{
            font-size: rem(17px);
            line-height: rem(26px);
            margin-bottom: 8px;
          }
        }
        .button{
          font-size: rem(14px);
          line-height: rem(14px);
          letter-spacing: -0.28px;
          padding: 12px 16px;
          width: fit-content;
          margin: 0 auto;
        }
      }
    }
  }
}
.homepage-news-intro{
  overflow-x: hidden;
  > .grid{
    position: relative;
  }

  .homepage-news-intro-shapes{
    height: 400px;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;

    &:before{
      content: "";
      margin-left: -200px;
      mix-blend-mode: multiply;
      position: absolute;
      left: 100%;
      transform: rotate(190deg);
      top: 320px;
      @include svgicon('../../images/organic-form-a.svg', 484px, 320px);

      @include tablet {
        display: none;
      }
    }
    &:after{
      content: "";
      left: 100%;
      margin-left: -130px;
      mix-blend-mode: multiply;
      position: absolute;
      top: 180px;
      transform: rotate(-100deg);
      @include svgicon('../../images/shape-transparent@2x.png', 332px, 328px);

      @include tablet {
        display: none;
      }
    }
  }
}

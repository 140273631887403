.pressreleases{
  .pressreleases-list{

  }
  .pressreleases-item{
    border-top: 1px solid #bbbbcc;
    margin: 30px 0 0;
    padding: 30px 0;

    @include mobile {
      padding: 20px 0 10px;
    }
  }
  .pressreleases-item-date{
    color: $turquoise;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(14px);
    letter-spacing: 1px;
  }
  .pressreleases-item-title{
    @extend %GothamMedium;
    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -.8px;
    margin: 10px 0 0;

    @include tablet {
      font-size: rem(20px);
      line-height: rem(26px);
      letter-spacing: -.2px;
    }
  }
  .pressreleases-item-description{
    @extend %Gotham;
    font-size: rem(17px);
    line-height: rem(26px);
    margin: 15px 0 0;

    @include tablet {
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
  .pressreleases-item-moar{
    color: $turquoise;
    display: inline-block;
    margin: 30px 0 0;
    padding-left: 0px;
    position: relative;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(17px);
    letter-spacing: 1px;
    transition: all .3s ease;
    @include tablet {
      margin-top: 24px;
    }
    &:hover{
      margin-left: 40px;

      @include tablet {
        margin-left: 0;
      }
      &:before{
        opacity: 1;
        @include tablet {
          opacity: 0;
        }
      }
    }
    &:before{
      content: "";
      display: block;
      left: -40px;
      opacity: 0;
      transition: opacity .5s ease;
      @include absolute-center('y');
      @include svgicon('../../images/icon_arrow_long_left.svg', 24px, 24px);

    }
  }
}

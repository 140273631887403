.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.display-none {
  display: none;
}
.display-flex {
  display: flex;
}
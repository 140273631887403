.homepage-push{
  .homepage-push-item{
    position: relative;

    @include mobile{
      margin-bottom: 10px;
    }

    img{
      display: block;
      max-width: 100%;
    }
  }
  .homepage-push-tag{
    border: 1px solid $white;
    border-radius: rem(4px);
    color: $white;
    display: inline-block;
    left: 50px;
    padding: 5px 10px;
    position: absolute;
    top: 50px;
    @extend .small-link;

    @include tablet{
      left: 30px;
      top: 30px;
    }
    @include mobile{
      left: 20px;
      top: 20px;
    }
  }
  .homepage-push-infos{
    bottom: 0;
    left: 0;
    padding: 0 50px 50px;
    position: absolute;
    right: 0;

    @include tablet{
      padding: 0 30px 30px;
    }
    @include mobile{
      padding: 0 20px 20px;
    }
    .button{
      margin-top: 10px;
    }
  }
  .homepage-push-title{
    color: $white;
    margin: 0 0 10px;
    @extend .heading-4;

    @include tablet-wide{
      font-size: rem(25px);
      letter-spacing: -.5px;
      line-height: rem(30px);
    }

    @include tablet {
      font-size: rem(20px);
      letter-spacing: -.2px;
      line-height: rem(26px);
    }
  }
}

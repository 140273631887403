.keyfigures-img-slider{
  &__title{
    @extend .heading-3;
    margin-bottom: rem(20px);
  }
  &--turquoise &{
    &__inner{
      &__right{
        background-color: $turquoise;
      }
    }
  }
  &--green &{
    &__inner{
      &__right{
        background-color: $green;
      }
    }
  }
  &__inner{
    display: flex;
    align-items: stretch;
    @include tablet-wide{
      flex-wrap: wrap;
    }
    &__left{
      width: 50%;
      flex-grow: 0;
      background-size: cover;
      background-position: center;
      @include tablet-wide{
        width: 100%;
        height: 400px;
      }
      @include tablet{
        height: 300px;
      }
      @include mobile{
        height: 220px;
      }
    }
    &__left-slider{
      width: 50%;
      flex-grow: 0;
      background-size: cover;
      background-position: center;
      @include tablet-wide{
        width: 100%;
        height: 400px;
      }
      @include tablet{
        height: 300px;
      }
      @include mobile{
        height: 220px;
      }
      div{
        height: 100%;
        pointer-events: none;
      }
      &__slide{
        background-size: cover;
        background-position: center;
      }
    }
    &__right{
      width: 50%;
      flex-grow: 0;
      min-height: 720px;
      background-color: $violet;
      background-image: url("../../images/bg_keyfigures_img_slider.png");
      background-size: cover;
      background-position: center;
      padding-top: 40px;
      padding-bottom: 40px;
      color: $white;
      text-align: center;
      display: flex;
      flex-flow: column;
      @include tablet-wide{
        width: 100%;
        min-height: 610px;
      }
      @include tablet{
        min-height: 510px;
      }
      &__count{
        font-size: rem(12px);
        @extend %GothamMedium;
        line-height: rem(14px);
      }
      &__slider-wrapper{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }
      &__slider{
        max-width: 480px;
        margin: 0 auto;
        @include tablet{
          max-width: 100%;
        }
        &__slide{
          @include mobile{
            padding: 0 15px;
          }
          &__title{
            text-transform: uppercase;
            @extend .small-link;
            margin-bottom: rem(40px);
          }
          &__number{
            margin-bottom: rem(0px);
            @extend .key-figures-big;
            font-size: rem(200px);
            line-height: rem(200px);
            @include tablet{
              font-size: rem(90px);
              letter-spacing: -2.5px;
              line-height: rem(80px);
            }
          }
          &__text{
            margin-top: rem(-15px);
            line-height: rem(22px);
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
            @include tablet{
              margin-top: rem(0px);
            }
          }
        }
      }
      &__arrows{
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet{
          display: none;
        }
        &__left, &__right{
          width: 48px;
          height: 48px;
          border:1px solid rgba($white,0.15);
          border-radius: 50%;
          margin: 0 5px;
          position: relative;
          cursor: pointer;
          &:before{
            content:"";
            width: 18px;
            height: 18px;
            background-image: url("../../images/icon_arrow_short_left_white.svg");
            background-size: contain;
            background-position: center;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
          }
        }
        &__right{
          &:before{
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
      &__dots{
        justify-content: center;
        align-items: center;
        display: none;
        @include tablet{
          display: flex;
        }
        .dot{
          cursor: pointer;
          margin: 0 5px;
          padding: 0;
          border: 0;
          flex-grow: 0;
          flex-shrink: 0;
          width: 10px;
          appearance: none;
          height: 10px;
          border-radius: 50%;
          background: $white;
          opacity: 0.15;
          &.is-active{
            opacity: 1;
          }
        }
      }
    }
  }
}
.linkedin-feed{
  .linkedin-feed-list{
    margin-top: 30px;
    margin-left: -$gutter;
    margin-right: -$gutter;
    display: flex;
    @include tablet{
      display: block;
      column-count: 2;
      column-gap: 1em;
    }
    @include mobile{
      column-count: unset;
      margin-left: 0;
      margin-right: 0;
    }
    &__item{
      padding:0 $gutter;
      display: inline-block;
      margin: 0 0 1em;
      width: 33.33%;
      @include tablet{
        width: 100%;
        padding: 0px;

      }
    }

    iframe{
      margin-bottom: 10px;
    }
  }
  .linkedin-feed-item{
    border: 1px solid rgba($black, .35);
    border-radius: rem(5px);
    //margin-bottom: 20px;
    overflow: hidden;
  }
  .linkedin-feed-item-head{
    align-items: center;
    display: flex;
    padding: 25px;

    @include mobile{
      padding: 15px;
    }

    .linkedin-feed-item-avatar{
      margin-right: 20px;
    }
    .linkedin-feed-item-info{
      a{
        display: block;
        @extend .heading-5;
      }
      p{
        color: $turquoise;
        margin: 0;
        text-transform: uppercase;
        @extend %Gotham;
        font-size: rem(14px);
        line-height: rem(21px);
      }
    }
  }
  .linkedin-feed-item-content{
    padding: 0 25px 25px;
    @extend .body-small;

    @include mobile{
      padding: 0 15px 15px;
    }

    a{
      color: $turquoise;
      @extend %GothamMedium;
    }
  }
  .linkedin-feed-item-media{
    img{
      display: block;
      max-width: 100%;
    }
    .linkedin-feed-item-image,
    .linkedin-feed-item-video,
    .linkedin-feed-item-link{
      display: block;
      position: relative;
    }

    .linkedin-feed-item-link p{
      background-color: #EAF9FA;
      padding: 25px;
      @extend .small-link;
      font-size: rem(17px);
      line-height: rem(21px);

      @include mobile{
        display: none;
      }

      span{
        color: $turquoise;
        display: block;
        margin-top: 5px;
        text-transform: uppercase;
        font-size: rem(12px);
        line-height: rem(14px);
      }
    }
    .linkedin-feed-item-video{
      &:before{
        content: "";
        z-index: 1;
        @include absolute-center;
        @include svgicon('../../images/icon_play.svg', 48px, 48px);

        @include mobile{
          @include svgicon('../../images/icon_play.svg', 40px, 40px);
        }
      }
    }
  }
}

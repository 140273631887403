.back-to-top{
    position: absolute;
    top:80vh;
    z-index: 99;
    cursor: pointer;
}
.page-project-anim{
    .back-to-top{
        top: calc(100vh - 78px);
    }
}
.place{
  &__wrap{
    display: flex;
    align-items: stretch;
    border-radius: 4px; 
    overflow: hidden;
    @include tablet{
      flex-wrap: wrap;
    }
  }

  &__left{
    background-color: $black;
    padding: 50px;
    flex:0 0 33.333%;
    &__title{
      color:$turquoise;
      @extend .heading-4;
      margin-bottom: rem(20px);
    }
    &__address{
      @extend .chapo;
      color:$white;
      margin-bottom: rem(210px);
      @include tablet{
        margin-bottom: rem(30px);
      }
    }
    &__button{
      display: inline-flex;
        border-radius:26px;
        background-color: $turquoise;
        color:white;
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(20px);
        padding: 14px 30px;
        text-transform: uppercase;
        align-items: flex-start;
        &:after{
          content:"";
          width: 17px;
          height: 17px;
          background-image: url('../../images/icon_arrow_short_left_white.svg');
          display: block;
          margin-left: 5px;
          background-size: contain;
          background-repeat: no-repeat;
          transform: rotate(180deg);
        }
    }
    @include tablet{
      width: 100%;
      flex:0 0 100%;
      padding:35px 20px;
    }
  }
  &__right{
    background-size: cover;
    flex:1;
    @include tablet{
      width: 100%;
      flex:0 0 100%;
      padding-bottom: 100%;
    }
  }
}
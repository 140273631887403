.project-images-anim{

    .col-left, .col-right{
        img{
            width: 100%;
        }
    }

    &__item{
        &__wrap{
            margin-bottom: rem(40px);
            @include tablet{
                margin-bottom: rem(15px);
            }
        }
    }
    .js-project-imgs-anim-one{
        transform-origin: bottom left;
    }
    .js-project-imgs-anim-three{
        transform-origin: bottom left;
    }
    .js-project-imgs-anim-two{
        transform-origin: bottom right;
    }
    .js-project-imgs-anim-wrap-two{

        @include tablet{
            width: 100%;
        }
    }

    .col-left{
        width: 66.66667%;
        @include tablet-wide{
            width: 100%;
        }
    }
    .col-right{
        width: 33.3333%;
        @include tablet-wide{
            width: 100%;
        }
    }
}
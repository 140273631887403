.infos{
  .infos-date{
    color: $turquoise;
    text-transform: uppercase;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(18px);
    letter-spacing: 1px;
  }
  .infos-list{
    border-bottom: 1px solid #BBBBCC;
    margin: 30px 0 0;

    &>li{
      border-top: 1px solid #BBBBCC;
      display: flex;
      padding: 20px 0;

      @include tablet {
        flex-direction: column;
      }
    }
    .infos-list-title{
      flex: 0 0 330px;
      @extend %GothamMedium;
      font-size: rem(21px);
      line-height: rem(32px);
      letter-spacing: -.5px;

      @include tablet {
        flex: 0;
        font-size: rem(18px);
        line-height: rem(22px);
        margin: 0 0 10px;
      }
    }
    .infos-list-value{
      color: rgba($black, .5);
      @extend %Gotham;
      font-size: rem(21px);
      line-height: rem(32px);

      @include tablet {
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
  }
}

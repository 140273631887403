.event-block{
  display: flex;
  align-items: stretch;
  border-top:1px solid $border;

  @include tablet{
    flex-wrap: wrap;
  }
  &:last-child{
    border-bottom:1px solid $border;
  }
  .event-block-left{
    flex: 0 0 auto;
    width: 362px;
    background-color: $turquoise-third;
    padding: 49px 60px 83px 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    @include tablet{
      width: 100%;
      padding: 20px 10px 23px 10px;
    }
    .event-block-left-date{
      text-align: center;
      margin-bottom: 40px;
      color: $turquoise;
      @include tablet{
        margin-bottom: 0;
      }
      .number{
        @extend %GothamNarrow;
        font-size: rem(115px);
        line-height: rem(125px);
        letter-spacing: rem(-2px);
        display: block;
      }
      .month{
        margin-top: -15px;
        display: block;
        text-transform: uppercase;
        @extend %GothamMedium;
        font-size: rem(14px);
        line-height: rem(17px);
        @include target-metro{
          margin-top:-10px;
        }
      }
    }
    .event-block-left-date-details{
      width: 221px;
      margin: 0 auto;
      @include tablet{
        display: none;
      }
      li{
        border-top:1px solid $border;
        padding:10px 0;
        display: flex;
        align-items: center;
        svg{
          width: 24px;
          height: auto;
          margin-right: 10px;
          @include target-metro{
            height: 24px;
          }
        }
        &:last-child{
          border-bottom: 1px solid $border;
        }
      }
    }
  }
  .event-block-right{
    padding: 40px 60px;
    width: 100%;
    @include mobile{
      padding: 30px 15px;
    }
    .event-block-right-tags{
      display: flex;
      align-items: center;
      margin-bottom: rem(30px);
      a{
        margin-right: 5px;
        border: 1px solid $black;
        border-radius: rem(2px);
        color: $black;
        display: block;
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(24px);
        padding: 0 10px;
        transition: all .3s ease;

        &:hover{
          background-color: $black;
          color: $white;
        }
      }
      @include tablet{
        justify-content: center;
      }
    }
    .event-block-right-title{
      @extend .heading-4;
      margin-bottom: rem(30px);
      @include tablet{
        text-align: center;
      }
    }
    .event-block-right-text{
      @extend .body;
      margin-bottom: rem(30px);
      @include tablet{
        display: none;
      }
    }
    .event-block-right-buttons{
      .button{
        @include tablet-wide{
          width: 100%;
          margin-bottom: 15px;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}
.event-wrapper {
}
.section-block-events{
  .section-title-wrapper{
    margin-bottom: 30px;
  }
}
.twitter-carousel{
  margin: rem(100px) 0;
  @include tablet{
    margin: rem(50px) 0;
  }
  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $border;
    flex-wrap: wrap;
    margin-bottom: rem(30px);
  }
  &__title{
    @extend .heading-3;
  }
  &__bottom{

  }
  .js-twitter-carousel{

  }
  .js-twitter-carousel-siema{
    cursor: grab;
  }
  &__item{
    margin: 0 10px;
    padding: 10px;
    border: 1px solid $border;
    text-align: center;
    img{
      max-width: 100%;
    }
  }
  &__arrows{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    &__arrow{
      margin: 0 5px;
      width: 48px;
      height: 48px;
      background-image: url("../../images/back-to-top.svg");
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      cursor: pointer;
      &.js-twitter-carousel-left{
        transform: rotate(90deg);
      }
      &.js-twitter-carousel-right{
        transform: rotate(-90deg);
      }
    }
  }
}
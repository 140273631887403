.quote-alt{
  &__author{
    color:$turquoise;
    margin-top: rem(20px);
  }
  .col-wrap{
    @include tablet-wide{
      width: 80%;
      margin-left: 10%;
    }
    @include mobile{
      margin-left: 0%;
      width: 100%;
    }
  }
}
.notice {
  background-color: rgba(117, 194, 189, 0.1);
  .notice-container {
    display: flex;
    align-items: start;
    padding: 24px 40px;
    @include tablet {
      flex-direction: column;
      padding: 24px 20px;
    }
    .notice-picto {
      display: flex;
      align-items: center;
      @include tablet {
        padding-bottom: 16px;
      }

      .notice-title {
        @extend %GothamBold;
        font-size: rem(17px);
        line-height: rem(26px);
        padding: 0 32px 0 8px;
      }
    }
    .notice-message {
      p {
        @extend %GothamBook;
        font-size: rem(17px);
        line-height: rem(26px);
      }
      a {
      color: $turquoise-black-second;
      text-decoration: underline;
        &:hover {
          color: #04142D;
        }
      }
      b {
        @extend %GothamBold;
      }
    }
  }
}

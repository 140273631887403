.page-header{
  padding-top: 104px;
  position: relative;

  @include tablet{
    padding-top: 60px;
  }
  .row-btns{
    align-items: center;
  }
  .col-left{
    @include tablet{
      margin-bottom: rem(25px);
    }
  }
  &__btns{
    display: flex;
    justify-content: flex-end;
    @include tablet{
      justify-content: center;
    }
    .button{
      margin-right: rem(12px);
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
}
.simpleHeader {
  @include tablet {
    padding-top: 25px;
  }
}
.page-title{
  @extend %GothamBold;
  font-size: rem(56px);
  line-height: rem(64px);
  letter-spacing: -2.24px;
  margin: 32px 0 0;
  text-transform: uppercase;
  @include tablet{
    font-size: rem(32px);
    line-height: rem(38px);
    letter-spacing: -1.28px;
  }
}
.page-title-small{
  margin: 20px 0 0;
  @extend .heading-2;
}
.page-subtitle{
  color: $turquoise;
  margin: 15px 0 0;
  @extend .heading-4;
}
.page-actions{
  margin: 20px 0 0;
  @include tablet {
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
.breadcrumbs{
  @extend %GothamBook;
  font-size: rem(14px);
  list-style: none;
  display: flex;
  margin-top: rem(16px);
  &>li{
    &>a,
      &>span{
        display: block;
      }
      &>a{
        margin-right: 20px;
        position: relative;

        &.is-first{
          &:before{
            content: "";
            margin-right: 15px;
            opacity: .45;
            display: inline-block;
            transform: rotate(-180deg);
            @include svgicon('../../images/icon_arrow_small_left.svg', 6px, 9px);
          }
        }

        &:after{
          content: "";
          left: 100%;
          margin-left: 8px;
          opacity: .45;
          @include absolute-center('y');
          @include svgicon('../../images/icon_arrow_small_left.svg', 6px, 9px);
        }
      }
      &:last-child{
        &>a{
          &:after{
            display: none;
          }
        }
      }
      &:hover {
        color: $turquoise-black;
      }
  }
  &>li:last-child {
    @extend %GothamMedium;
    pointer-events: none;
    min-width: 0;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
}
.page-anchors{
  @extend %GothamMedium;
  font-size: rem(14px);
  line-height: rem(14px);
  letter-spacing: -0.28px;
  margin: 40px 0 0;
  text-transform: uppercase;
  @include tablet {
    margin: 32px 0 0;
  }
  &>li{
    overflow: hidden;
    a{
      overflow: hidden;
      padding: 8px 0 8px 24px;
      position: relative;
      transition: color .3s ease;
      white-space: normal;
      word-wrap: break-word;
      display: block;

      &:hover{
        color: $turquoise-black;

        &:before{
          opacity: 0;
        }
        &:after{
          opacity: 1;
          transform: translateY(-50%);
        }
      }
      &:before{
        background-color: $turquoise-black;
        content: "";
        display: block;
        left: 3px;
        transition: opacity .3s ease;
        @include absolute-center('y');
        @include circle(8px);
      }
      &:after{
        content: "";
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 16px;
        transition: all .3s ease;
        transform: translateY(-200%);
        width: 14px;
        height: 14px;
        @include svgicon('../../images/icon-arrow-turquoise-black.svg', 14px, 14px);
      }
    }
  }
}
.side-anchors{
  position: sticky;
  text-transform: uppercase;
  top: 0;
  background-color: $white;
  z-index: 98;
  max-width: 1535px;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  @include tablet {
    top: 59px;
  }
  ul{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px 24px;
    padding: 16px 40px 0;
    border-bottom: 1px solid $border;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    @include desktop {
      padding: 16px 80px 0;
    }
    @include tablet {
      padding: 8px 20px;
      flex-direction: column;
      gap: 0;
      border-bottom: 1px solid $border;
    }
  }
  li{
    padding-bottom: 9px;
    color: black;
    white-space: nowrap;
    &.active {
      position: relative;
      .side-anchors-timeline {
        background-color: $turquoise-black;
        height: 2px;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: all .1s ease;
        @include tablet {
          display: none;
        }
      }
    }
    @include tablet {
      display: none;
      width: 100%;
      padding-bottom: 8px;
      padding-top: 8px;
      &.visible {
        display: block;
        order: 2;
        &:first-child {
          border-top: 1px solid $border;
        }
      }
      &:first-child.visible.active{
        border-top: none;
      }
      &.active {
        order: 1;
        display: block;
        a {
          .side-anchors-item-icon {
            display: block!important;
          }
        }
      }
      &.active.visible {
        a {
          .side-anchors-item-icon {
            rotate: 180deg;
          }
        }
      }
    }
    &:hover, &.active {
      color: $turquoise-black;
      @include tablet {
        border: none;
      }
    }

    a{
      @extend %GothamBold;
      font-size: rem(14px);
      line-height: rem(14px);
      letter-spacing: -0.28px;
      text-transform: uppercase;
      @include mobile {
        font-size: rem(12px);
        line-height: rem(16px);
      }
      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .side-anchors-item-icon {
        display: none;
        width: 22px;
        height: 22px;
        background-image: url('../../images/chevron-down-rounded.svg');
        background-size: 100%;
      }
    }
  }
}
.page-list-header{
  height: 70px;
  position: relative;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    //display: none; // IE 10+
  }

  @include mobile {
    display: none;
  }
  .page-list-header-wrapper{
    height: 330px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: -170px;
    width: 100%;

    @include tablet {
      height: 170px;
      top: -50px;
    }

    &:before{
      bottom: 0px;
      content: "";
      left: 100%;
      margin-left: -470px;
      mix-blend-mode: multiply;
      position: absolute;
      transform: rotate(0deg);
      //z-index: 1;
      @include svgicon('../../images/organic-form-c.svg', 427px, 282px);

      @include tablet {
        bottom: 0px;
        margin-left: -270px;
        @include svgicon('../../images/organic-form-c.svg', 246px, 130px);
      }
    }
    &:after{
      bottom: 0px;
      content: "";
      left: 100%;
      margin-left: -180px;
      mix-blend-mode: multiply;
      position: absolute;
      transform: rotate(180deg);
      @include svgicon('../../images/organic-form-a.svg', 500px, 330px);

      @include tablet {
        bottom: 0px;
        margin-left: -100px;
        @include svgicon('../../images/organic-form-a.svg', 255px, 169px);
      }
    }
  }
}
.page-intro{
  margin-top: 40px;

  @include mobile {
    margin-top: 32px;
    margin-right: -20px;
  }


  .cell-details{
    position: absolute;
    right:0;
  }
  .row-intro{
    position: relative;
  }
  .page-intro-visual{
    margin-bottom: 45px;

    @include mobile {
      margin-bottom: 25px;
    }

    .page-intro-visual-image{
      background: transparent none 50% 50%/cover no-repeat;
      height: 450px;
      width: 100%;
    }

    .page-intro-visual-caption{
      font-weight: 400;
      color: rgba(4, 20, 45, 0.45);
      font-size: 0.94444rem;
      line-height: 1.44444rem;
      margin-top: 15px;
    }
  }
  p{
    margin: 0 0 38px;
    @extend %Gotham;
    font-size: rem(28px);
    line-height: rem(38px);

    @include tablet {
      margin: 0 0 26px;
      font-size: rem(18px);
      line-height: rem(26px);
    }
  }
  .infos{
    margin-top: 60px;

    @include tablet {
      margin-top: 30px;
    }
  }
}

.page-intro-anim{
  margin-top: 10px;
  margin-bottom: 200px;
  margin-top:0;
  @include tablet{
    margin-bottom: rem(50px);
    margin-top:0;
  }
  @include mobile{
    margin-top:0;
  }
  &__cover{
    width: 1520px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    margin-bottom:rem(99px);
    overflow: hidden;
    height: 1500px;
    @include tablet{
      margin-bottom: rem(50px);
      height:800px;
    }
    @include mobile{
      height:500px;
    }
    &__bg{
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
      right: 0;
      background-size: cover;
      background-position: center;
    }

    &__svg{
      position: absolute;
      top:0;
      left:0;
      opacity: 0.8;
      mix-blend-mode: multiply;
      img{
        max-width: 100%;
      }
      @include mobile{
        img{
          max-width: 120%;
        }
      }
    }
    &__header{
      position: absolute;
      top:0;
      left:80px;
      right:80px;
      padding-top: 50px;
      @include tablet{
        left:15px;
        right:15px;
        padding-top: 10px;
      }

      &__back{
        color:white;
        @extend %GothamMedium;
        font-size: rem(12px);
        line-height: rem(18px);
        list-style: none;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 48px;
        display: inline-flex;
        align-items: baseline;
        .desktop{
          @include tablet{
            display: none;
          }
        }
        .mobile{
          display: none;
          @include mobile{
            display: block;
          }
        }
        @include mobile{
          padding-top: 18px;
        }
        &:before{
          content:"";
          display: block;
          @include svgicon('../../images/icon_arrow_small_left_white.svg', 6px, 9px);
          transform: rotate(-180deg);
          margin-right: 15px;
        }
      }

      &__logo{
        position: absolute;
        top:50px;
        left:50%;
        transform: translateX(-50%);
        @include mobile{
          max-width: 80px;
          top:10px;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
    &__text{
      position: absolute;
      top:230px;
      left:80px;
      max-width: 730px;
      color:white;
      @include tablet{
        top:230px;
        left:15px;
        right:15px;
      }
      @include mobile{
        top:90px;
      }
    }

  }

}

.results{
  .results{
    border-bottom: 1px solid #BBBBCC;
    margin: 30px 0 0;

    &>li{
      border-top: 1px solid #BBBBCC;
      display: flex;
      padding: 35px 0;

      @include tablet {
        flex-direction: column;
      }
    }
  }
  .results-item{
    border-radius: 16px;
    background: var(--Blanc, #FFF);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    margin: 30px 0 0;
    padding: 24px;

    @include tablet {
      margin: 10px 0 0;
      padding: 20px;
    }
  }
  .results-item-type{
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(14px);
    letter-spacing: 1px;
    text-transform: uppercase;

    small{
      color: $turquoise;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(14px);
      letter-spacing: 1px;
    }
  }
  .results-item-value{
    color: $black;
    @extend %GothamNarrow;
    font-size: rem(115px);
    line-height: rem(115px);
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;

    @include tablet {
      font-size: rem(70px);
      line-height: rem(70px);
    }
  }
  .results-list-files{
    margin: 10px 0 0;

    &>li{
      padding: 6px 0;

      a{
        color: $turquoise-black-second;
        display: inline-block;
        @extend %GothamMedium;
        -webkit-flex: 0 0 210px;
        -ms-flex: 0 0 210px;
        font-size: rem(14px);
        line-height: rem(20px);
        letter-spacing: -0.28px;
        padding-left: 30px;
        position: relative;
        text-transform: uppercase;
        transition: all .2s ease;

        &:hover{
          color: $black;

          .results-list-icon{
            svg *[stroke]{
              stroke: $black;
            }
          }
        }
        &.unavailable{
          color: $grey;
          cursor: default;

          .results-list-icon{
            svg *[stroke]{
              stroke: $grey;
            }
          }
          &:hover{
            color: $grey;

            .results-list-icon{
              svg *[stroke]{
                stroke: $grey;
              }
            }
          }
        }
        .results-list-icon{
          align-items: center;
          display: flex;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0px;
          @include size(20px, 20px);

          svg{
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

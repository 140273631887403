.news-single-infos{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 0;

  @include tablet {
    margin: 10px 0 0;
  }

  .news-single-tags{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    &>li{
      padding: 5px;
    }
    a{
      border: 1px solid $black;
      border-radius: rem(2px);
      color: $black;
      display: block;
      @extend %GothamMedium;
      font-size: rem(12px);
      line-height: rem(24px);
      padding: 0 10px;
      transition: all .3s ease;

      &:hover{
        background-color: $black;
        color: $white;
      }
    }
  }
  .news-single-type{
    color: $turquoise;
    margin: 0 0 0 30px;
    text-transform: uppercase;
    @extend .small-link;

    @include mobile {
      margin: 10px 0 0;
    }
  }
}
.news-single{
  .section-quote{
    margin: 50px 0 40px;

    @include tablet {
      margin: 30px 0 20px;
    }
  }
  .video,
  .presskit{
    margin: 60px 0;

    @include tablet {
      margin: 40px 0;
    }
  }
}
.news-single-tools{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 10px;

  .news-single-social{
    display: flex;
    align-items: center;
    margin: 0 -5px;

    &>li{
      margin: 0 5px;

      a{
        border: 1px solid rgba($black, .15);
        display: block;
        position: relative;
        transition: background-color .3s ease;
        @include circle(48px);

        &:hover{
          background-color: $black;
          border-color: $black;

          span{
            svg{
              &>g{
                fill: none;
                *[fill]{
                  fill: $white;
                }
                *[stroke]{
                  stroke: $white;
                }
              }
            }
          }
        }

        span{
          display: block;
          @include absolute-center;

          svg{
            display: block;
          }
        }
      }
    }
  }
  .button{
    margin: 0 0 0 40px;

    @include mobile {
      margin: 20px 0 0;
    }
  }
}
.news-single-content{
  margin: 60px 0;

  @include tablet {
    margin: 20px 0 0;
  }

  p{
    margin: 32px 0;
    @extend .body;

    @include tablet {
      margin: 22px 0;
    }
  }
}

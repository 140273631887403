.button{
  align-items: center;
  background-color: $white;
  border: 1px solid rgba($black, .15);
  border-radius: rem(24px);
  cursor: pointer;
  display: inline-flex;
  @extend %GothamMedium;
  font-size: rem(14px);
  line-height: rem(14px);
  letter-spacing: -0.28px;
  padding: 12px 16px;
  text-transform: uppercase;
  text-align: center;
  transition: all .3s ease;
  color: $black;
  @include tablet{
    border-radius: rem(20px);
    padding: 10px 26px;
  }
  @include mobile{
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    padding: 12px 16px;
    width: 100%;
  }
  @media (min-width: 767px){
    &:hover{
      border-color: $black;
      color: $black;
    }
  }
  &.button-black{
    background-color: $black;
    border-color: $black;
    color: $white;
    @media (min-width: 767px){
      &:hover{
        background-color: transparent;
        color: $black;
      }
    }
  }
  &.button-download {
    background-color: transparent;
    border: 1px solid $turquoise-black;
    color: $turquoise-black;
    &:hover {
      border: 1px solid $turquoise-black-alt;
      color: $turquoise-black-alt;
    }
  }
  &.downloadButton::after {
    content: url("../../images/icon-download-light.svg")!important;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    height: 14px;
    line-height: normal;
    transition-duration: 0.3s;
    filter: invert(1);
  }
  &.downloadButton.button-transparent::after {
    content: url("../../images/icon-download.svg") !important;
    filter: none;
    margin-top: -10px;
  }
  &.button-white{
    background-color: $white;
    border-color: $white;
    color: $black;

    &:hover{
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }
  &.button-turquoise-black {
    background-color: $turquoise-black;
    border-color: $turquoise-black;
    color: $white;

    &:hover {
      background-color: $turquoise-black-alt;
      border-color: $turquoise-black-alt;
    }
  }
  &.button-white-to-opacity{
    background-color: $white;
    border-color: $white;
    color: $turquoise-black;
    padding: 12px 16px 12px 16px;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(14px);
    letter-spacing: -0.28px;
    text-transform: uppercase;
    &:hover{
      background-color: transparent;
      color: $white;
      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(304deg) brightness(106%) contrast(101%);
      }
    }
  }
  &.button-outline-white{
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover{
      border-color: $white;
    }
  }
  &.button-outline-white-to-opacity{
    padding: 12px 16px 12px 16px;
    @extend %GothamMedium;
    font-size: rem(14px);
    line-height: rem(14px);
    letter-spacing: -0.28px;
    text-transform: uppercase;
    background-color: transparent;
    border-color: rgba($white, 1);
    color: $white;
    &:hover{
      background-color: $white;
      color: $turquoise-black;
    }
  }
  &.button-turquoise{
    background-color: $turquoise;
    border-color: $turquoise;
    color: $white;

    &:hover{
      background-color: #69D0D7;
      border-color: #69D0D7;
    }
  }
  &.button-green{
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover{
      background-color: #A0E297;
      border-color: #A0E297;
    }
  }
  &.button-text-turquoise{
    color: $turquoise;

    &:hover{
      border-color: $black;
        color: $black;
    }
    &.is-active{
      color:$black;
      border-color: $black;
    }
  }
  &.button-border-turquoise{
    border-color: $turquoise;
    color:$turquoise;
    &:hover{
        color:$white;
        background-color: $turquoise;
    }
  }
  &.button-transparent {
    background-color: transparent;
    border-color: $turquoise-black;
    color: $turquoise-black;
    &:hover {
      opacity: 0.5;
    }
  }
  &.button-disabled{
    background-color: $disabled;
    border-color: $disabled;
    color: rgba($black, .25);
    cursor: default;
    outline: none;

    &:hover{
      background-color: $disabled;
      border-color: $disabled;
      color: rgba($black, .25);
    }
  }
  .button-icon{
    display: block;
    position: relative;

    &:first-child{
      margin-right: 10px;
    }
    &:last-child{
      margin-left: 10px;
    }
    img, svg{
      display: block;
    }
  }
  &.button-fav{
    display: inline-flex;
    align-items: center;
    color:$turquoise;
    border:1px solid $border;
    transition: all 250ms;
    justify-content: center;
    &:hover, &.is-active{
      color:$white;
      background-color: $turquoise;
      border-color: $turquoise;
      svg{
        path{
          fill: $white;
          stroke:$white;
        }
      }
    }
    svg{
      margin-left: 10px;
      max-height: 16px;
      path{
        fill: transparent;
        stroke: $turquoise;
      }
    }
  }
  &.button-apply-job{
    color:$white;
    background-color: $turquoise;
    border-color: $turquoise;
    justify-content: center;
    &:hover{
      opacity: 0.8;
    }
    svg{
      margin-left: 10px;
      max-height: 19px;
    }
  }

  &.button-account{
    display: inline-flex;
    min-width: 290px;
    justify-content: center;
    color: $turquoise;
    &:focus{
      outline: none;
    }
    svg{
      margin-left: 5px;
    }
  }
}

.select{
  @extend %GothamMedium;
  font-size: rem(12px);
  line-height: rem(14px);
  border:1px solid $black;
  border-radius: 26px;
  text-transform: uppercase;
  padding: 0.66667rem 25px 0.66667rem 1.33333rem;
  background-image: url('../../images/chevron-down.svg');
  background-position: calc(100% - 24px) center;
  background-repeat: no-repeat;
  display: inline-block;
  text-align: center;
  position: relative;
  select{
    appearance: none;
    border:0;
    @extend %GothamMedium;
    text-transform: uppercase;
    font-size: rem(12px);
    line-height: rem(14px);
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.button-solid {
  display: flex;
  padding: 4px 8px;
  justify-content: left;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 12px;
}

.button-dotted-light {
  border: 1px solid $white;
  color: $white;
  border-radius: 4px;
}

.button-dotted-dark {
  border: 1px solid $black;
  color: $black;
  border-radius: 4px;
}

.button-brand {
  border: 1px solid rgba(4, 20, 45, 0.15);
  border-radius: 1.33333rem;
  display: block;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  transition: border-color .3s ease;
  background-color: transparent;
  position: relative;
  height: 48px;
  width: 48px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    width: 16px;
  }
  &:hover {
    border-color: $black;
  }
}

.button-brand-facebook:after {
  background-image: url(../../images/icon-facebook.svg);
}
.button-brand-x:after {
  background-image: url(../../images/icon-x.svg);
}
.button-brand-linkedin:after {
  background-image: url(../../images/icon-linkedin.svg);
}
.button-brand-mail:after {
  background-image: url(../../images/icon-mail.svg);
}

.doughnut{
    .subtitle {
        padding-bottom: 32px;
        @include mobile {
            padding-bottom: 24px;
        }
    }
    .doughnut-subhead{
        @extend .chapo;
        opacity: 0.6;
        padding-right: 30%;
        @include mobile{
            padding-right: 0;
        }
    }

    .grid-canvas{
        margin-top: rem(40px);   
        @include mobile{
            margin-top: rem(20px);
        }
    }

    .doughnut-border{
        margin-bottom: rem(40px);
        line-height: 38px; /* 135.714% */
        font-size: rem(28px);
        font-weight: 400;
        @include mobile{
            margin-bottom: rem(20px);
        }
    }


    .grid-canvas-wrap{
        position: relative;
        max-width: 400px;
        @include mobile{
            max-width: none;
            padding: 0 20px;
        }
    }

    .doughnut-chart{
        @include mobile{
          
        }
    }

    .col-canvas{
        @include tablet{
            margin-bottom: rem(40px);
        }
    } 

    .doughnut-tooltips-wrapper{
        position: absolute;
        pointer-events: none;
        width: 400px;
        height: 400px;
        top:0;
        left:0;
        @include tablet{
            width: 100%;
            height: 100%;
        }
    }
    .js-doughnut-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 32px 40px;

        @include tablet {
            gap: 32px 10px;
        }
    }
    .doughnut-list-item{
        cursor: pointer;
        transition: all 250ms;
        &.is-inactive{
            opacity: 0.25;
        }

        @include mobile{
            flex-flow: column;
        }
    }
    .doughnut-list-item-left{
        @extend %GothamNarrow;
        font-size: rem(80px);
        line-height: rem(80px);
        letter-spacing: rem(-1px);
        @include mobile{
            font-size: rem(44px);
            line-height: rem(44px);
        }
    }
    .doughnut-list-item-right{
        margin-top:rem(-7px);
        @include mobile{
            margin-top: rem(0px);
            margin-left: rem(0px);
        }
    }
    .doughnut-list-item-right-title{
        @extend .heading-5;
        margin-bottom: rem(10px);
    }
    .doughnut-list-item-right-subhead{
        @extend .body-small;
        opacity: 0.5; 
    }

    .doughnut-tooltips-wrapper-item{
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 160px;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all 250ms;
        &.is-active{
            opacity: 1;
            visibility: visible;
        }
    }

    .doughnut-tooltips-wrapper-item-data{
        @extend %GothamNarrow;
        font-weight: 700;
        font-size: rem(68px);
        line-height: rem(68px);
        letter-spacing: rem(-4px);
        margin-left: -30px;
        @include mobile{
            font-size: rem(48px);
            line-height: rem(48px);
            margin-left: 0;
        }
    }
    .doughnut-tooltips-wrapper-item-data{
        @extend .small-link;
    }
    .doughnut-tooltips-wrapper-item-label{
        font-size: rem(12px);
    }
}
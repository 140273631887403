.investir-become-actioner p {
  font-size: rem(21px);
  line-height: rem(32px);

}
.investir-become-actioner .button {
  margin-top: 40px;
  padding: 12px 24px;
}
.investir-become-actioner .button::after {
  content: url("../../images/investir-icade/icadeDownload.svg");
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  line-height: normal;
  transition-duration: 0.3s;
}

.investir-become-actioner .button:hover::after {
  filter: invert(1);
}
.parallax{
  width: 100%;
  position: relative;
  padding-bottom: 48%;
  overflow: hidden;
  width: 1520px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include tablet{
    padding-bottom: 70%;
  }
  &__wrapper{
    position: absolute;
    top:0;
    left:0;
    right:0;
    height: 150%;
    background-size: cover;
    background-position: center;
  }
}
.form-login{
  margin-top: rem(128px);
  margin-bottom: rem(108px);

  &__wrap{
    max-width: 400px;
    margin: 0 auto;
  }
  &__switch{
    border: 1px solid $turquoise;
    border-radius: rem(24px);
    display: flex;
    margin-bottom: rem(66px);
    &__left, &__right{
      font-size: rem(12px);
      line-height: rem(20px);
      padding: 14px 30px;
      text-transform: uppercase;
      text-align: center;
      transition: all .3s ease;
      flex-grow: 1;
      font-weight: 500;
      color:$turquoise;
      &.is-current{
        color:$white;
        background-color: $turquoise;
        border-radius: rem(24px);
      }
    }
  }
  &__title{
    font-weight: 700;
    text-align: center;
    margin-bottom: rem(30px);
    font-size: 20px;
    @include mobile{
      line-height: normal;
    }
  }
  &__infos{
    color:rgba($black, 0.45);
    @extend %GothamMedium;
    font-size: rem(12px);
    line-height: rem(12px);
    a{
      text-decoration: underline;
    }
  }
}
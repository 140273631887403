.info-card .title {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: #32BBC5;
  font-weight: 600;
  margin-bottom: 20px;
  @include tablet {
    font-size: 12px;
    margin-bottom: 16px;
  }
}
.info-card .value {
  font-family: 'DIN Condensed', sans-serif;
  font-size: 115px;
  color: #32BBC5;
  font-weight: 700;
  @include tablet {
    font-size: 70px;
  }
}
.info-card .change {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  @include tablet {
    font-size: 18px;
    margin-bottom: 16px;
  }
}
.info-card .date {
  font-size: 17px;
  color: RGBA(4, 20, 45, 0.5);
  font-weight: 400;
  @include tablet {
    font-size: 16px;
  }
}
